<template>
  <div v-if="timings.length > 0" class="canvas-field">
    <milestones-table
      :value="timings"
      :briefing-type="briefingType"
      hide-milestone
      :titles="[]"
    />
  </div>
</template>

<script>
import { parseISO } from 'date-fns';
import ElementBase from './ElementBase';
import MilestonesTable from '@/components/common/MilestonesTable';
import { singleTextField, field } from '@/pdf/components';

export default {
  name: 'CanvasSingledate',
  components: {
    MilestonesTable
  },
  mixins: [ ElementBase ],
  data: () => ({
    timings: [],
  }),
  created() {
    if (this.value.SubmittedData.Value) {
      this.timings = [{
        Title: this.title,
        CheckboxValue: '1',
        To: this.value.SubmittedData.Value
      }];
    } else {
      this.timings = [];
    }
  },
  methods: {
    renderPdfContent() {
      if (!this.value.SubmittedData.Value) {
        return undefined;
      }

      return field([
        singleTextField(this.value, this.$formatDate(parseISO(this.value.SubmittedData.Value), 'P'))
      ]);
    }
  }
}
</script>
