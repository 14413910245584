<template>
  <internal-layout justify-mobile-header="right">
    <template #header>
      <multi-tiles :title="$t('create_briefing.tile')" />
    </template>

    <template #mobile-header>
      <multi-tiles :title="$t('create_briefing.tile')" />
    </template>

    <template default>
      <v-stepper v-model="step" class="elevation-0 flex-grow-1 flex-shrink-1">
        <v-stepper-items class="registration-content">
          <v-stepper-content step="1" class="pa-0">
            <h1 id="brieftypes-header">
              {{ $t('create_briefing.briefing_type.title') }}
            </h1>

            <p>{{ $t('create_briefing.briefing_type.desc') }}</p>

            <div
              v-for="t in briefingTypes"
              :key="t.BriefingType"
              class="template-group"
            >
              <div class="template-icon-column">
                <v-card
                  v-if="$isMobile"
                  class="briefing-tile mobile d-flex align-center"
                  height="76px"
                  :color="t.Color"
                  flat
                  tile
                >
                  <img :src="require(`../assets/icon_${t.BriefingType}.svg`)">
                  <h1 class="white--text text-center">
                    {{ t.MenuTitle }}
                  </h1>
                </v-card>
                <briefing-type-button
                  v-else
                  :type="t.BriefingType"
                  class="briefing-tile mt-auto"
                  :title="t.MenuTitle"
                />
              </div>
              <ul class="template-list">
                <li
                  v-for="p in t.ProjectTypes"
                  :key="p.ID"
                  v-ripple="true"
                  :class="form.projectType === p ? 'active' : null"
                  :style="{ color: t.Color }"
                  @click="setProjectType(t, p)"
                >
                  <span>{{ p.Title }}</span>
                </li>
              </ul>
            </div>
          </v-stepper-content>

          <v-stepper-content step="2" class="pa-0">
            <v-form ref="form" v-model="valid" @submit.prevent="submit">
              <h1>{{ $t('create_briefing.title') }}</h1>
              <p>{{ $t('create_briefing.desc', [ selectedBriefingTypeName, selectedProjectTypeName ]) }}</p>

              <v-text-field
                v-model="form.name"
                :label="$t('create_briefing.labels.name')"
                required
                :rules="validation.name"
              />

              <choose-collection
                v-model="form.collection"
                :collections="collections"
                required
              />

              <div class="brief-setup-button d-flex justify-end">
                <div class="d-flex justify-space-between align-center flex-grow-1 flex-shrink-1" style="max-width: 369px">
                  <button-text @click.prevent="step = 1">
                    <v-icon>mdi-arrow-left</v-icon>{{ $t('generic.back') }}
                  </button-text>
                  <button-xl
                    emphasize
                    type="submit"
                    :disabled="!valid"
                    @click.prevent="submit"
                  >
                    {{ $t('generic.forward') }}
                  </button-xl>
                </div>
              </div>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </internal-layout>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import i18n from '@/i18n';
import { getProjectCollections, getProjectTypes, createSubmittedBriefingForm } from '@/api';
import { BRIEFING_EDIT } from '@/routes';
import { sortFormData } from '@/model/brief';
import { required } from '@/util/validation';
import InternalLayout from '@/components/layout/InternalLayout.vue';
import MultiTiles from '@/components/layout/MultiTiles.vue';
import BriefingTypeButton from '@/components/decoration/BriefingTypeButton';
import ChooseCollection, { getCollectionID } from '@/components/ChooseCollection.vue';

export default {
  name: 'BriefingSetup',
  components: {
    InternalLayout,
    ChooseCollection,
    MultiTiles,
    BriefingTypeButton,
  },
  props: {
    collectionId: {
      type: Number,
      default: null,
    }
  },
  data: () => ({
    step: 1,
    valid: false,
    form: {
      name: null,
      collection: null,
      briefingType: null,
      projectType: null,
    },
    validation: {
      name: [ required(i18n.t('create_briefing.labels.name')) ],
      project: [ required(i18n.t('create_briefing.labels.project')) ]
    },
    collections: [],
    briefingTypes: [],
  }),
  computed: {
    ...mapState(['userId', 'user']),
    briefingTypeNames() {
      return this.briefingTypes.map(t => t.BriefingType);
    },
    selectedBriefingTypeName() {
      return this.form.briefingType?.MenuTitle;
    },
    selectedProjectTypeName() {
      return this.form.projectType?.Title;
    }
  },
  async created() {
    try {
      this.loadStart();
      let [ briefingTypes, collections, projectTypes ] = await Promise.all([
        this.getBriefingTypes(),
        getProjectCollections(this.userId),
        getProjectTypes(),
      ]);

      this.collections = collections;

      if (this.collectionId) {
        this.form.collection = this.collectionId;
      }

      this.briefingTypes = briefingTypes.map((b) => ({
        ...b,
        ProjectTypes: projectTypes
          .filter((p) => p.Parent == b.ID)
          .sort(sortFormData)
      })).sort(sortFormData);

      this.loadSuccess();
    } catch (e) {
      this.loadError(e);
    }
  },
  methods: {
    ...mapActions(['getBriefingTypes', 'saveSuccess']),
    ...mapMutations(['loadStart', 'loadSuccess', 'loadError', 'saveStart', 'saveEnd', 'saveError']),
    async submit() {
      try {
        this.saveStart();

        const collectionId = await getCollectionID(this.form.collection, this.userId);

        const { Uuid } = await createSubmittedBriefingForm({
          Title: this.form.name,
          BriefingType: this.form.briefingType.ID,
          ProjectType: this.form.projectType.ID,
          SubmittedBy: this.userId,
          ProjectCollectionID: collectionId
        });

        this.saveSuccess();

        this.$router.push({
          name: BRIEFING_EDIT,
          params: {
            uuid: Uuid,
          }
        });
      } catch (e) {
        this.saveError(e);
      }
    },
    setProjectType(briefingType, projectType) {
      this.form.briefingType = briefingType;
      this.form.projectType = projectType;
      this.step = 2;
    }
  }
}
</script>

<style lang="sass">
#brieftypes-header
  margin-bottom: 18pt

.template-group
  display: flex
  margin-top: 32pt
  margin-bottom: 90pt

  @media #{map-get($display-breakpoints, 'xs-only')}
    flex-wrap: wrap

.template-icon-column
  flex-grow: 0
  flex-shrink: 0
  padding: 0
  width: 140pt
  margin-right: 24pt

  @media #{map-get($display-breakpoints, 'xs-only')}
    width: 100%

ul.template-list
  padding: 0
  flex-grow: 1
  flex-shrink: 1
  max-width: 290pt

  > li
    list-style: none
    padding: 6pt 12pt
    cursor: pointer
    background-color: none

    > span
      color: black

    &:hover, &.active
      background-color: currentColor

      > span
        color: white

.briefing-tile
  width: 100%
  height: auto

  &.mobile
    width: 100%
    padding: 19px 22px

    img
      width: 32px
      margin-right: 15px
    h4, p
      font-size: 22px
      text-transform: uppercase

.brief-setup-button
  margin-top: $btn-xl-margin
</style>
