













































import Vue from 'vue';
import ButtonBase from './ButtonBase';

export default Vue.extend({
  name: 'FloatingMenuItem',
  mixins: [ButtonBase],
  props: {
    download: {
      type: String,
      default: null,
    }
  },
});
