

























import { Vue, Component } from 'vue-property-decorator';
import InternalLayout from '@/components/layout/InternalLayout.vue';
import MultiTiles from '@/components/layout/MultiTiles.vue';

@Component({
  components: {
    InternalLayout,
    MultiTiles
  }
})
export default class HomeScreen extends Vue {
  
}
