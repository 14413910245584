<template>
  <div v-if="isRenderable" class="canvas-budget canvas-field">
    <h2 class="canvas-field-title" v-html="title" />
    <p>{{ $formatMoney(content) }}</p>
    <v-divider />
  </div>
</template>

<script>
import ElementBase from './ElementBase';
import { singleTextField, field } from '@/pdf/components';

export default {
  name: 'CanvasBudget',
  mixins: [ ElementBase ],
  computed: {
    isRenderable() {
      return this.content !== null;
    }
  },
  methods: {
    renderPdfContent() {
      if (!this.isRenderable) {
        return undefined;
      }

      return field([
        singleTextField(this.value, this.$formatMoney(this.content))
      ]);
    }
  }
}
</script>

<style lang="sass">
.canvas-budget
  p
    @include canvas-larg-text
    margin-bottom: $canvas-option-bottom-padding
</style>
