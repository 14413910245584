import { TCreatedPdf } from 'pdfmake/build/pdfmake';
import {
  TDocumentDefinitions,
  Content,
  ContentSvg,
  TFontDictionary,
  ContentColumns,
  ContentText,
  Node,
  Margins,
} from 'pdfmake/interfaces';
import { BriefingData } from '@/model/brief';
import { styles, STYLES, ITEM_MARGIN, FONT_BASE, TTNORMS, TTNORMS_EXTRALIGHT, MATERIAL_ICONS, tableLayouts } from './constants';
import { resizeSvg } from './components';
import i18n from '@/i18n';

const TTNORMS_NORMAL_FILE = 'ttnorms-regular-nolig.ttf';
const TTNORMS_BOLD_FILE = 'ttnorms-bold-nolig.ttf';
const TTNORMS_EXTRALIGHT_FILE = 'ttnorms-extralight-nolig.ttf';
const MATERIAL_ICONS_FILE = 'icons.ttf';

const PAGE_WIDTH = 595;

const PAGE_LEFT_MARGIN = 57;
const PAGE_RIGHT_MARGIN = 27;
const HEADER_TOP_MARGIN = 74;
const PAGE_MARGIN: Margins = [ PAGE_LEFT_MARGIN, HEADER_TOP_MARGIN + 2 * ITEM_MARGIN, PAGE_RIGHT_MARGIN, 29 ];

const FOOTER_LOGO_SIZE = 10;

export const createCanvasPdf = async (data: BriefingData, content: Array<Content>): Promise<TCreatedPdf> => {
  const { default: pdfMake } = await import(
    /* webpackChunkName: "pdfmake" */
    /* webpackPrefetch: true */
    'pdfmake/build/pdfmake'
  );

  const { default: footerLogo } = await import(
    /* webpackChunkName: "pdfmake" */
    /* webpackPrefetch: true */
    '!!raw-loader!@/assets/logo-small.svg'
  );

  const { default: regular } = await import(
    /* webpackChunkName: "pdfmake" */
    /* webpackPrefetch: true */
    '!!binary-base64-loader!@/assets/fonts/ttnorms-regular-nolig.ttf'
  );

  const { default: bold } = await import(
    /* webpackChunkName: "pdfmake" */
    /* webpackPrefetch: true */
    '!!binary-base64-loader!@/assets/fonts/ttnorms-bold-nolig.ttf'
  );

  const { default: extralight } = await import(
    /* webpackChunkName: "pdfmake" */
    /* webpackPrefetch: true */
    '!!binary-base64-loader!@/assets/fonts/ttnorms-extralight-nolig.ttf'
  );

  const { default: icons } = await import(
    /* webpackChunkName: "pdfmake" */
    /* webpackPrefetch: true */
    '!!binary-base64-loader!@/assets/fonts/icons.ttf'
  );

  const dd: TDocumentDefinitions = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    pageMargins: PAGE_MARGIN,
    header: (currentPage: number): ContentColumns | undefined => {
      // cover page has no header;
      if (currentPage === 1) {
        return undefined;
      }

      return {
        margin: [PAGE_LEFT_MARGIN, HEADER_TOP_MARGIN, PAGE_RIGHT_MARGIN, 0],
        columnGap: 10,
        columns: [
          <ContentText> {
            text: data.Title,
            width: '*',
            fontSize: 12,
            lineHeight: 1,
          },
          <ContentText> {
            text: currentPage.toString(),
            width: 20,
            fontSize: 12,
            lineHeight: 1,
            alignment: 'right',
          },
        ]
      };
    },
    footer: (): ContentColumns => {
      return {
        margin: [PAGE_LEFT_MARGIN, 0, PAGE_RIGHT_MARGIN, 0],
        columnGap: 4,
        columns: [
          <ContentText> {
            text: i18n.t('canvas.pdf.footer'),
            width: '*',
            alignment: 'right',
            fontSize: FOOTER_LOGO_SIZE,
            lineHeight: 1,
          },
          <ContentSvg> {
            svg: resizeSvg(footerLogo, FOOTER_LOGO_SIZE, FOOTER_LOGO_SIZE),
            width: FOOTER_LOGO_SIZE,
            relativePosition: {
              x: 0,
              y: 2,
            }
          },
        ]
      };
    },
    // watermark: {
    //   text: 'BRIEF.ING',
    //   color: 'blue',
    //   opacity: 0.1,
    //   bold: true
    // },
    content,
    styles,
    defaultStyle: {
      fontSize: FONT_BASE,
      font: TTNORMS,
    },
    pageBreakBefore: (
      currentNode: Node,
      followingNodesOnPage: Node[],
      nodesOnNextPage: Node[],
      previousNodesOnPage: Node[],
    ) => {
      if (currentNode.style === STYLES.FIELD_WRAPPER || currentNode.style === STYLES.FIELD_GROUP_WRAPPER) {
        // if a pagebreak happens in a field, let's add a page break before it.
        return previousNodesOnPage.length > 0
          && currentNode.pageNumbers.length > 1
          // we don't want empty page after a step title - rare case with a long field group
          && previousNodesOnPage[previousNodesOnPage.length - 1].style !== STYLES.STEP_TITLE;
      }

      return false;
    }
  };

  const dict: TFontDictionary = {
    [TTNORMS]: {
      normal: TTNORMS_NORMAL_FILE,
      bold: TTNORMS_BOLD_FILE,
    },
    [TTNORMS_EXTRALIGHT]: {
      normal: TTNORMS_EXTRALIGHT_FILE,
    },
    [MATERIAL_ICONS]: {
      normal: MATERIAL_ICONS_FILE
    }
  };

  const fonts = {
    [TTNORMS_NORMAL_FILE]: regular,
    [TTNORMS_BOLD_FILE]: bold,
    [TTNORMS_EXTRALIGHT_FILE]: extralight,
    [MATERIAL_ICONS_FILE]: icons
  };

  return pdfMake.createPdf(dd, tableLayouts, dict, fonts);
};