<template>
  <div class="add-user-option d-flex align-end">
    <v-combobox
      v-if="options"
      ref="input"
      v-model.trim="value"
      :items="options"
      :label="label"
      :color="color"
      :loading="loading"
      :disabled="loading"
      hide-details
      disable-lookup
      validate-on-blur
      @keyup.enter="onAdd"
    />
    <v-text-field
      v-else
      ref="input"
      v-model.trim="value"
      :label="label"
      :color="color"
      :loading="loading"
      :disabled="loading"
      hide-details
      @keydown.enter="onAdd"
    />
    <button-text
      :disabled="!isInputValid"
      :loading="loading"
      class="add-option-btn"
      underlined
      :color="color"
      @click="onAdd"
    >
      + {{ $t('generic.add') }}
    </button-text>
  </div>
</template>

<script>
export default {
  name: 'AddNewValue',
  props: {
    color: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      default: null,
    }
  },
  data: () => ({
    value: null,
  }),
  computed: {
    isInputValid() {
      return !this.loading
        && this.value !== null
        && this.value !== undefined
        && this.value !== '';
    }
  },
  methods: {
    onAdd() {
      this.$emit('input', this.value);
      this.value = null;
      this.$refs.input.blur();
    }
  }
}
</script>

<style lang="sass">
.add-option-btn
  margin-left: 16px

.add-user-option
  //margin-top: $field-margin
  margin-top: 8px
  margin-bottom: $field-margin + 26px
</style>
