<template>
  <div v-if="axes.length > 0" class="canvas-spider-chart canvas-field">
    <h2 class="canvas-field-title" v-html="title" />

    <div class="d-flex justify-center">
      <div class="spider-chart-wrapper">
        <spider-chart
          ref="chart"
          :data="axes"
          :color="color"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { sortFormData } from '@/model/brief';
import { fieldTitle, field } from '@/pdf/components';
import ElementBase from '@/components/canvas/ElementBase'
import SpiderChart from '@/components/common/SpiderChart.vue'

export default {
  name: 'CanvasSpiderChart',
  components: {
    SpiderChart,
  },
  mixins: [ ElementBase ],
  data: () => ({
    axes: []
  }),
  created() {
    if (this.value.SubmittedData?.SpiderOptions?.length > 0) {
      this.axes = this.value.SubmittedData.SpiderOptions
        .sort(sortFormData);
    }
  },
  methods: {
    renderPdfContent() {
      if (this.axes.length > 0) {
        return field([
          fieldTitle(this.value),
          ...this.$refs.chart.renderPdfContent()
        ]);
      } else {
        return undefined;
      }
    }
  }
}
</script>

<style lang="sass">
.canvas-spider-chart
  .canvas-field-title
    margin-bottom: 8px !important
</style>
