import Vue, { PropType, VNode, CreateElement } from 'vue';
import Component from 'vue-class-component';
import { BriefingFieldGroup } from '@/model/brief';
import { BriefingType, FieldUpdatedEvent } from '@/model/brief2';
import FormFragment from './FormFragment';

const props = Vue.extend({
  props: {
    value: {
      type: BriefingFieldGroup,
      required: true,
    },
    briefingType: {
      type: Object as PropType<BriefingType>,
      required: true,
    },
    submittedFormId: {
      type: Number,
      required: true,
    },
    visibilities: {
      type: Object,
      required: true,
    },
    briefingUuid: {
      type: String,
      required: true,
    }
  },
});

@Component
export default class FieldGroup extends props {
  /*
   * methods
   */
  onUpdated(e: FieldUpdatedEvent) {
    this.$emit('updated', e);
  }

  render(h: CreateElement): VNode {
    const fields = this.value.Fields
      .map(field => {
        return h(FormFragment, {
          props: {
            value: field,
            briefingUuid: this.briefingUuid,
            briefingType: this.briefingType,
            submittedFormId: this.submittedFormId,
            visibilities: this.visibilities
          },
          on: {
            updated: this.onUpdated
          },
        })
      });

    return h(
      'div',
      { class: 'brief-fieldset', attrs: { 'data-fieldset-id': this.value.ID } }, [
        h('h1', { attrs: { id: `fieldset-${this.value.ID}` }, domProps: { innerHTML: this.value.Title } }),
        ...fields
      ]
    );
  }
}
