import { BriefingParticipantType } from '@/model/participant';
import { mapState } from 'vuex';
import { BriefingType, SubmittedData, TemplateField } from '@/model/brief2';
export const BRIEFING_MODULE_NAME = 'briefing';

type StoreStateBase = {
  uuid: string;
  briefingType: BriefingType;
  participantsField: TemplateField;
  participants: BriefingParticipantType[];
};

type StoreStateMeta = {
  sbfId: number | null;
  participantsSbf: SubmittedData | null;
  createParticipantDialogShown: boolean;
};

export type BriefingStoreState = Readonly<StoreStateBase> & Readonly<StoreStateMeta>;
export type NullableBriefingStoreState = { [key in keyof StoreStateBase]: StoreStateBase[key] | null } & StoreStateMeta;

type FieldMapper = (state: BriefingStoreState) => any;
type MapOptionsArray = Array<keyof BriefingStoreState>;
type MapOptionsObject = {
  [key: string]: keyof BriefingStoreState | FieldMapper
};

export const mapBriefingState = (options: MapOptionsArray | MapOptionsObject) => {
  return mapState(BRIEFING_MODULE_NAME, options as any);
};
