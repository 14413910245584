var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"milestones-field briefing-field"},[(_vm.value.Title)?_c('h2',{staticClass:"briefing-field-title"},[_vm._v(" "+_vm._s(_vm.value.Title)+" ")]):_vm._e(),(_vm.value.FieldDescription)?_c('p',{staticClass:"briefing-field-description",domProps:{"innerHTML":_vm._s(_vm.value.FieldDescription)}}):_vm._e(),_vm._l((_vm.titles.filter(function (t) { return !_vm.timings.some(function (m) { return m.Title === t; }); })),function(o,i){return _c('div',{key:("sw-" + i),staticClass:"d-flex table-row justify-space-between available-timing",on:{"click":function($event){return _vm.addNewTiming(o)}}},[_c('span',{staticClass:"milestone-item"},[_vm._v(_vm._s(o))]),_c('v-icon',{attrs:{"size":"12pt","title":_vm.$t('generic.add')}},[_vm._v(" mdi-plus ")])],1)}),_c('add-new-value',{attrs:{"color":_vm.color,"label":_vm.$t('create_briefing.upload.new_category')},on:{"input":_vm.addNewTiming}}),(_vm.timings.length > 0)?_c('h2',{staticClass:"briefing-field-title mb-0"},[_vm._v(" "+_vm._s(_vm.$t('create_briefing.milestones.added_title'))+" ")]):_vm._e(),(_vm.timings.length > 0)?_c('v-data-table',{staticClass:"complex-table added-milestones",attrs:{"item-key":"ID","headers":_vm.headers,"items":_vm.timings},scopedSlots:_vm._u([{key:"item.Title",fn:function(ref){
var item = ref.item;
return [(!_vm.$isTabletOrMobile)?_c('v-textarea',{attrs:{"loading":item._isSaving,"rows":1,"auto-grow":"","hide-details":"","single-line":""},on:{"change":function($event){return _vm.submitEdit(item)}},model:{value:(item.Title),callback:function ($$v) {_vm.$set(item, "Title", $$v)},expression:"item.Title"}}):_vm._e(),(_vm.$isTabletOrMobile)?_c('div',[_c('span',{staticClass:"table-emphasized"},[_vm._v(_vm._s(item.Title))]),_c('br'),(_vm.$isTabletOrMobile)?_c('span',{staticClass:"date"},[_vm._v(_vm._s(!item.CheckboxValue ? ((item.From) + " - ") : '')+_vm._s(item.To))]):_vm._e()]):_vm._e()]}},{key:"item.From",fn:function(ref){
var item = ref.item;
return [(!item.CheckboxValue)?_c('date-picker',{attrs:{"briefing-type":_vm.briefingType,"is-saving":item._isSaving,"hide-details":"","single-line":"","max":item.To,"start-with":item.To},on:{"input":function($event){return _vm.submitEdit(item)}},model:{value:(item.From),callback:function ($$v) {_vm.$set(item, "From", $$v)},expression:"item.From"}}):_vm._e()]}},{key:"item.To",fn:function(ref){
var item = ref.item;
return [_c('date-picker',{attrs:{"briefing-type":_vm.briefingType,"is-saving":item._isSaving,"hide-details":"","single-line":"","min":item.CheckboxValue ? null : item.From,"start-with":item.CheckboxValue ? null : item.From},on:{"input":function($event){return _vm.submitEdit(item)}},model:{value:(item.To),callback:function ($$v) {_vm.$set(item, "To", $$v)},expression:"item.To"}})]}},{key:"item.CheckboxValue",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"v-input-switch--single-line",attrs:{"color":_vm.color,"loading":item._isSaving,"hide-details":""},on:{"change":function($event){return _vm.submitEdit(item)}},model:{value:(item.CheckboxValue),callback:function ($$v) {_vm.$set(item, "CheckboxValue", $$v)},expression:"item.CheckboxValue"}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"size":"18pt","title":_vm.$t('generic.delete')},on:{"click":function($event){return _vm.showDeleteDialog(item.ID, item.Title)}}},[_vm._v(" mdi-delete-outline ")])]}}],null,false,1102152567)}):_vm._e(),_c('milestone-delete-dialog',{attrs:{"id":_vm.deleteDialog.id,"show":_vm.deleteDialog.show,"title":_vm.deleteDialog.title,"color":_vm.color},on:{"success":_vm.onDelete,"dismiss":_vm.dismissDeleteDialog}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }