var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tree)?_c('v-stepper',{attrs:{"id":"create-briefing"},model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('table-of-contents-layout',{attrs:{"right-menu":"","justify-header":"space-between"},scopedSlots:_vm._u([{key:"toc",fn:function(){return [_c('div',{attrs:{"id":"briefing-menu-header"}},[_c('briefing-steps-tile',{attrs:{"current":_vm.currentStep,"count":_vm.tree.Steps.length,"color":_vm.tree.BriefingType.Color}})],1),_c('div',{attrs:{"id":"briefing-menu"}},[_c('h1',{attrs:{"id":"brieftype-header"}},[_vm._v(" "+_vm._s(_vm.tree.BriefingType.MenuTitle)+" / "+_vm._s(_vm.tree.ProjectType.Title)+" ")]),_c('h1',{attrs:{"id":"brieftype-header-shifter"}},[_vm._v(" "+_vm._s(_vm.tree.BriefingType.MenuTitle)+" / "+_vm._s(_vm.tree.ProjectType.Title)+" ")]),_c('auto-scroll-list',{attrs:{"color":_vm.tree.BriefingType.Color,"value":_vm.currentStep - 1},scopedSlots:_vm._u([{key:"after",fn:function(){return [_c('div',{directives:[{name:"ripple",rawName:"v-ripple",value:({ class: ((_vm.tree.BriefingType.BriefingType) + "--text") }),expression:"{ class: `${tree.BriefingType.BriefingType}--text` }"}],staticClass:"auto-scroll-list-item",style:({ color: _vm.tree.BriefingType.Color }),attrs:{"id":"canvas-preview-btn"}},[_c('router-link',{attrs:{"to":{ name: 'brief_canvas', params: { uuid: _vm.uuid } },"title":_vm.$t('generic.preview')}},[_vm._v(" "+_vm._s(_vm.$t('generic.preview'))+" ")])],1)]},proxy:true}],null,false,3363471575)},_vm._l((_vm.tree.Steps),function(s,i){return _c('div',{key:s.ID,staticClass:"auto-scroll-list-item",style:({ color: _vm.tree.BriefingType.Color })},[_c('a',{directives:[{name:"ripple",rawName:"v-ripple",value:({ class: ((_vm.tree.BriefingType.BriefingType) + "--text") }),expression:"{ class: `${tree.BriefingType.BriefingType}--text` }"}],style:({
                color: (i + 1) === _vm.currentStep ?
                  _vm.tree.BriefingType.Color
                  : ((i + 1) > _vm.currentStep ? 'grey' : 'black' )
              }),attrs:{"href":("#" + (i + 1))},domProps:{"innerHTML":_vm._s(((i + 1) + ".&nbsp;" + (s.Title)))},on:{"click":function($event){return _vm.jumpToStep(i + 2)}}})])}),0)],1)]},proxy:true},{key:"header",fn:function(){return [_c('header',{attrs:{"id":"create-briefing-title"}},[_c('h1',{staticClass:"briefing-title"},[_vm._v(" "+_vm._s(_vm.tree.Title)+" ")])])]},proxy:true}],null,false,3512060862)},[[_c('briefing-title',{staticClass:"d-sm-none",attrs:{"value":_vm.tree}}),(_vm.$isMobile)?_c('v-dialog',{attrs:{"width":"390px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"d-sm-none wrap-lines",attrs:{"id":"mobile-stepper","block":"","x-large":"","tile":"","text":"","depressed":"","color":_vm.tree.BriefingType.Color}},on),[_vm._v(" "+_vm._s(_vm.currentStep)+" / "+_vm._s(_vm.tree.Steps.length)+" "+_vm._s(_vm.tree.Steps[_vm.currentStep - 1].Title)+" ")])]}}],null,false,1216007829),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"d-flex justify-center align-center",attrs:{"tile":""}},[_c('v-list',_vm._l((_vm.tree.Steps),function(s,i){return _c('v-list-item',{key:s.ID,on:{"click":function($event){return _vm.jumpToStep(i + 2)}}},[_c('v-list-item-content',[_c('v-list-item-title',{style:({
                    color: (i + 1) === _vm.currentStep ?
                      _vm.tree.BriefingType.Color
                      : ((i + 1) > _vm.currentStep ? 'grey' : 'black' ) 
                  })},[_vm._v(" "+_vm._s(s.Title)+" ")])],1)],1)}),1)],1)],1):_vm._e(),_c('v-stepper-items',_vm._l((_vm.tree.Steps),function(s,i){return _c('v-stepper-content',{key:s.ID,staticClass:"pa-0",attrs:{"step":i + 1}},[_vm._l((s.Groups),function(j){return _c('field-group',{key:j.ID,attrs:{"value":j,"briefing-uuid":_vm.uuid,"briefing-type":_vm.tree.BriefingType,"submitted-form-id":_vm.tree.ID,"visibilities":_vm.visibilities},on:{"updated":_vm.onUpdated}})}),_c('div',{staticClass:"d-flex justify-space-between justify-md-end align-center"},[(i > 0)?_c('button-text',{staticClass:"back-btn",attrs:{"color":_vm.tree.BriefingType.Color},on:{"click":function($event){return _vm.prevStep(i + 1)}}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(_vm._s(_vm.$t('generic.back'))+" ")],1):_c('v-spacer'),_c('button-xl',{attrs:{"emphasize":"","color":_vm.tree.BriefingType.Color},on:{"click":function($event){return _vm.nextStep(i + 1)}}},[_vm._v(" "+_vm._s(_vm.$t('generic.forward'))+" ")])],1)],2)}),1),_c('v-dialog',{attrs:{"persistent":"","max-width":"720px"},model:{value:(_vm.createParticipantDialogShown),callback:function ($$v) {_vm.createParticipantDialogShown=$$v},expression:"createParticipantDialogShown"}},[_c('new-participant-and-role-dialog')],1)]],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }