































































import { Component, Vue } from 'vue-property-decorator';
import { mapActions, mapMutations, mapState } from 'vuex';
import { InputValidationRules } from 'vuetify';
import i18n from '@/i18n';
import { StoreActions, StoreMutations, StoreState } from '@/store/interfaces';
import { mapBriefingState, NullableBriefingStoreState } from '@/store/interfaces/BriefingStore';
import { required, email } from '@/util/validation';
import { FeedbackData, FeedbackForm } from '@/model/user';
import { submitFeedback } from '@/api';

type FeedbackDialog = {
  shown: boolean;
  valid: boolean;
  form: FeedbackForm;
};

const emptyForm = (): FeedbackForm => ({
  Email: null,
  Name: null,
  Subject: null,
  Request: null,
});

@Component({
  computed: {
    ...mapBriefingState(['briefingType']),
    ...mapState(['user']),
  },
  methods: {
    ...mapActions(['saveSuccess']),
    ...mapMutations(['saveStart', 'saveError']),
  }
})
export default class Feedback extends Vue {
  /*
   * refs
   */
  $refs!: {
    form: Element & {
      resetValidation: () => void;
    }
  }


  /*
   * vuex
   */
  readonly briefingType!: NullableBriefingStoreState['briefingType'];
  readonly user!: StoreState['user'];
  readonly saveStart!: StoreMutations.saveStart;
  readonly saveError!: StoreMutations.saveError;
  readonly saveSuccess!: StoreActions.saveSuccess;

  /*
   * data
   */
  feedbackDialog: FeedbackDialog = {
    shown: false,
    valid: false,
    form: emptyForm()
  };

  feedbackValidation: { [k in keyof FeedbackForm]?: InputValidationRules } = {
    Email: [ required(i18n.t('feedback.labels.email')), email(i18n.t('feedback.labels.email')) ],
    Request: [ required(i18n.t('feedback.labels.request')) ],
  };

  /*
   * computed
   */
  get color() {
    return this.briefingType?.Color ?? undefined;
  }

  /*
   * methods
   */
  showFeedbackDialog() {
    //this.feedbackDialog.shown = true;

    if (this.user) {
      this.feedbackDialog.form.Name = this.user.FirstName + ' ' + this.user.Surname;
      this.feedbackDialog.form.Email = this.user.Email;
    }
  }

  dismissFeedbackDialog() {
    this.feedbackDialog.shown = false;
    this.feedbackDialog.form = emptyForm();
    this.feedbackDialog.valid = false;
    this.$refs.form.resetValidation();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isFormValid(form: FeedbackForm): form is FeedbackData {
    return this.feedbackDialog.valid;
  }

  async submitFeedback() {
    if (!this.isFormValid(this.feedbackDialog.form)) {
      return;
    }

    try {
      this.saveStart();

      await submitFeedback(this.feedbackDialog.form);

      this.saveSuccess();
      this.dismissFeedbackDialog();
    } catch (e) {
      this.saveError(e);
    }
  }
}
