<template>
  <internal-layout>
    <template #header>
      <multi-tiles :title="$t('toolbox.title')" />
    </template>

    <template #mobile-header>
      <multi-tiles :title="$t('toolbox.title')" />
    </template>

    <template default>
      <v-tabs centered class="mb-10">
        <v-tab v-for="t in tabs" :key="t" :to="{ name: t }">
          {{ $t(`toolbox.tabs.${t}`) }}
        </v-tab>
      </v-tabs>

      <router-view />
    </template>
  </internal-layout>
</template>

<script>
import { toolboxChildren } from '@/routes';
import InternalLayout from '@/components/layout/InternalLayout.vue';
import MultiTiles from '@/components/layout/MultiTiles.vue';

export default {
  name: 'ToolboxScreen',
  components: {
    InternalLayout,
    MultiTiles,
  },
  computed: {
    tabs() {
      return toolboxChildren.map(r => r.name);
    }
  }
}
</script>
