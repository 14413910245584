<template>
  <div v-if="related.length > 0" class="related-briefings canvas-field">
    <v-simple-table>
      <template #default>
        <tbody>
          <tr v-for="(b, i) in related" :key="`briefing-${i}`">
            <td class="name">
              <router-link :to="{ name: 'brief_canvas', params: { uuid: b.Uuid } }">
                {{ b.Title }}
              </router-link>
            </td>
            <td class="submitter">
              {{ b.SubmitterName }}
            </td>
            <td class="date">
              {{ b.Created }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { sortFormData } from '@/model/brief';
import { field, relatedBriefings, fieldTitle } from '@/pdf/components';
import ElementBase from './ElementBase';

export default {
  name: 'RelatedBriefings',
  mixins: [ ElementBase ],
  data: () => ({
    related: [],
  }),
  computed: {
    ...mapState(['userId']),
  },
  async created() {
    if (this.value.SubmittedData.Related) {
      this.related = this.value.SubmittedData.Related
        .sort(sortFormData);
    } else {
      const values = this.value.SubmittedData.Value.split(',');
      this.related = this.Briefings.filter(s => values.some(v => s.ID.toString() === v));
    }
  },
  methods: {
    renderPdfContent() {
      if (this.related.length === 0) {
        return undefined;
      }

      return field([
        fieldTitle(this.value),
        relatedBriefings(this.related),
      ]);
    }
  }
}
</script>

<style lang="sass">
.related-briefings

  td
    @include table-border-bottom
    font-size: 16px
    font-weight: $font-medium
    padding: 0 6px
    &.name
      @media #{map-get($display-breakpoints, 'lg-and-up')}
        width: 60%
      @media #{map-get($display-breakpoints, 'md-only')}
        width: 47%
    &.date, .submitter
      min-width: 97px
      max-width: 97px + 58px
  .date, .submitter
    font-size: 14px
    color: #AAAAAA
</style>
