













import Vue from 'vue';

export default Vue.extend({
  name: 'LogoutBtn',
  methods: {
    onClick(e: any) {
      this.$emit('click', e);
    }
  }
});
