<template>
  <div v-if="points.length > 0" class="canvas-matrix-chart canvas-field">
    <h2 class="canvas-field-title" v-html="title" />

    <div class="matrix-chart-wrapper">
      <matrix-chart
        ref="chart"
        :data="points"
        :color="color"
        :label-x="labelX"
        :label-y="labelY"
      />
    </div>
  </div>
</template>

<script>
import { sortFormData } from '@/model/brief';
import { fieldTitle, field } from '@/pdf/components';
import ElementBase from './ElementBase';
import MatrixChart from '@/components/common/MatrixChart.vue';

export default {
  name: 'CanvasMatrixChart',
  components: {
    MatrixChart,
  },
  mixins: [ ElementBase ],
  data: () => ({
    points: []
  }),
  computed: {
    labelX() {
      return this.value.SubmittedData?.AxisX ?? '';
    },
    labelY() {
      return this.value.SubmittedData?.AxisY ?? '';
    }
  },
  created() {
    if (this.value.SubmittedData?.MatrixOptions?.length > 0) {
      this.points = this.value.SubmittedData.MatrixOptions
        .sort(sortFormData);
    }
  },
  methods: {
    renderPdfContent() {
      if (this.points.length > 0) {
        return field([
          fieldTitle(this.value),
          ...this.$refs.chart.renderPdfContent()
        ]);
      } else {
        return undefined;
      }
    }
  }
}
</script>

<style>

</style>
