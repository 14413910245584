<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="249"
    height="249"
    viewBox="0 0 249 249"
  >
    <rect width="249" height="249" fill="#f04568" />
    <text
      x="50%"
      y="224"
      text-anchor="middle"
      fill="#fff"
      font-size="32"
    >{{ $t('briefing.creative.name').toUpperCase() }}</text>
    <path d="M29.392,54.435c-7.06.454-13.467,3.143-17.1,12.723a2.686,2.686,0,0,1-2.567,1.756c-1.955,0-8-4.869-9.722-6.045C0,77.36,6.676,90.1,22.525,90.1c13.349,0,22.524-7.7,22.524-21.15a15.246,15.246,0,0,0-.171-1.607L29.392,54.435ZM80.576,0A10.746,10.746,0,0,0,73.5,2.895C37.53,35.027,33.787,35.782,33.787,45.24a16.291,16.291,0,0,0,1.536,6.81l11.23,9.358a16.192,16.192,0,0,0,3.94.533c10.929,0,17.264-8,37.158-45.129A18.106,18.106,0,0,0,90.1,8.719C90.1,3.632,85.522,0,80.576,0Z" transform="translate(78.854 67.339)" fill="#fff" />
  </svg>
</template>

<script>
export default {
  name: 'BriefingTypeCreative'
}
</script>
