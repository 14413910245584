<script>
import { Radar } from 'vue-chartjs';
import { SPIDER_CHART_MAX_VALUE } from '@/model/charts';
import { chartImage } from '@/pdf/components';

const COLOR_PTTRN = /^rgb\(([^)]+)\)$/;

const addOpacity = (color) => {
  if (color.startsWith('#')) {
    return color + "10";
  }

  const match = COLOR_PTTRN.exec(color);

  if (!match) {
    throw new Error(`Bad color value ${color}`);
  }

  return `rgba(${match[1]}, 0.1)`;
};

const mapChartData = (data, color) => ({
  labels: data.map((d) => d.Title),
  datasets: [{
    label: '',
    pointRadius: 10,
    pointHoverRadius: 15,
    backgroundColor: addOpacity(color),
    borderColor: color,
    pointBorderColor: color,
    pointBackgroundColor: color,
    data: data.map((d) => d.Value),
  }]
});

export default {
  name: 'SpiderChart',
  extends: Radar,
  props: {
    data: {
      type: Array,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    draggable: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    chartData: {
      labels: [],
      datasets: []
    }
  }),
  computed: {
    options() {
      return {
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            title: () => '',
            footer: () => ''
          }
        },
        layout: {
          padding: {
              left: 5,
              right: 5,
              top: 0,
              bottom: 0
          }
        },
        animation: false,
        scale: {
          ticks: {
            min: 0,
            max: SPIDER_CHART_MAX_VALUE,
            display: false
          },
          pointLabels: {
            fontSize: 16,
            fotFamily: 'TTNorms'
          }
        },
        dragData: this.draggable,
        dragDataRound: 0,
        dragX: true,
        dragOptions: {
          showTooltip: true
        },
        onDrag: (e) => {
          // change cursor style to grabbing during drag action
          e.target.style.cursor = 'grabbing';
        },
        onDragEnd: (e, datasetIndex, index, value) => {
          // restore default cursor style upon drag release
          e.target.style.cursor = 'default';
          this.$emit('change', { index, value });
        },
      };
    }
  },
  created() {
    this.chartData = mapChartData(this.data, this.color, this.areaColor);
  },
  mounted () {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options);
  },
  methods: {
    reRender() {
      this.chartData = mapChartData(this.data, this.color, this.areaColor);
      this.renderChart(this.chartData, this.options);
    },
    renderPdfContent() {
      return [
        chartImage(this.$refs.canvas)
      ];
    }
  },
}
</script>
