<template>
  <div class="briefing-field">
    <date-picker
      v-model="data"
      :label="value.Title"
      :briefing-type="briefingType"
      :is-saving="isSaving"
      @input="onChange"
    />
  </div>
</template>

<script>
import FieldBase from './FieldBase'
import DatePicker from '@/components/common/DatePicker.vue';

export default {
  name: 'SingleDatePicker',
  components: {
    DatePicker
  },
  mixins: [ FieldBase ],
  data: () => ({
    menu: false,
  }),
  methods: {
    async onChange() {
      try {
        this.isSaving = true;
        await this.submit(this.data);
        this.menu = false;
        this.onUpdated({
          id: this.value.ID,
          value: this.data,
        });
      } catch (e) {
        this.setError(e);
      }

      this.isSaving = false;
    }
  }
}
</script>
