<script>
/**
 * Read-only unformatted text to show dynamically in the page as a heading.
 */
export default {
  name: 'BriefingHeading',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  render(h) {
    return h(`h${this.value.Level}`, null, [ this.value.Title ]);
  },
};
</script>