<script>
import AddUserOption from './AddUserOption';

export default {
  components: {
    AddUserOption
  },
  created() {
    this.update();
  },
  methods: {
    transformSubmitBody(body) {
      return {
        ...body,
        IsModelOption: this.value.Model !== undefined ? '1' : '0'
      };
    },
    update() {
      this.onUpdated({
        id: this.value.ID,
        value: this.value.Options
          .find(o => o.value === this.data)
          ?.matchValue
      });
    },
    async onChange() {
      try {
        this.isSaving = true;
        await this.submit(this.data);
        this.update();
      } catch (e) {
        this.setError(e);
      }

      this.isSaving = false;
    },
    onOptionAdded(newOption) {
      const { ID, Title } = newOption;

      this.value.Options.push({
        text: Title,
        value: ID
      });
    },
  }
};
</script>
