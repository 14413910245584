<template>
  <div v-if="isRenderable" class="canvas-options canvas-field">
    <h2 class="canvas-field-title" v-html="title" />
    <div class="d-flex flex-wrap">
      <span v-for="o in options" :key="`option-${o}`">{{ o }}</span>
    </div>
  </div>
</template>

<script>
import { sortFormData } from '@/model/brief';
import { optionField, field } from '@/pdf/components';
import ElementBase from './ElementBase';

export default {
  name: 'CanvasOptions',
  mixins: [ ElementBase ],
  data: () => ({
    options: [],
  }),
  computed: {
    isRenderable() {
      return this.options.length > 0;
    }
  },
  created() {
    const submitted = this.value.SubmittedData;
    if (submitted.ID && submitted.Value) {
      const ids = submitted.Value.toString().split(',');
      this.options = this.value.Options
        .filter(o => ids.some(i => i === o.value))
        .map(o => o.text);
    } else if (submitted.UserOptions) {
      this.options = submitted.UserOptions
        .sort(sortFormData)
        .map(o => o.Title);
    } else {
      this.options = [];
    }
  },
  methods: {
    renderPdfContent() {
      if (!this.isRenderable) {
        return undefined;
      }

      return field([
        optionField(this.value, this.options),
      ]);
    }
  }
}
</script>

<style lang="sass">
$element-distance: $canvas-option-margin / 2

.canvas-options
  &.canvas-field
    margin-bottom: 0

  > div
    overflow: hidden
    margin-left: - $element-distance
    margin-right: - $element-distance

  span
    @include canvas-larg-text
    @include table-border-bottom
    padding-bottom: $canvas-option-bottom-padding
    display: inline-block
    margin: 0 $element-distance $canvas-field-margin $element-distance
</style>
