import { required, password, EMAIL_TO_PTTRN, requiredEmail, email, FormType } from '@/util/validation';
import i18n from '@/i18n';
import { ClientFormType, ClientDataType } from './clients';
import { LocationData } from './location';
import { ParticipantFormType, BriefingParticipantFormType, ParticipantDataType, BriefingRoleType, ParticipantPermissions } from './participant';
import { UserFormType } from './user';

const copyHelper = <T> (template: T, data: T): T => {
  const res: any = {};
  Object.keys(template).forEach(key => res[key] = (data as any)[key]);
  return res;
}

export const ParticipantBase = {
  empty: {
    Email: null,
    Company: null,
  },
  validation: {
    email: requiredEmail,
    company: [ required(i18n.t('profile.labels.company')) ],
  }
};

export const ParticipantData = {
  empty: <ParticipantFormType> {
    ...ParticipantBase.empty,
    Title: null,
  },
  validation: {
    ...ParticipantBase.validation,
    title: [ required(i18n.t('participant.labels.name')) ],
  },
  create() {
    return { ...this.empty };
  },
  copy(data: ParticipantFormType) {
    return copyHelper(this.empty, data);
  }
};

export const BriefingParticipantData = {
  empty: <BriefingParticipantFormType> {
    ...ParticipantData.empty,
    Role: null,
    Permission: 0,
  },
  validation: {
    ...ParticipantData.validation,
    Role: [ required(i18n.t('participant.labels.role')) ],
    Permission: [ required(i18n.t('participant.labels.permission')) ],
  },
  create() {
    return { ...this.empty };
  },
  copy(data: BriefingParticipantFormType) {
    return copyHelper(this.empty, data);
  },
  copyParticipantData(data: BriefingParticipantFormType) {
    return copyHelper(ParticipantData.empty, data) as ParticipantDataType;
  },
  copyRoleData(data: BriefingParticipantFormType): BriefingRoleType {
    return {
      Role: data.Role!,
      Permission: ParticipantPermissions[data.Permission],
    };
  }
};

export const UserData = {
  empty: <UserFormType> {
    ...ParticipantBase.empty,
    FirstName: null,
    Surname: null,
    Position: null,
    Phone: null,
    ...LocationData.empty,
  },
  validation: {
    ...ParticipantBase.validation,
    firstname: [ required(i18n.t('profile.labels.firstname')) ],
    surname: [ required(i18n.t('profile.labels.surname')) ],
    position: [ required(i18n.t('profile.labels.position')) ],
    phone: [],
    ...LocationData.validation,
  },
};

export const PasswordData = {
  empty: {
    Password: null
  },
  validation: {
    password: [
      required(i18n.t('login.labels.password')),
      password(i18n.t('login.labels.password')),
    ],
  }
};

export const ClientData: FormType<ClientDataType> = {
  empty: {
    ...LocationData.empty,
    CompanyName: null,
    Location: null,
    CompanyURL: null,
    Email: null,
    Description: null,
  },
  validation: {
    CompanyName: [ required(i18n.t('client.labels.name')) ],
    Country: [],
    Zip: [],
    Location: [],
    Street: [],
    Email: [ email(i18n.t('profile.labels.email')) ],
    CompanyURL: [],
    Description: [],
  },
  copy(data: ClientFormType) {
    return copyHelper(this.empty, data);
  },
  create() {
    return { ...this.empty };
  }
};

export const isInitialUser = (user: any) => user.InitialUser > 0;

export const splitMails: (v: string) => string[] = (input: string) => {
  return input.split(/[,;]/).map(v => {
    const val = v.trim();

    if (val === "") {
      return null;
    }

    const res = EMAIL_TO_PTTRN.exec(val);

    if (res) {
      if (res[1]) {
        return `${res[1]} <${res[2]}>`;
      } else {
        return res[2];
      }
    } else {
      throw new Error(val);
    }
  }).filter((v): v is string => !!v);
}
