





































import { Component, Watch } from 'vue-property-decorator';
import { sortFormData } from '@/model/brief';
import FieldBase from './FieldBase';
import AddUserOption from './AddUserOption.vue';
import TileItem from '@/components/global/TileItem.vue';


@Component({
  components: {
    AddUserOption,
    TileItem,
  }
})
export default class TileGroupField extends FieldBase {
  /* 
   * data
   */
  selected: number[] | number | null = null;

  /* 
   * computed
   */
  get multiselect() {
    return this.value.Multiselect === 1;
  }

  get selectedIds() {
    if (this.multiselect && (this.selected as number[]).length > 0) {
      return (this.selected as number[]).map(i => this.value.Options![i].value);
    } else if (!this.multiselect && this.selected !== null && this.selected !== undefined) {
      return [this.value.Options![this.selected as number].value];
    } else {
      return [];
    }
  }

  @Watch('selected') onSelectedChange() {
    this.dataChanged();
  }

  /*
   * hooks
   */
  created() {
    this.value.Options = this.value.Options?.sort(sortFormData);

    let ids: string[];
    if (this.data && typeof this.data === 'string') {
      ids = this.data.split(',');
    } else {
      ids = [];
    }
    
    const selected = this.value.Options!.map((o, index) => {
      if (ids.some(d => d === o.value)) {
        return index;
      } else {
        return null;
      }
    }).filter((i): i is number => i !== null);

    if (this.multiselect) {
      this.selected = selected;
    } else {
      this.selected = selected[0] || null;
    }

    this.update();
  }

  /*
   * methods
   */
  update() {
    this.onUpdated({
      id: this.value.ID,
      value: this.selectedIds.map(v =>
        this.value.Options!
          .find(o => o.value === v)!
          .matchValue
      ),
    });
  }

  async dataChanged() {
    try {
      this.isSaving = true;

      if (this.selectedIds.length > 0) {
        await this.submit(this.selectedIds.join(','));
      } else {
        await this.submit(null);
      }

      this.update();
    } catch (e) {
      this.setError(e);
    }

    this.isSaving = false;
  }

  async onOptionAdded(res: any) {
    this.value.Options!.push({
      text: res.Title as string,
      value: res.ID as string,
      matchValue: res.ID as string,
    });

    if (this.multiselect) {
      (this.selected as number[])!.push(this.value.Options!.length - 1);
    } else {
      this.selected = this.value.Options!.length - 1;
    }
  }
}
