import Vue from 'vue';
import VueRouter, { Route, NavigationGuardNext, RouteConfig } from 'vue-router';
import routes, { LOGIN, REGISTRATION_ACTIVATED, SERVER_ERROR, loginRoute, activatedRoute, serverErrorRoute, collectionsRoute, redirectToLogin } from '@/routes';
import i18n from '@/i18n';
import store from '@/store';
import { isInitialUser } from '@/model/userdata';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  // eslint-disable-next-line no-undef
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      // delay the scrolling with the page animation length
      // currently 300ms
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition);
        } else if (to.hash) {
            resolve({ selector: to.hash });
        } else {
           resolve({ x: 0, y: 0 });
        }
      }, 300);
    });
  }
});

const createMetaAsFallback = () => {
  const tag = document.createElement('meta');
  tag.name = 'description';
  tag.content = '';
  document.head.appendChild(tag);

  return tag;
}

// the <meta> tag is in the top part of the header, the browser is already created it when
// the JS code is run. We can cache it as a top-level variable as optimization
const metaTag: HTMLMetaElement = document.querySelector('meta[name="description"]') || createMetaAsFallback();

const handleMobile = (to: Route, target: RouteConfig, next: NavigationGuardNext<Vue>) => {
  if (to.matched.some(record => !record.meta.mobile && document.documentElement.clientWidth <= 600)) {
    // we are on mobile and the target route shouldn't be available on it
    Vue.$gtm.navigation(target.name || '???', target.path);
    next({ name: target.name });
  } else {
    Vue.$gtm.navigation(to.name || '???', to.path);

    if (to.meta.defaultChild) {
      next({ name: to.meta.defaultChild });
    } else {
      next();
    }
  }
}

router.beforeEach((to, from, next) => {
  console.log(`[NAVIGATION] ${from.fullPath} -> ${to.fullPath}`);

  // in-page navigation, only the query/hash differs
  // we shouldn't do anything in this case
  // if what's happening is not in-page navigation but the
  //
  // user arrives to the page, then from.path === to.path, so
  // we need to filter out this case (name is falsy in this case)
  if (from.name && from.path === to.path) {
    next();
    return;
  }

  if (to.meta?.pageDescription) {
    const metaDesc = i18n.t(to.meta.pageDescription) as string;

    if (metaTag.content !== metaDesc) {
      metaTag.content = metaDesc;
    }
  } else {
    metaTag.content = i18n.t('routes.meta.description') as string;
  }

  if (to.meta?.pageTitle) {
    const metaTitle = i18n.t(to.meta.pageTitle) as string;

    if (document.title !== metaTitle) {
      document.title = metaTitle;
    }
  } else {
    document.title = i18n.t('routes.meta.title') as string;
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    if (!store.getters.isAuthenticated) {
      // if not, redirect to login page.
      Vue.$gtm.action('logout', { userId: undefined });
      Vue.$gtm.navigation(LOGIN, loginRoute.path);

      next(redirectToLogin(to));
    } else if (to.name == REGISTRATION_ACTIVATED) {
      // we don't want an infinite loop
      handleMobile(to, collectionsRoute, next);
    } else {
      store.dispatch('getUser').then((user) => {
        if (isInitialUser(user)) {
          Vue.$gtm.navigation(REGISTRATION_ACTIVATED, activatedRoute.path);
          next({ name: REGISTRATION_ACTIVATED });
        } else {
          handleMobile(to, collectionsRoute, next);
        }
      }).catch(() => {
        Vue.$gtm.navigation(SERVER_ERROR, serverErrorRoute.path);
        next({ name: SERVER_ERROR });
      });
    }
  } else {
    handleMobile(to, loginRoute, next);
  }
});

export default router;
