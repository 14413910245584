<template>
  <add-new-value
    :color="color"
    :loading="isSaving"
    :label="$t('create_briefing.labels.new_value')"
    @input="submit"
  />
</template>

<script>
import { createUserOption } from '@/api';
import AddNewValue from './AddNewValue';

export default {
  name: 'AddUserOption',
  components: {
    AddNewValue
  },
  props: {
    color: {
      type: String,
      required: true,
    },
    nextIndex: {
      type: Number,
      required: true,
    },
    getParentId: {
      type: Function,
      required: true,
    }
  },
  data: () => ({
    isSaving: false
  }),
  methods: {
    async submit(value) {
      const val = {
        Title: value,
        Sort: this.nextIndex,
        Parent: await this.getParentId(),
      };

      try {
        this.isSaving = true;
        const res = await createUserOption(val);
        this.$emit('option-added', res);
      } catch (e) {
        this.setError(e);
      }

      this.isSaving = false;
    }
  }
}
</script>
