<template>
  <v-combobox
    v-model="collection"
    :label="$t('create_briefing.labels.project')"
    :items="collectionItems"
    :rules="required ? validation : null"
    :hint="projectNewlyCreated"
    persistent-hint
    :required="required"
  />
</template>

<script>
import { required } from '@/util/validation';
import i18n from '@/i18n';
import { createProjectCollection } from '@/api';

const isNew = (value) => typeof value === 'string';

export const getCollectionID = async (p, userId) => {
  if (isNew(p)) {
    const res = await createProjectCollection({ Title: p, User: userId });
    return res.ID;
  } else {
    return p;
  }
};

export default {
  name: 'ChooseCollection',
  props: {
    collections: {
      type: Array,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [ String, Number ],
      default: undefined,
    }
  },
  data: () => ({
    validation: [ required(i18n.t('create_briefing.labels.project')) ],
  }),
  computed: {
    collectionItems() {
      return this.collections.map((c) => ({
        text: c.Title,
        value: c.ID,
      }));
    },
    projectNewlyCreated() {
      return (this.collection != null && isNew(this.collection)) ? this.$t('create_briefing.new_project_warning') : null;
    },
    collection: {
      get() {
        return this.collectionItems.find(i => i.value === this.value) || this.value;
      },
      set(value) {
        this.$emit('input', isNew(value) ? value : value.value);
      }
    }
  }
}
</script>
