<template>
  <dialog-content :title="$t('client.delete.title')">
    <p>{{ $t('client.delete.text', [title]) }}</p>

    <template #actions>
      <button-xl @click="dismiss">
        {{ $t('generic.cancel') }}
      </button-xl>
      <button-xl emphasize @click="submit">
        {{ $t('generic.ok') }}
      </button-xl>
    </template>
  </dialog-content>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'ClientDeleteDialog',
  props: {
    value: {
      type: Object,
      default: null,
    }
  },
  computed: {
    title() {
      return this.value?.CompanyName;
    }
  },
  methods: {
    ...mapActions(['deleteClient', 'saveSuccess']),
    ...mapMutations(['saveStart', 'saveError']),
    dismiss() {
      this.$emit('dismiss');
    },
    async submit() {
      try {
        this.saveStart();

        await this.deleteClient(this.value.ID);

        this.saveSuccess();
        this.$emit('success', this.value.ID);
      } catch (e) {
        this.saveError(e);
      }
    }
  }
}
</script>
