import { BRIEFING_MODULE_NAME } from './BriefingStore';
import { BriefingRoleType } from '@/model/participant';
import { mapActions } from 'vuex';

export const BRIEFING_ACTION_KEYS = {
  addRole: 'addRole',
  editRole: 'editRole',
  removeRole: 'removeRole',
  initParticipantsSbf: 'initParticipantsSbf'
};

export type AddRolePayload = Readonly<{
  participantId: number;
  data: BriefingRoleType;
}>;
export type addRole = (payload: AddRolePayload) => Promise<void>;

export type EditRolePayload = Readonly<{
  roleId: number;
  data: BriefingRoleType;
}>;
export type editRole = (payload: EditRolePayload) => Promise<void>;

export type removeRole = (roleId: number) => Promise<void>;

export type initParticipantsSbf = () => Promise<void>;

type MapOptionsArray = Array<keyof typeof BRIEFING_ACTION_KEYS>;
type MapOptionsObject = {
  [key: string]: keyof typeof BRIEFING_ACTION_KEYS
};

export const mapBriefingActions = (options: MapOptionsArray | MapOptionsObject) => {
  return mapActions(BRIEFING_MODULE_NAME, options as any);
};
