






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class InputLabel extends Vue {
  @Prop({ type: String, required: true }) value!: string;
}
