


























import Vue from 'vue';
import BriefingFooter from './BriefingFooter.vue';

export default Vue.extend({
  name: 'InternalLayout',
  components: {
    BriefingFooter,
  },
  props: {
    noHeader: {
      type: Boolean,
      default: false,
    },
    justifyHeader: {
      type: String,
      default: 'end'
    },
    justifyMobileHeader: {
      type: String,
      default: 'right'
    },
  }
});
