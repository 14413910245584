

















































import { Vue, Component } from 'vue-property-decorator';
import { mapMutations, mapActions } from 'vuex';
import { StoreMutations, StoreActions } from '@/store/interfaces';
import { getSharedBriefings } from '@/api';
import { SharedBriefing } from '@/model/collection';
import { BRIEF_CANVAS } from '@/routes';
import { parseISO } from 'date-fns';

@Component({
  methods: {
    ...mapActions(['showSnackbar']),
    ...mapMutations([ 'loadStart', 'loadSuccess', 'loadError' ]),
  }
})
export default class SharedBriefings extends Vue {
  /*
   * vuex
   */
  readonly showSnackbar!: StoreActions.showSnackbar;

  readonly loadStart!: StoreMutations.loadStart;
  readonly loadSuccess!: StoreMutations.loadSuccess;
  readonly loadError!: StoreMutations.loadError;

  /*
   * data
   */
  briefings: SharedBriefing[] = [];

  /*
   * computed
   */

  get headers() {
    const titleCol = { text: this.$t('projects.labels.name'), value: 'Title' };
    const dateCol = { text: this.$t('projects.labels.date'), width: '97px', value: 'Created' };
    const creatorCol = { text: this.$t('shared.labels.creator'), width: '170px', value: 'CreatedBy.Name' };
    const actionCol = { text: this.$t('projects.labels.actions'), value: 'Permission', align: 'right', width: '87px' };

    if (this.$isMobile) {
      return [ titleCol, actionCol ];
    } else {
      return [ titleCol, dateCol, creatorCol, actionCol ];
    }
  }

  /*
   * hooks
   */
  async created() {
    try {
      this.loadStart();
      this.briefings = (await getSharedBriefings()).map((m) => ({
        ...m,
        Created: this.$formatDate(parseISO(m.Created), 'P'),
      }));
      this.loadSuccess();
    } catch (e) {
      this.loadError(e);
    }
  }

  /*
   * methods
   */
  async doCopy(uuid: string) {
    const { href } = this.$router.resolve({
      name:
      BRIEF_CANVAS,
      params: { uuid } },
      // TODO
      (this.$router as any).options.base,
      true
    );

    if (navigator.share) {
      // native share on Android/iOS
      try {
        await navigator.share({
          title: 'Share briefing',
          url: href
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    } else {
      try {
        await this.$copyText(`${window.location.origin}${href}`);
        this.showSnackbar(this.$t('projects.copy_link.notification') as string); 
      } catch (e) {
        this.loadError(e);
      }
    }
  }
}
