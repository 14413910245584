<template>
  <external-layout>
    <template #header>
      <multi-tiles :title="$t('password_reset.tile')" />
    </template>

    <template #mobile-header>
      <multi-tiles :title="$t('password_reset.tile')" />
    </template>

    <div id="password-reset">
      <h1>{{ $t('password_reset.title') }}</h1>

      <v-stepper v-model="step" class="elevation-0">
        <v-stepper-items>
          <v-stepper-content step="1" class="pa-0">
            <p>
              {{ $t('password_reset.lost_your_pw') }} <br>
              {{ $t('password_reset.no_problem') }}
            </p>

            <p>{{ $t('password_reset.we_will_send_you_an_email') }}</p>

            <v-form
              v-model="valid"
              @submit.prevent="submit"
            >
              <v-text-field
                v-model="email"
                :rules="emailRules"
                :label="$t('login.labels.email')"
                required
              />

              <v-alert
                v-if="error != null"
                outlined
                tile
                type="error"
              >
                {{ error }}
              </v-alert>
              
              <button-xl
                emphasize
                type="submit"
                :disabled="!valid"
                @click.prevent="submit"
              >
                {{ $t('password_reset.submit') }}
              </button-xl>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="2" class="pa-0">
            <p>
              {{ $t('password_reset.we_sent_you_an_email') }}
            </p>

            <p>
              {{ $t('password_reset.check_your_mailbox') }}<br>
              {{ $t('password_reset.try_again') }}
            </p>

            <button-xl emphasize :disabled="!valid" :to="{ name: 'login' }">
              {{ $t('password_reset.to_login') }}
            </button-xl>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </external-layout>
</template>

<script>
import i18n from '@/i18n';
import { required } from '@/util/validation';
import { passwordReset } from '@/api';
import ExternalLayout from '@/components/layout/ExternalLayout.vue';
import MultiTiles from '@/components/layout/MultiTiles.vue';

export default {
  name: 'PasswordReset',
  components: {
    ExternalLayout,
    MultiTiles
  },
  data: () => ({
    step: 1,
    valid: false,
    error: null,
    email: null,
    emailRules: [ required(i18n.t('login.labels.email')) ],
  }),
  methods: {
    async submit() {
      try {
        await passwordReset(this.email);
        this.step = 2;
      } catch (e) {
        this.error = e.message;
      }
    }
  }
}
</script>

<style lang="sass">
#password-reset
  max-width: $external-content-width
</style>
