<template>
  <div>
    <h1>{{ $t('invite.title') }}</h1>
    <p>
      <strong v-if="!inRegistration">{{ $t('invite.text1') }}<br></strong>
      <strong>{{ $t('invite.text2') }}</strong>
    </p>
    <p>
      {{ $t('invite.text3') }}
    </p>
    <p>
      {{ $t('invite.text4') }}
    </p>

    <v-form @submit.prevent="$event('submit')">
      <v-textarea
        outlined
        placeholder="example1@gmail.com; example2@gmail.com; ..."
        :error-messages="errors"
        @change="onChange"
      />

      <slot :valid="valid" />
    </v-form>
  </div>
</template>

<script>
import i18n from '@/i18n';
import { splitMails } from '@/model/userdata';

export default {
  name: 'InviteForm',
  props: {
    inRegistration: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: null,
    }
  },
  data: () => ({
    emails: null,
    errors: null,
  }),
  computed: {
    valid() {
      return this.emails && this.errors === null;
    }
  },
  created() {
    this.emails = this.value;
  },
  methods: {
    onChange(e) {
      if (e) {
        try {
          this.emails = splitMails(e);
          this.errors = null;
        } catch (e) {
          this.emails = null;
          this.errors = i18n.t('invite.email_error', [e.message]);
        }
      } else {
        this.emails = null;
        this.errors = i18n.t('invite.no_email');
      }
      
      this.$emit('input', this.emails);
    }
  }
}
</script>
