<template>
  <v-text-field
    v-model="amount"
    class="briefing-field"
    :label="value.Title"
    :placeholder="value.Placeholder"
    :color="color"
    :rules="validation"
    :loading="isSaving"
    :disabled="isSaving"
    @change="onChange"
  />
</template>

<script>
import { money, parseMoney } from '@/util/validation';
import FieldBase from './FieldBase';
import InputFieldBase from './InputFieldBase';

export default {
  name: 'BudgetField',
  mixins: [ FieldBase, InputFieldBase ],
  computed: {
    amount: {
      get() {
        if (typeof this.data === 'number') {
          return this.$formatMoney(this.data);
        } else {
          return this.data;
        }
      },
      set(value) {
        this.data = parseMoney(value);
      },
    }
  },
  created() {
    this.update();
  },
  methods: {
    createExtraValidationRules() {
      const { Title, MaxLength, MinLength } = this.value;
      this.validation.push(money(Title, MinLength, MaxLength));
    },
    update() {
      this.onUpdated({
        id: this.value.ID,
        value: this.data,
      });
    },
    async onChange() {
      try {
        this.isSaving = true;
        await this.submit(this.data);
        this.update();
      } catch (e) {
        this.setError(e);
      }

      this.isSaving = false;
    }
  }
}
</script>
