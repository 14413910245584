





























export default {
  name: 'FloatingActionBtn',
  props: {
    icon: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'none',
    }
  },
  data: () => ({
    show: false
  })
}
