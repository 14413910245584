<template>
  <div class="canvas-tiles canvas-field">
    <h2 class="canvas-field-title" v-html="title" />
    <div class="d-flex flex-wrap tile-field-content">
      <tile-item
        v-for="item in options"
        :key="item.ID"
        :value="item"
        active
      />
    </div>
  </div>
</template>

<script>
import { sortFormData } from '@/model/brief';
import { tilesField, fieldTitle, field } from '@/pdf/components';
import ElementBase from './ElementBase';
import TileItem from '@/components/global/TileItem.vue';

export default {
  name: 'CanvasTileGroup',
  components: {
    TileItem,
  },
  mixins: [ ElementBase ],
  data: () => ({
    options: [],
  }),
  created() {
    const submitted = this.value.SubmittedData;
    if (submitted.ID && submitted.Value) {
      const ids = submitted.Value.toString().split(',');
      this.options = this.value.Options
        .filter(o => ids.some(i => i === o.value))
        .sort(sortFormData);
    } else if (submitted.UserOptions) {
      this.options = submitted.UserOptions
        .sort(sortFormData);
    } else {
      this.options = [];
    }
  },
  methods: {
    async renderPdfContent() {
      if (this.options.length === 0) {
        return undefined;
      }

      const tiles = await tilesField(this.options);

      return field([
        fieldTitle(this.value),
        ...tiles
      ]);
    }
  }
}
</script>
