<template>
  <external-layout no-header>
    <div id="login-wrapper" class="flex-grow-1 flex-shrink-1">
      <login-image id="login-logo" />

      <v-form
        id="login"
        ref="form" 
        v-model="valid"
        class="mx-auto"
        @submit.prevent="submit"
      >
        <v-text-field
          ref="emailField"
          v-model="email"
          :rules="emailRules"
          :label="$t('login.labels.email')"
          :placeholder="isAutofilledEmail && email === null ? ' ' : null"
          type="email"
          autocomplete="email"
          autofocus
          autocapitalize="off"
          autocorrect="off"
          spellcheck="false"
          required
        />

        <v-text-field
          ref="passwordField"
          v-model="password"
          :rules="passwordRules"
          :label="$t('login.labels.password')"
          :placeholder="isAutofilledPwd && password === null ? ' ' : null"
          type="password"
          autocomplete="current-password"
          autocapitalize="off"
          autocorrect="off"
          spellcheck="false"
          required
        />

        <v-alert
          v-if="error != null"
          outlined
          tile
          type="error"
        >
          {{ error }}
        </v-alert>

        <button-row size="xl" class="margin-top">
          <button-xl
            emphasize
            type="submit"
            :disabled="!valid"
            @click.prevent="submit"
          >
            {{ $t('login.submit') }}
          </button-xl>

          <button-xl :to="{ name: 'registration' }">
            {{ $t('registration.title') }}
          </button-xl>
        </button-row>


        <router-link class="d-block unimportant" :to="{ name: 'reset_password' }">
          {{ $t('login.forgot_password') }}
        </router-link>
      </v-form>
    </div>
  </external-layout>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import i18n from '@/i18n';
  import { required } from '@/util/validation';
  import { REGISTRATION_ACTIVATED, NEW_BRIEFING_SETUP } from '@/routes';
  import ExternalLayout from '@/components/layout/ExternalLayout.vue';
  import LoginImage from '@/components/decoration/LoginImage.vue';

  export default {
    name: 'Login',
    components: {
      ExternalLayout,
      LoginImage,
    },
    props: {
      redirect: {
        type: String,
        default: null,
      },
    },
    data: () => ({
      valid: false,
      error: null,
      email: null,
      emailRules: [ required(i18n.t('login.labels.email')) ],
      password: null,
      passwordRules: [ required(i18n.t('login.labels.password')) ],
      isAutofilledEmail: null,
      isAutofilledPwd: null,
    }),
    computed: {
      ...mapGetters(['isInitialUser']),
    },
    mounted() {
      // ugly workaround to fix chrome autofill bug
      let times = 0;

      const interval = setInterval(() => {
        times += 1;

        if (this.isAutofilledEmail || times >= 20) {
          clearInterval(interval);
        }

        this.isAutofilledEmail = !!document.querySelector('input[type="email"]:-webkit-autofill');
        this.isAutofilledPwd = !!document.querySelector('input[type="password"]:-webkit-autofill');
      }, 100);
    },
    methods: {
      ...mapActions(['login']),
      async submit() {
        try {
          const id = await this.login({ email: this.email, password: this.password });

          if (id != undefined) {
            if (this.redirect) {
              this.$router.push({ path: this.redirect });
            } else {
              this.$router.push({ name: NEW_BRIEFING_SETUP });
            }
          } else {
            this.error = this.$t('login.error');
          }
        } catch (e) {
          this.error = e.message;
        }
      }
    }
  }
</script>

<style lang="sass">
#login-screen
  @media #{map-get($display-breakpoints, 'sm-and-down')}
    padding-left: 0px

#login-wrapper
  margin-top: 75px
  max-width: 504px

#login-logo
  width: 100%
  height: auto

#login
  margin-top: 11px
  max-width: $external-content-width

  .unimportant
    text-decoration: none !important
</style>
