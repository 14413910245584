<template>
  <div class="briefing-field" v-html="value.Content" />
</template>

<script>
/**
 * Read-only formatted text to show dynamically in the page.
 */
export default {
  name: 'BriefingHtmlText',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
}
</script>
