import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapMutations } from 'vuex';
import { BriefingField } from '@/model/brief';
import { updateSubmittedFormField, createSubmittedFormField } from '@/api';
import { BriefingType, BriefingFieldInterface, FieldUpdatedEvent, SubmittedFormFieldData } from '@/model/brief2';
import { InputValidationRule } from 'vuetify';
import { StoreMutations } from '@/store/interfaces';

@Component
export class FieldBaseProps extends Vue {
  @Prop({ type: BriefingField, required: true }) value!: BriefingFieldInterface;
  @Prop({ type: Object, required: true }) briefingType!: BriefingType;
  @Prop({ type: Number, required: true }) submittedFormId!: number;
  @Prop({ type: String, required: true }) briefingUuid!: string;
}

@Component
export class FieldBasePropsWithVisibility extends FieldBaseProps {
  // TODO: type
  @Prop({ type: Object, required: true }) visibilities!: any;
}

@Component({
  methods: mapMutations(['setError'])
})
export default class FieldBase extends FieldBaseProps {
  /*
   * vuex 
   */
  setError!: StoreMutations.setError;

  /*
   * data
   */
  submittedId: number | null = null;
  isSaving = false;
  data: string | number | null = null;
  lastData: string | number | null = null;
  validation: InputValidationRule[] = [];

  /*
   * computed
   */
  get color() {
    return this.briefingType.Color;
  }

  get customOptionsAllowed() {
    return !this.value.Model && this.value.DisableCustomOption === 0;
  }

  /*
   * hooks
   */
  created() {
    if (this.value.SubmittedData) {
      const d = this.value.SubmittedData;
      this.submittedId = d.ID;

      // model fields
      if (this.value.Model) {
        const val = d.Related?.map(r => String(r.ID))?.join(',') ?? null;
        this.lastData = val;
        this.data = val;
      } else if (this.value.MilestoneOptions) {
        const val = this.value.SubmittedData?.MilestoneOptions?.map(r => String(r.ID))?.join(',') ?? null;
        this.lastData = val;
        this.data = val;

      // normal fields
      } else {
        this.lastData = d.Value;
        this.data = String(d.Value);
      }
    }
  }

  /*
   * methods
   */
  onUpdated(field: FieldUpdatedEvent) {
    this.$emit('updated', field);
  }

  async submit(data: string | number | null): Promise<void> {
    // only save the field value if it really changed
    if (data === this.lastData) {
      return;
    }

    // manually validate the field before sending
    // vuetify built-in validation works on the form level,
    // the validation result cannot be queried for individal form fields
    // or at least not in a supported, future-proof way
    const validationResult = this.validation.reduce((result, validator) => {
      return result && validator(data) === true;
    }, true);

    if (!validationResult) {
      return;
    }

    if (this.submittedId) {
      return updateSubmittedFormField(this.submittedId, {
        Value: data
      });
    } else {
      const { ID: fid, Parent, FieldType, Name, Sort, Title, } = this.value;

      const body = this.transformSubmitBody({
        ParentID: this.submittedFormId,
        ParentElementID: Parent,
        BriefingFieldClassName: FieldType,
        Name,
        Sort,
        Title,
        RelatedFieldID: fid,
        Value: data
      });

      this.value.SubmittedData = await createSubmittedFormField(body);
      this.submittedId = this.value.SubmittedData.ID;
    }

    this.lastData = data;
  }

  async getSubmittedFieldId(): Promise<number> {
    if (this.submittedId === null) {
      // force creation of the submitted field
      await this.submit(Array.isArray(this.data) ? this.data.join(',') : this.data);
    }

    return this.submittedId!;
  }

  transformSubmitBody(body: SubmittedFormFieldData): SubmittedFormFieldData {
    return body;
  }
}
