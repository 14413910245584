import Vue from 'vue';
// todo: if data is renamed in FieldBase
// import 'vue-class-component/hooks';
import vuetify from './plugins/vuetify';
import router from './plugins/router';
import './plugins/clipboard';
import i18n from './i18n';
import store from './store';
import './plugins/dateformatter';
import './plugins/chartjs';
import './plugins/globals';
import './plugins/classcomponents';
import './components/global';
import './plugins/civicuk';
import './plugins/gtm';
//import './plugins/facebook_pixel';
import App from './App.vue';

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
