import { VNode, CreateElement } from 'vue';
import { Component } from 'vue-property-decorator';
import { fieldTypes } from '@/model/brief';
import { FieldUpdatedEvent } from '@/model/brief2';
import { FieldBasePropsWithVisibility } from './FieldBase';
import TextField from './TextField.vue';
import SingleDatePicker from './SingleDatePicker.vue';
import DropdownField from './DropdownField.vue';
import RadioField from './RadioField.vue';
import SwitchSet from './SwitchSet.vue';
import ParticipantsField from './ParticipantsField.vue';
import SingleCheckbox from './SingleCheckbox.vue';
import HtmlTextField from './HtmlTextField.vue';
import HeadingField from './HeadingField.vue';
import TextareaField from './TextareaField.vue';
import FileUploadField from './FileUploadField.vue';
import MilestonesField from './MilestonesField.vue';
import YesNoField from './YesNoField.vue';
import BudgetField from './BudgetField.vue';
import TileGroupField from './TileGroupField.vue';
import ClientField from './ClientField.vue';
import SpiderChartField from './SpiderChartField.vue';
import MatrixChartField from './MatrixChartField.vue';
import UnknownField from './UnknownField';

@Component
export default class FormFragment extends FieldBasePropsWithVisibility {
  /*
   * computed
   */
  get currentVisibility() {
    return this.visibilities[this.value.ID].current;
  }

  /*
   * methods
   */
  onUpdated(field: FieldUpdatedEvent) {
    this.$emit('updated', field);
  }

  render(h: CreateElement): VNode | null {
    // ignore *CustomOption typed fields
    // user options will be implemented differently and these fields are not needed anymore
    // they will be removed in the future when the prototype application won't be needed anymore
    if (this.value.FieldType.endsWith('CustomOption')) {
      return null;
    }

    if (process.env.VUE_APP_TARGET_ENV === 'test' && !this.currentVisibility) {
      return h('h2', { attrs: { class: 'red--text' } }, `Field not visible: #${this.value.ID} - ${this.value.Title}`);
    }

    const props = {
      value: this.value,
      briefingType: this.briefingType,
      submittedFormId: this.submittedFormId,
      briefingUuid: this.briefingUuid,
    };

    const on = {
      updated: this.onUpdated
    };

    const style = {
      display: this.currentVisibility ? 'block' : 'none'
    };

    const attrs = {
      id: `field-${this.value.ID}`,
    }

    switch (this.value.FieldType) {
      case fieldTypes.BriefingEmailField:
        return h(TextField, { props, on, style, attrs });
      case fieldTypes.BriefingTextField:
        if (this.value.Rows && this.value.Rows > 1) {
          return h(TextareaField, { props, on, style, attrs });
        } else {
          return h(TextField, { props, on, style, attrs });
        }
      case fieldTypes.BriefingDateField:
        return h(SingleDatePicker, { props, on, style, attrs });
      case fieldTypes.BriefingDropdown:
        return h(DropdownField, { props, on, style, attrs });
      case fieldTypes.BriefingRadioField:
        return h(RadioField, { props, on, style, attrs });
      case fieldTypes.BriefingSidebysideSelect:
        return h(SwitchSet, { props, on, style, attrs });
      case fieldTypes.BriefingPeopleDropdown:
        return h(ParticipantsField, { props, on, style, attrs });
      case fieldTypes.BriefingCheckbox:
        return h(SingleCheckbox, { props, on, style, attrs });
      case fieldTypes.BriefingYesNoRadioField:
        return h(YesNoField, { props, on, style, attrs });
      case fieldTypes.BriefingLiteralField:
        return h(HtmlTextField, { props, style, attrs });
      case fieldTypes.BriefingFormHeading:
        return h(HeadingField, { props, style, attrs });
      case fieldTypes.BriefingFileField:
        return h(FileUploadField, { props, style, attrs });
      case fieldTypes.BriefingMilestoneField:
        return h(MilestonesField, { props, style, attrs });
      case fieldTypes.BriefingBudgetField:
        return h(BudgetField, { props, on, style, attrs });
      case fieldTypes.BriefingClientField:
        return h(ClientField, { props, style, attrs });
      case fieldTypes.BriefingTilesField:
        return h(TileGroupField, { props, on, style, attrs });
      case fieldTypes.BriefingSpiderChartField:
        return h(SpiderChartField, { props, style, attrs });
      case fieldTypes.BriefingMatrixChartField:
        return h(MatrixChartField, { props, style, attrs });
      default: 
        return h(UnknownField, { props, style });
    }
  }
}
