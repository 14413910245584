export interface CopyableObject {
  [key: string]: any
}

export const copyFields = (source: CopyableObject, target: CopyableObject) => {
  Object.keys(target).forEach((key) => target[key] = source[key]);
  return target;
};

export const clone = (source: CopyableObject) => {
  const res: CopyableObject = {};
  Object.keys(source).forEach((key) => res[key] = source[key]);
  return res;
};

export const isBlank = (val: any) => val === undefined || val === null || val.length === 0;

const URL_PATTERN = /(www\.|http:|https:)+[^\s"]+[\w]/gi;

export const stylizeUserText = (value: string | number | null | undefined, briefingType: string): string | null => {
  if (!value) {
    return null;
  }

  if (typeof value === 'number') {
    return `${value}`;
  }

  return value
    // try to prevent XSS, escape HTML tags
    .replace('<', '&lt;')
    .replace('>', '&gt;')
    // replace URL's in user input texts with real links
    .replace(URL_PATTERN, (match, prefix) => {
      let url = match;
      if (prefix === 'www.') {
        url = 'https://' + match;
      }

      return `<a class="user-input-link ${briefingType}" href="${url}" target="_blank">${match}</a>`
    });
};

/**
 * Returns an array with arrays of the given size.
 */
export const splitIntoChunks = <T>(array: Array<T>, size: number): Array<Array<T>> => {
  const res = [];
  
  for (let index = 0; index < array.length; index += size) {
      res.push(array.slice(index, index+size));
  }

  return res;
};

export const wordStartPatternOf = (value: string | null | undefined) => {
  const rawPattern = value?.trim();
  
  if (rawPattern && rawPattern.length > 0) {
    const f = rawPattern.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    console.log(f);
    return new RegExp('\\b' + f, 'i');
  }
}
