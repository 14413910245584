import { StoreState } from './interfaces';
import { NullableBriefingStoreState } from './interfaces/BriefingStore';
import { BRIEFING_MUTATION_KEYS, InitializePayload } from './interfaces/BriefingMutations';
import { Module, Mutation, Action } from 'vuex';
import { BriefingParticipantType } from '@/model/participant';
import { BRIEFING_ACTION_KEYS, AddRolePayload, EditRolePayload } from './interfaces/BriefingActions';
import { editParticipantRole, addParticipantToBriefing, removeParticipantFromBriefing, createSubmittedFormField } from '@/api';
import { SubmittedData } from '@/model/brief2';

const state: NullableBriefingStoreState = {
  uuid: null,
  sbfId: null,
  briefingType: null,
  participants: null,
  participantsField: null,
  participantsSbf: null,
  createParticipantDialogShown: false,
};

type BriefingStoreMutationTree = {
  [K in keyof typeof BRIEFING_MUTATION_KEYS]: Mutation<NullableBriefingStoreState>;
};

const mutations: BriefingStoreMutationTree = {
  initialize: (state: NullableBriefingStoreState, payload: InitializePayload) => {
    state.uuid = payload.uuid;
    state.briefingType = payload.briefingType;
    state.participantsField = payload.participantsField;
    state.participants = payload.participants;

    if (payload.sbfId !== undefined) {
      state.sbfId = payload.sbfId;
    }

    if (payload.participantsSbf !== undefined) {
      state.participantsSbf = payload.participantsSbf;
    }
  },
  clear: (state: NullableBriefingStoreState) => {
    state.uuid = null;
    state.briefingType = null;
    state.participantsField = null;
    state.participantsSbf = null;
    state.participants = null;
  },
  addRole: (state: NullableBriefingStoreState, payload: BriefingParticipantType) => {
    state.participants = [ ...state.participants!, payload ];
  },
  replaceRole: (state: NullableBriefingStoreState, payload: BriefingParticipantType) => {
    state.participants = state.participants!.map(a => a.ID === payload.ID ? payload : a);
  },
  removeRole: (state: NullableBriefingStoreState, roleId: number) => {
    state.participants = state.participants!.filter(i => i.ID != roleId);
  },
  showCreateParticipantDialog: (state: NullableBriefingStoreState) => {
    state.createParticipantDialogShown = true;
  },
  closeCreateParticipantDialog: (state: NullableBriefingStoreState) => {
    state.createParticipantDialogShown = false;
  },
  setParticipantsSbf: (state: NullableBriefingStoreState, payload: SubmittedData) => {
    state.participantsSbf = payload;
  }
};

type BriefingStoreActionTree = {
  [K in keyof typeof BRIEFING_ACTION_KEYS]: Action<NullableBriefingStoreState, StoreState>;
};

const actions: BriefingStoreActionTree = {
  addRole: async ({ commit, state, dispatch }, { participantId, data }: AddRolePayload) => {
    // maybe sbfield does not exist?
    await dispatch(BRIEFING_ACTION_KEYS.initParticipantsSbf);
    const res = await addParticipantToBriefing(state.uuid!, state.participantsSbf!.ID, participantId, data);
    commit(BRIEFING_MUTATION_KEYS.addRole, res);
  },
  editRole: async ({ commit, state }, { roleId, data }: EditRolePayload) => {
    // sbfield should already exist
    const res = await editParticipantRole(state.uuid!, state.participantsSbf!.ID, roleId, data);
    commit(BRIEFING_MUTATION_KEYS.replaceRole, res);
  },
  removeRole: async ({ commit, state }, roleId: number) => {
    // sbfield should already exist
    await removeParticipantFromBriefing(state.participantsSbf!.ID, roleId);
    commit(BRIEFING_MUTATION_KEYS.removeRole, roleId);
  },
  initParticipantsSbf: async ({ commit, state }) => {
    if (state.participantsSbf) {
      return;
    }

    const { ID: fid, Parent, FieldType, Name, Sort, Title, } = state.participantsField!;

    const res = await createSubmittedFormField({
      ParentID: state.sbfId!,
      ParentElementID: Parent,
      BriefingFieldClassName: FieldType,
      Name,
      Sort,
      Title,
      RelatedFieldID: fid,
      Value: null,
    });

    commit(BRIEFING_MUTATION_KEYS.setParticipantsSbf, res);
  }
};

const BriefingStoreModule: Module<NullableBriefingStoreState, StoreState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default BriefingStoreModule;
