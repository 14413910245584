<template>
  <internal-layout>
    <template #header>
      <multi-tiles :title="$t('profile.tile')" />
    </template>

    <template #mobile-header>
      <multi-tiles :title="$t('profile.tile')" />
    </template>

    <template default>
      <h1>{{ $t('profile.title') }}</h1>
      
      <v-form
        id="profile-form"
        ref="form"
        v-model="valid"
        @submit.prevent="submit"
      >
        <v-container class="pa-0">
          <v-row v-if="isDevUser" no-gutters>
            <v-col cols="12" sm="6">
              <v-select
                :value="currentLanguage"
                :items="['de', 'en']"
                :label="$t('profile.labels.language')"
                @change="onLanguageChange"
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.FirstName"
                :rules="validation.firstname"
                :label="$t('profile.labels.firstname')"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.Surname"
                :rules="validation.surname"
                :label="$t('profile.labels.surname')"
              />
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.Email"
                :rules="validation.email"
                :label="$t('profile.labels.email')"
                type="email"
                required
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.Phone"
                :rules="validation.phone"
                :label="$t('profile.labels.phone')"
              />
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.Company"
                :rules="validation.company"
                :label="$t('profile.labels.company')"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.Position"
                :rules="validation.position"
                :label="$t('profile.labels.position')"
              />
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.Street"
                :rules="validation.street"
                :label="$t('profile.labels.street')"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.Zip"
                :rules="validation.zip"
                :label="$t('profile.labels.zip')"
              />
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.City"
                :rules="validation.city"
                :label="$t('profile.labels.city')"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.Country"
                :rules="validation.country"
                :label="$t('profile.labels.country')"
              />
            </v-col>
          </v-row>
        </v-container>

        <button-row size="xl" justify="end" class="margin-top">
          <button-xl @click.prevent="reset">
            {{ $t('generic.cancel') }}
          </button-xl>
          <v-dialog v-model="passwordChangeDialog" persistent max-width="720px">
            <template #activator="{ on }">
              <button-stack size="xl" v-on="on">
                <template #secondary>
                  {{ $t('password_change.title') }}
                </template>

                <button-xl
                  emphasize
                  type="submit"
                  :disabled="!valid"
                  @click.prevent="submit"
                >
                  {{ $t('generic.save') }}
                </button-xl>
              </button-stack>
            </template>

            <dialog-content :title="$t('password_change.title')">
              <v-form ref="validationForm" v-model="passwordFormValid" @submit.prevent="onChangePassword">
                <v-text-field
                  v-model="passwordForm.CurrentPassword"
                  :rules="passwordValidation.current"
                  :label="$t('password_change.labels.current_password')"
                  type="password"
                  required
                />

                <v-text-field
                  v-model="passwordForm.NewPassword"
                  :rules="passwordValidation.new"
                  :label="$t('password_change.labels.new_password')"
                  :error-messages="newPasswordCustomErrors"
                  type="password"
                  required
                />

                <v-text-field
                  v-model="passwordForm.NewPasswordAgain"
                  :rules="passwordValidation.new"
                  :label="$t('password_change.labels.new_password')"
                  :error-messages="newPasswordCustomErrors"
                  type="password"
                  required
                />
              </v-form>

              <template #actions>
                <button-xl @click.prevent="onCancelPasswordChange">
                  {{ $t('generic.cancel') }}
                </button-xl>
                <button-xl emphasize @click.prevent="onChangePassword">
                  {{ $t('generic.save') }}
                </button-xl>
              </template>
            </dialog-content>
          </v-dialog>
        </button-row>
      </v-form>
    </template>
  </internal-layout>
</template>

<script>
  import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
  import i18n from '@/i18n';
  import { user, changePassword } from '@/api';
  import { copyFields } from '@/util/data';
  import { UserData, PasswordData } from '@/model/userdata';
  import { required } from '@/util/validation';
  import InternalLayout from '@/components/layout/InternalLayout.vue';
  import MultiTiles from '@/components/layout/MultiTiles.vue';

  export default {
    name: 'Profile',
    components: {
      InternalLayout,
      MultiTiles,
    },
    data: () => ({
      valid: false,
      passwordFormValid: false,
      loaded: false,
      passwordChangeDialog: false,
      orig: {
        ...UserData.empty
      },
      form: {
        ...UserData.empty
      },
      validation: {
        ...UserData.validation
      },
      passwordForm: {
        CurrentPassword: null,
        NewPassword: null,
        NewPasswordAgain: null,
      },
      passwordValidation: {
        current: [ required(i18n.t('password_change.labels.current_password')) ],
        new: PasswordData.validation.password,
      },
    }),
    computed: {
      ...mapState(['userId']),
      ...mapGetters(['isDevUser']),
      newPasswordsAreDifferent() {
        return this.passwordForm.NewPassword != null
          && this.passwordForm.NewPasswordAgain != null
          && this.passwordForm.NewPassword != this.passwordForm.NewPasswordAgain
      },
      newAndOldPasswordsAreTheSame() {
        return this.passwordForm.CurrentPassword != null
          && this.passwordForm.NewPassword != null
          && this.passwordForm.CurrentPassword == this.passwordForm.NewPassword
      },
      passwordFormIsInValid() {
        return ! this.passwordFormValid || this.newPasswordsAreDifferent || this.newAndOldPasswordsAreTheSame;
      },
      newPasswordCustomErrors() {
        if (this.newAndOldPasswordsAreTheSame) {
          return [ i18n.t('password_change.errors.new_and_current_are_same') ];
        } else if (this.newPasswordsAreDifferent) {
          return [ i18n.t('password_change.errors.new_passwords_are_different') ];
        } else {
          return [];
        }
      },
      currentLanguage() {
        return i18n.locale;
      },
    },
    async created() {
      try {
        const data = await user(this.userId);

        if (data != undefined) {
          this.loaded = true;
          copyFields(data, this.orig);
          copyFields(data, this.form);
        } else {
          this.saveError('TODO!');
        }
      } catch (e) {
        this.saveError(e);
      }
    },
    methods: {
      ...mapMutations(['saveStart', 'saveError']),
      ...mapActions(['updateUser', 'saveSuccess']),
      async submit() {
        this.updateUser(this.form);
      },
      clearPasswordForm() {
        this.passwordChangeDialog = false;
        this.passwordForm.CurrentPassword = null;
        this.passwordForm.NewPassword = null;
        this.passwordForm.NewPasswordAgain = null;
        this.$refs.validationForm.resetValidation();
      },
      async onChangePassword() {
        try {
          this.saveStart();
          await changePassword(this.userId, this.passwordForm);
          this.saveSuccess();
          this.clearPasswordForm();
        } catch (e) {
          this.saveError(e);
        }
      },
      onCancelPasswordChange() {
        this.clearPasswordForm();
      },
      reset() {
        copyFields(this.orig, this.form);
      },
      onLanguageChange(value) {
        i18n.locale = value;
      }
    }
  }
</script>

<style lang="sass">
#profile-form
  .row
    margin: 0 -23.25pt
    .col-sm-6
      padding: 0 23pt

#profile-buttons
  text-align: right
  padding-top: 63.75pt
</style>
