<template>
  <v-btn
    class="applogo-mobile"
    x-large
    depressed
    tile
    text
    :ripple="false"
    :style="{ transform: `rotate(${open ? '-180deg' : '0deg'})`, opacity: open ? 0 : 1 }"
    @click.stop="$emit('click', $event)"
  >
    <img src="@/assets/logo-small.svg" :alt="$APP" class="d-inline">
  </v-btn>
</template>

<script>
export default {
  name: 'AppLogoMobile',
  props: {
    open: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style lang="sass">
.v-btn.applogo-mobile
  font-size: 24px
  margin: 0
  padding: 0 !important
  min-width: 0 !important
  height: auto !important
  margin-left: $main-content-padding-x

  transition: transform 0.2s linear, opacity 0.2s ease-in

  @media #{map-get($display-breakpoints, 'xs-only')}
    margin-left: $main-content-padding-x-m

  img
    height: $logo-height

    @media #{map-get($display-breakpoints, 'sm-and-down')}
      height: $logo-mobile-height

  &::before
      color: transparent
</style>
