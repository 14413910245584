export type LocationType = {
  Zip: string | null | undefined;
  Street: string | null | undefined;
  Country: string | null | undefined;
};

const LocationBaseData = {
  empty: <LocationType> {
    Zip: null,
    Street: null,
    Country: null,
  },
  validation: {
    country: [],
    zip: [],
    street: [],
  },
}

type LocationDataType = LocationType & {
  City: string | null | undefined;
};

export const LocationData = {
  empty: <LocationDataType> {
    ...LocationBaseData.empty,
    City: null,
  },
  validation: {
    ...LocationBaseData.validation,
    city: [],
  },
}
