<template>
  <header id="briefing-title">
    <h1 class="briefing-title">
      {{ value.Title }}

      <router-link
        v-if="editable && !$isMobile"
        class="d-inline d-sm-none d-print-none float-right"
        :to="{ name: 'briefing_edit', params: { uuid: value.Uuid } }"
        :title="$t('generic.edit')"
      >
        <v-icon size="36px" class="mr-1">
          mdi-pencil-outline
        </v-icon>
      </router-link>
    </h1>
    <p class="d-none d-sm-block d-print-block">
      {{ value.BriefingType.MenuTitle }} / {{ value.ProjectType.Title }} · Version: {{ value.Version }} · Erstellt: {{ $formatDate(value.Created, 'P') }} · Geändert: {{ $formatDate(value.LastEdited, 'P') }} · Ersteller: {{ value.Submitter.Name }}
      

      <v-icon
        v-if="deletable && !$isMobile"
        size="16px"
        :title="$t('generic.delete')"
        class="float-right"
      >
        mdi-delete-outline
      </v-icon>
      <router-link
        v-if="editable && !$isMobile"
        class="float-right d-print-none"
        :to="{ name: 'briefing_edit', params: { uuid: value.Uuid } }"
        :title="$t('generic.edit')"
      >
        <v-icon size="16px" class="mr-1">
          mdi-pencil-outline
        </v-icon>
      </router-link>
    </p>
  </header>
</template>

<script>
import { BriefingData } from '@/model/brief';

export default {
  name: 'BriefingTitle',
  props: {
    value: {
      type: BriefingData,
      required: true
    },
    editable: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style lang="sass">
$subtitle-spacing: 10px

#briefing-title
  margin-bottom: 114px
  text-align: left

  @media print
    margin-bottom: 55px

  h1
    margin-bottom: $subtitle-spacing

  p
    @include table-border-bottom
    line-height: 1
    font-size: 10.5pt
    font-weight: $font-medium
    color: #AAAAAA
    padding-bottom: $subtitle-spacing

    a
      text-decoration: none
</style>
