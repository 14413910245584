




























































































































































































































































import { Component } from 'vue-property-decorator';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import { InputValidationRule } from 'vuetify';
import i18n from '@/i18n';
import { StoreState, StoreGetters, StoreMutations, StoreActions } from '@/store/interfaces';
import { mapBriefingState, BriefingStoreState } from '@/store/interfaces/BriefingStore';
import * as BriefingStoreMutations from '@/store/interfaces/BriefingMutations';
import * as BriefingStoreActions from '@/store/interfaces/BriefingActions';
import { BriefingParticipantType, ParticipantType, BriefingRoleType, ParticipantPermissions } from '@/model/participant';
import { required } from '@/util/validation';
import FieldBase2 from './FieldBase2';
import ParticipantDialog from '@/components/dialogs/ParticipantDialog.vue';
import CardList from '@/components/common/CardList.vue';
import InputLabel from '@/components/common/InputLabel.vue';
import { wordStartPatternOf } from '@/util/data';

type ParticipantDiff = {
  add: true;
  participant: ParticipantType;
  role: string | null;
  permission: number | null;
};
//  | {
//   add: false;
//   participantRole: BriefingParticipantType;
// };

@Component({
  components: {
    CardList,
    ParticipantDialog,
    InputLabel,
  },
  computed: {
    ...mapState(['participants']),
    ...mapBriefingState({
      added: 'participants',
    }),
    ...mapGetters(['companies']),
  },
  methods: {
    ...mapMutations(['saveStart', 'saveError', 'setError']),
    ...mapActions(['saveSuccess']),
    ...BriefingStoreMutations.mapBriefingMutations(['showCreateParticipantDialog']),
    ...BriefingStoreActions.mapBriefingActions(['addRole', 'editRole', 'removeRole']),
  },
})
export default class ParticipantsField extends FieldBase2 {
  $refs!: {
    form: Element & {
      resetValidation: () => void;
    }
  }

  /*
   * vuex
   */
  readonly participants!: StoreState['participants'];
  readonly added!: BriefingStoreState['participants'];
  readonly companies!: StoreGetters.companies;

  readonly saveStart!: StoreMutations.saveStart;
  readonly saveError!: StoreMutations.saveError;
  readonly setError!: StoreMutations.setError;
  readonly saveSuccess!: StoreActions.saveSuccess;

  readonly showCreateParticipantDialog!: BriefingStoreMutations.showCreateParticipantDialog;
  readonly addRole!: BriefingStoreActions.addRole;
  readonly editRole!: BriefingStoreActions.editRole;
  readonly removeRole!: BriefingStoreActions.removeRole;

  /* 
   * data
   */
  permissions = ParticipantPermissions;
  isSaving = false;
  roleDialog: {
    shown: boolean;
    valid: boolean;
    id: number | null;
    form: { Role: string | null, Permission: number };
  } = {
    shown: false,
    valid: false,
    id: null,
    form: {
      Role: null,
      Permission: 0,
    }
  };
  deleteDialog: { shown: boolean, participant: BriefingParticipantType | null } = {
    shown: false,
    participant: null,
  };
  selectDialog: {
    step: 0 | 1 | 2 | 3;
    search: string | null;
    selectionDiff: ParticipantDiff[];
    rolesValid: boolean;
  } = {
    step: 0,
    search: null,
    selectionDiff: [],
    rolesValid: false,
  };
  roleValidation: { [key in keyof BriefingRoleType]: InputValidationRule[] } = {
    Role: [ required(i18n.t('participant.labels.role')) ],
    Permission: [ required(i18n.t('participant.labels.permission')) ],
  };

  /*
   * computed
   */
  get loading() {
    return this.available === null || this.isSaving;
  }

  get available() {
    const pattern = wordStartPatternOf(this.selectDialog.search);

    if (pattern) {
      return this.participants?.filter(pav => pattern.test(pav.Title) && ! this.added.some(padd =>
        padd.Email === pav.Email
        && padd.Title === pav.Title
        && padd.Company === pav.Company
      ));
    } else {
      return this.participants;
    }
  }

  /*
   * methods
   */
  showEditParticipantDialog(p: BriefingParticipantType) {
    this.roleDialog.shown = true;
    this.roleDialog.id = p.ID;
    this.roleDialog.form = {
      Role: p.Role,
      Permission: this.permissions.indexOf(p.Permission),
    };
  }

  showSelectDialog() {
    this.selectDialog.step = 1;
    this.selectDialog.search = null;
    this.selectDialog.selectionDiff = [];
  }

  dismissSelectDialog() {
    this.selectDialog.step = 0;
  }

  onSelect({ add, participant }: { add: boolean, participant: ParticipantType }) {
    if (add) {
      this.selectDialog.selectionDiff.push({
        add: true,
        participant,
        role: null,
        permission: 0,
      });  
    } else {
      this.selectDialog.selectionDiff = this.selectDialog.selectionDiff.filter((d) => d.participant !== participant);
    }
  }

  onSelectDialogNext() {
    this.selectDialog.step += 1;
  }

  async submitSelectDialog() {
    try {
      this.isSaving = true;
      await Promise.all(this.selectDialog.selectionDiff.map((s) => {
        return this.addRole({
          participantId: s.participant.ID,
          data: {
            Role: s.role!,
            Permission: this.permissions[s.permission!],
          }
        });
      }));
      this.dismissSelectDialog();
    } catch (e) {
      this.setError(e);
    }

    this.isSaving = false;
  }

  showCreateDialogFromSelect() {
    this.selectDialog.step = 0;
    this.showCreateParticipantDialog();
  }

  dismissRoleDialog() {
    this.roleDialog.shown = false;

    setTimeout(() => {
      this.roleDialog.id = null;
      this.roleDialog.form = {
        Role: null,
        Permission: 0,
      };
      this.$refs.form.resetValidation();
    }, 200);
  }

  async submitRoleDialog() {
    const { form, id } = this.roleDialog;
    if (id === null || form === null || !this.roleDialog.valid) {
      return;
    }

    try {
      this.isSaving = true;

      await this.editRole({
        roleId: id,
        data: {
          Role: form.Role!,
          Permission: this.permissions[form.Permission],
        }
      });

      this.dismissRoleDialog();
    } catch (e) {
      this.setError(e);
    }

    this.isSaving = false;
  }

  showDeleteDialog(p: BriefingParticipantType) {
    this.deleteDialog.shown = true;
    this.deleteDialog.participant = p;
  }

  dismissDelete() {
    this.deleteDialog.shown = false;
    this.deleteDialog.participant = null;
  }

  async submitDelete() {
    const participant = this.deleteDialog.participant;

    if (!participant) {
      return;
    }

    try {
      this.isSaving = true;
      await this.removeRole(participant.ID);
      this.dismissDelete();
    } catch (e) {
      this.setError(e);
    }

    this.isSaving = false;
  }
}
