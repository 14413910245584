











import Component from 'vue-class-component';
import ElementBase from './ElementBase';
import { fieldTitle, yesnoField, field } from '@/pdf/components';

@Component
export default class CanvasYesno extends ElementBase {
  /*
   * computed
   */
  get isRenderable() {
    return this.content !== null;
  }

  /*
   * methods
   */
  public renderPdfContent() {
    if (!this.isRenderable) {
      return undefined;
    }

    return field([
      fieldTitle(this.value),
      yesnoField(this.content as 'ja' | 'nein'),
    ]);
  }
}
