<template>
  <v-form ref="form" v-model="valid" @submit.prevent="$emit('submit')">
    <v-container class="pa-0">
      <v-row v-if="edit" no-gutters>
        <v-col cols="12">
          <v-combobox
            v-model="form.Title"
            :rules="validation.Title"
            :label="$t('create_briefing.milestones.entry_name')"
            :color="briefingType.Color"
            :items="titles"
            :loading="isSaving"
            :disabled="isSaving"
            required
            @change="onInput"
          />
        </v-col>
      </v-row>

      <v-row class="datepickers briefing-field">
        <v-col cols="4">
          <date-picker
            v-if="!form.CheckboxValue"
            v-model="form.From"
            :rules="validation.From"
            :label="$t('create_briefing.milestones.from') + ':'"
            :briefing-type="briefingType"
            :is-saving="isSaving"
            :max="item.To"
            :start-with="item.To"
            @input="onInput"
          />
        </v-col>
        <v-col cols="4">
          <date-picker
            v-model="form.To"
            :rules="validation.To"
            :label="$t('create_briefing.milestones.to') + ':'"
            :briefing-type="briefingType"
            :is-saving="isSaving"
            :min="item.CheckboxValue ? null : item.From"
            :start-with="item.CheckboxValue ? null : item.From"
            @input="onInput"
          />
        </v-col>
        <v-col cols="4">
          <briefing-switch
            v-model="form.CheckboxValue"
            class="milestone-switch"
            :label="$t('create_briefing.milestones.is_milestone') + ':'"
            :color="briefingType.Color"
            :loading="isSaving"
            :disabled="isSaving"
            hide-details
            @change="onInput"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import i18n from '@/i18n';
import { required } from '@/util/validation';
import { copyFields } from '@/util/data';
import BriefingSwitch from '@/components/common/LabelFirstSwitch';
import DatePicker from '@/components/common/DatePicker.vue';

export const emptyForm = () => ({
  Title: null,
  From: null,
  To: null,
  CheckboxValue: false,
});

export const milestoneFormFrom = (source) => ({
  Title: source.Title,
  From: source.From,
  To: source.To,
  CheckboxValue: String(source.CheckboxValue) === "1"
});

export default {
  name: 'MilestoneEditForm',
  components: {
    BriefingSwitch,
    DatePicker
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    briefingType: {
      type: Object,
      required: true,
    },
    titles: {
      type: Array,
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    valid: false,
    form: emptyForm(),
    validation: {
      Title: [ required(i18n.t('create_briefing.milestones.entry_name')) ],
      From: [ required(i18n.t('create_briefing.milestones.from')) ],
      To: [ required(i18n.t('create_briefing.milestones.to')) ],
    },
  }),
  watch: {
    value(newValue) {
      copyFields(newValue, this.form);
    },
    valid(newValue) {
      this.$emit('update:valid', newValue);
    }
  },
  created() {
    copyFields(this.value, this.form);
  },
  methods: {
    copyForm() {
      const newVal = { ...this.form };

      if (!this.edit) {
        delete newVal.Title;
      }

      return newVal;
    },
    onInput() {
      this.$emit('input', this.copyForm());
    },
    reset() {
      this.form.Title = null;
      this.form.From = null;
      this.form.To = null;
      this.form.CheckboxValue = false;

      this.$refs.form.resetValidation();

      this.$emit('input', this.copyForm());
    }
  }
}
</script>

<style lang="sass">
.milestone-switch.v-input--switch
  margin-top: 0px
  @include table-border-bottom
  height: 57px

  .v-input__slot
    flex-direction: column
    align-items: flex-end
  
  .v-label
    font-size: $field-info-font-size
    color: $gray-color
    margin-top: -8px
    margin-bottom: 8px
</style>