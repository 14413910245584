<template>
  <div v-if="client" class="canvas-client">
    <div class="d-flex">
      <div class="client-logo" :class="client.Logo ? null : 'no-image'">
        <img
          v-if="client.Logo"
          :src="client.Logo"
        >
        <div v-else>
          <v-icon color="black" size="56pt">
            mdi-image-off-outline
          </v-icon><br>
          {{ $t('canvas.client.no_logo') }}<br>
        </div>
      </div>
      <div class="client-data">
        <h3>{{ client.CompanyName }}</h3>

        <p>
          {{ client.Street }}<br>
          {{ client.Zip }} {{ client.Location }}<br>
          {{ client.Country }}
        </p>

        <p v-if="client.CompanyURL">
          <a
            :href="client.CompanyURL"
            class="user-input-link"
            :class="briefingType.BriefingType"
            title="Website"
          >
            {{ client.CompanyURL }}
          </a>
        </p>

        <p>{{ client.Email }}</p>
      </div>
    </div>
    <p v-html="htmlContent" />
  </div>
</template>

<script>
import { clientField } from '@/pdf/components';
import { stylizeUserText } from '@/util/data';
import ElementBase from './ElementBase';

export default {
  name: 'Clients',
  mixins: [ ElementBase ],
  computed: {
    client() {
      const clients = this.value.SubmittedData?.Clients;
      return clients ? clients[0] : undefined;
    },
    htmlContent() {
      return stylizeUserText(this.client?.Description, this.briefingType.BriefingType);
    }
  },
  methods: {
    renderPdfContent() {
      if (!this.client) {
        return undefined;
      }

      return clientField(this.client);
    }
  }
}
</script>

<style lang="sass">
.canvas-client
  .client-logo
    width: 232px
    height: 232px

    > img
      width: 100%
      height: 100%
      object-fit: contain
      object-position: 50% 0
    
    > div
      text-align: center
      font-weight: $font-bold
      text-transform: uppercase

  .client-data
    margin-left: 40px

    h3, p
      font-size: 16pt
      line-height: 22.6pt

  h3, p
      font-size: 16pt
      line-height: 22.6pt
</style>
