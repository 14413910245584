<template>
  <v-btn
    class="applogo"
    x-large
    depressed
    tile
    text
    :ripple="false"
    @click.stop="$emit('click', $event)"
  >
    <v-icon class="d-none d-sm-inline">
      {{ open ? 'mdi-close' : 'mdi-menu' }}
    </v-icon>
    <img src="@/assets/logo.svg" :alt="$APP" class="d-inline">
  </v-btn>
</template>

<script>
export default {
  name: 'AppLogo',
  props: {
    open: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style lang="sass">
.v-btn.applogo
  font-size: 24px
  margin: 0
  padding: 0 !important
  height: auto !important

  .v-icon
    margin: 0px
    width: $left-menu-padding-left
    @media #{map-get($display-breakpoints, 'sm-only')}
      width: $left-menu-padding-left-tablet
    @media #{map-get($display-breakpoints, 'xs-only')}
      width: $main-content-padding-x

  img
    height: $logo-height

    @media #{map-get($display-breakpoints, 'sm-and-down')}
      height: $logo-mobile-height
    
    @media #{map-get($display-breakpoints, 'xs-only')}
      margin-left: $main-content-padding-x-m

  &::before
      color: transparent
</style>
