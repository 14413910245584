


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { TileOptionType } from '@/model/brief2';

@Component
export default class TileItem extends Vue {
  @Prop({ type: Object, required: true })
  readonly value!: TileOptionType;

  @Prop({ type: Boolean, default: false })
  readonly active!: boolean;

  @Prop({ type: String, default: null })
  readonly color!: string;
  
  /*
   * data
   */
  inactivated = false;

  /*
   * computed
   */
  get title(): string {
    // TODO
    return this.value.text || (this.value as any).Title;
  }

  /*
   * methods
   */
  
  onClick(): void {
    this.$emit('click', this.value);

    // if (this.active) {
    //   this.inactivated = true;

    //   setTimeout(() => {
    //     this.inactivated = false;
    //   }, 200)
    // }
  }
}
