import { Component } from 'vue-property-decorator';
import { mapMutations } from 'vuex';
import { FieldUpdatedEvent, SubmittedData } from '@/model/brief2';
import { StoreMutations } from '@/store/interfaces';
import { FieldBaseProps } from './FieldBase';
import { createSubmittedFormField } from '@/api';

@Component({
  methods: mapMutations(['setError'])
})
export default class FieldBase extends FieldBaseProps {
  /*
   * vuex 
   */
  setError!: StoreMutations.setError;

  /*
   * data
   */
  isSaving = false;

  /*
   * computed
   */
  get color() {
    return this.briefingType.Color;
  }

  /*
   * methods
   */
  onUpdated(field: FieldUpdatedEvent) {
    this.$emit('updated', field);
  }

  async submit(): Promise<SubmittedData> {
    const { ID: fid, Parent, FieldType, Name, Sort, Title, } = this.value;

    return await createSubmittedFormField({
      ParentID: this.submittedFormId,
      ParentElementID: Parent,
      BriefingFieldClassName: FieldType,
      Name,
      Sort,
      Title,
      RelatedFieldID: fid,
      Value: null,
    });
  }

  async getSubmittedFieldId(): Promise<number> {
    if (!this.value.SubmittedData) {
      // force creation of the submitted field
      this.value.SubmittedData = await this.submit();
    }

    return this.value.SubmittedData.ID;
  }
}
