















import { Component, Vue, Prop } from 'vue-property-decorator';
import { mapActions, mapMutations } from 'vuex';
import { ParticipantType } from '../../model/participant';
import { StoreMutations, StoreActions } from '../../store/interfaces';

@Component({
  methods: {
    ...mapActions(['deleteParticipant', 'saveSuccess']),
    ...mapMutations(['saveStart', 'saveError']),
  }
})
export default class ParticipantDeleteDialog extends Vue {
  /*
   * vuex
   */
  saveStart!: StoreMutations.saveStart;
  saveError!: StoreMutations.saveError;
  saveSuccess!: StoreActions.saveSuccess;
  deleteParticipant!: StoreActions.deleteParticipant;

  @Prop(Object) value!: ParticipantType | null;
  
  /*
   * computed
   */
  get title() {
    return this.value?.Title;
  }

  /* 
   * methods
   */
  dismiss() {
    this.$emit('dismiss');
  }

  async submit() {
    if (!this.value) {
      return;
    }

    try {
      this.saveStart();

      await this.deleteParticipant(this.value.ID);

      this.saveSuccess();
      this.$emit('success', this.value.ID);
    } catch (e) {
      this.saveError(e);
    }
  }
}
