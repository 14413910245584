

















































































































































import Vue from 'vue';
import { Route, RawLocation, Location } from 'vue-router';
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex';
import Component from 'vue-class-component';
import * as TEMP from 'vue-disqus';
import { shareBriefing } from '@/api';
import { queryPublicBriefing, queryPrivateBriefing, CanvasData } from '@/model/brief';
import { createCanvasPdf } from '@/pdf';
import TableOfContentsLayout from '@/components/layout/TableOfContentsLayout.vue';
import BriefingTitle from '@/components/BriefingTitle.vue';
import FloatingActionBtn from '@/components/FloatingActionBtn.vue';
import CanvasTile from '@/components/decoration/CanvasTile.vue';
import CanvasContent from '@/components/canvas/CanvasContent.vue';
import AutoScrollList from '@/components/layout/AutoScrollList.vue';
import FloatingMenuItem from '@/components/global/FloatingMenuItem.vue';
import { coverPage, PdfDisplayable } from '@/pdf/components';
import { StoreState, StoreGetters, StoreActions, StoreMutations } from '@/store/interfaces';
import { Content } from 'pdfmake/interfaces';
import { editFieldRoute } from '@/routes';
import { BriefingStoreState, mapBriefingState } from '@/store/interfaces/BriefingStore';

const { Disqus } = TEMP as any;

const props = Vue.extend({
  props: {
    uuid: {
      type: String,
      required: true
    },
  },
});

@Component({
  components: {
    Disqus,
    TableOfContentsLayout,
    BriefingTitle,
    FloatingActionBtn,
    CanvasTile,
    CanvasContent,
    AutoScrollList,
    FloatingMenuItem
  },
  computed: {
    ...mapState(['userId']),
    ...mapBriefingState(['participants', 'participantsField']),
    ...mapGetters(['isAuthenticated'])
  },
  methods: {
    ...mapActions([ 'saveSuccess' ]),
    ...mapMutations([ 'loadStart', 'loadSuccess', 'loadError', 'saveStart', 'saveError', 'setError' ]),
  }
})
export default class BriefingCanvas extends props {
  /*
   * vuex 
   */
  readonly userId!: StoreState['userId'];
  readonly isAuthenticated!: StoreGetters.isAuthenticated;
  readonly saveSuccess!: StoreActions.saveSuccess;
  readonly loadStart!: StoreMutations.loadStart;
  readonly loadSuccess!: StoreMutations.loadSuccess;
  readonly loadError!: StoreMutations.loadError;
  readonly saveStart!: StoreMutations.saveStart;
  readonly saveError!: StoreMutations.saveError;
  readonly setError!: StoreMutations.setError;
  readonly participants!: BriefingStoreState['participants'];
  readonly participantsField!: BriefingStoreState['participantsField'];

  /*
   * refs
   */
  $refs!: {
    content: Element & PdfDisplayable
  };

  /*
   * data
   */
  tree: CanvasData | null = null; // tree is initialized in the created hook!
  shareDialog = {
    shown: false,
    participants: null as any[] | null,
  };
  editable = false;
  scrolledTo: number | null = null;

  /*
   * computed
   */
  get color() {
    return this.tree?.BriefingType.Color;
  }

  get areParticipantsAdded() {
    return this.shareDialog.participants && this.shareDialog.participants.length > 0;
  }

  get participantsFieldLink(): Location {
    return editFieldRoute(this.uuid, this.participantsField.ID);
  }

  /*
   * hooks
   */
  async created() {
    this.loadBriefing(this.uuid);
  }

  async beforeRouteUpdate(
    to: Route,
    from: Route,
    next: (to?: RawLocation | false | ((vm: Vue) => void)) => void
  ) {
    // navigate to other canvas with just reloading the content
    await this.loadBriefing(to.params.uuid);
    next();
  }

  beforeDestroy() {
    Vue.$gtm.tag({
      briefingType: undefined,
      projectType: undefined,
    });
  }

  /*
   * methods
   */
  async loadBriefing(uuid: string) {
    try {
      this.loadStart();

      if (this.isAuthenticated && this.userId) {
        try {
          this.tree = await queryPrivateBriefing(uuid);
          this.editable = true;
        } catch (e) {
          // probably unauth error - it's other user's briefing
          this.tree = await queryPublicBriefing(uuid);
        }
      } else {
        this.tree = await queryPublicBriefing(uuid);
      }

      if (this.tree.Steps.length > 0) {
        this.scrolledTo = this.tree.Steps[0].ID;
      }

      Vue.$gtm.tag({
        briefingType: this.tree.BriefingType.Title,
        projectType: this.tree.ProjectType.Title,
      });

      this.loadSuccess();
    } catch (e) {
      this.loadError(e);
    }
  }

  onScrolledTo(id: number) {
    this.scrolledTo = id;
  }

  async showShareDialog() {
    try {
      this.loadStart();
      this.shareDialog.participants = this.participants;
      this.shareDialog.shown = true;
      this.loadSuccess();
    } catch (e) {
      this.loadError(e);
    }
  }

  dismissShareDialog() {
    this.shareDialog.shown = false;
    this.shareDialog.participants = null;
  }

  async submitShareDialog() {
    try {
      this.saveStart();
      await shareBriefing(this.tree!.Uuid);
      this.saveSuccess();
      this.dismissShareDialog();
      this.$router.push({ name: 'share', params: { uuid: this.tree!.Uuid } });
    } catch (e) {
      this.saveError(e);
    }
  }

  async renderPdfContent() {
    const steps = await this.$refs.content.renderPdfContent()

    return [
      coverPage(this.tree!.Title, this.$formatDate(this.tree!.Created, 'P')),
      {
        toc: {
          // id: 'mainToc'  // optional
          title: {
            text: (this.$t('canvas.pdf.table_of_contents') as string).toUpperCase(),
            style: 'toc'
          }
        },
      },
      ...steps as Content[],
    ];
  }

  async downloadAsPdf() {
    try {
      const content = await this.renderPdfContent();
      const pdf = await createCanvasPdf(this.tree!, content);
      pdf.download();
    } catch (e) {
      this.setError(e);
    }
  }
}
