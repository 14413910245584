














import Component from 'vue-class-component';
import { sortFormData } from '@/model/brief';
import { FileOptions } from '@/model/brief2';
import { field, PdfDisplayable, columns } from '@/pdf/components';
import ElementBase from './ElementBase';
import FilesTable from '@/components/common/FilesTable.vue';
import { STYLES } from '@/pdf/constants';

const mapFile = (f: any): FileOptions => {
  const parts = f.URL.split('.');
  return {
    ...f,
    Extension: parts[parts.length - 1],
  };
};

@Component({
  components: {
    FilesTable
  }
})
export default class CanvasFiles extends ElementBase implements PdfDisplayable {
  /*
   * refs
   */
  $refs!: {
    table: Element & PdfDisplayable;
  };

  /*
   * data
   */
  files: FileOptions[] = [];
  
  /*
   * computed
   */
  get uuid() {
    return this.value.SubmittedData?.Uuid;
  }

  get downloadAllUrl() {
    return `/rest/downloadassets/${this.uuid}`;
  }

  /*
   * hooks
   */
  created() {
    if (this.value.SubmittedData?.FileOptions) {
      this.files = this.value.SubmittedData.FileOptions
        .sort(sortFormData)
        .map(mapFile);
    } else {
      this.files = [];
    }
  }

  /*
   * methods
   */
  async renderPdfContent() {
    if (this.files.length === 0) {
      return undefined;
    }

    return field([
      (await this.$refs.table.renderPdfContent())!,
      columns({
        columns: [
          {
            text: '',
            width: '*'
          },
          {
            width: 'auto',
            columns: [
              {
                text: '\uF069',
                link: window.location.origin + this.downloadAllUrl,
                style: STYLES.ICON,
                width: 'auto',
                margin: [3, 0]
              },
              {
                text: this.$t('create_briefing.upload.download_all') as string,
                link: window.location.origin + this.downloadAllUrl,
                width: 'auto'
              }
            ]
          }
        ]
      })
    ]);
  }
}
