import { RouteConfig, Route, RawLocation } from 'vue-router';
import NotFound404 from '@/pages/NotFound404.vue';
import ServerError from '@/pages/ServerError.vue';
import Projects from '@/pages/Projects.vue';
import EditBriefing from '@/pages/EditBriefing.vue';
import BriefingCanvas from '@/pages/BriefingCanvas.vue';
import BriefingSetup from '@/pages/BriefingSetup.vue';
import Login from '@/pages/Login.vue';
import Profile from '@/pages/Profile.vue';
import Registration from '@/pages/Registration.vue';
import PasswordReset from '@/pages/PasswordReset.vue';
import RegistrationActivated from '@/pages/RegistrationActivated.vue';
import TrashBin from '@/pages/TrashBin.vue';
import InvitePage from '@/pages/InvitePage.vue';
import SharePage from '@/pages/SharePage.vue';
import Toolbox from '@/pages/Toolbox.vue';
import ParticipantsPage from '@/pages/ParticipantsPage.vue';
import ClientsPage from '@/pages/ClientsPage.vue';
import SharedBriefingsScreen from '@/pages/SharedBriefingsScreen.vue';
import HomeScreen from '@/pages/HomeScreen.vue';

export const COLLECTIONS = 'collections';
export const HOME = COLLECTIONS;
export const SHARED = 'shared_briefings';
export const LOGIN = 'login';
export const REGISTRATION = 'registration';
export const REGISTRATION_ACTIVATED = 'registration_activated';
export const RESET_PASSWORD = 'reset_password';
export const ERROR_NOT_FOUND = 'error_not_found';
export const SERVER_ERROR = 'server_error';
export const NEW_BRIEFING_SETUP = 'new_briefing_setup';
export const BRIEFING_EDIT = 'briefing_edit';
export const BRIEF_CANVAS = 'brief_canvas';
export const BRIEF_RAW = 'brief_raw';
export const BRIEF_TEMPLATE = 'brief_template';
export const INVITE = 'invite';
export const SHARE = 'share';
export const PROFILE = 'profile';
export const TOOLBOX = 'toolbox';
export const PARTICIPANTS = 'participants';

export const redirectToLogin = (to: Route): RawLocation => ({
  name: LOGIN,
  // redirect to target path after login - but we shouldn't redirect to the default home, or error page!
  query: to.fullPath === serverErrorRoute.path || to.fullPath === collectionsRoute.path ? undefined : { redirect: to.fullPath }
});

export const loginRoute: RouteConfig = {
  name: LOGIN,
  path: '/login',
  component: Login,
  props: (route) => ({ redirect: route.query.redirect }),
  meta: {
    noMenu: true,
    mobile: true,
    pageTitle: 'login.meta.title',
    pageDescription: 'login.meta.description'
  }
};

const registration: RouteConfig = {
  name: REGISTRATION,
  path: '/registration',
  component: Registration,
  meta: {
    noMenu: true,
    mobile: true,
    pageTitle: 'registration.meta.title',
    pageDescription: 'registration.meta.description'
  }
};

export const activatedRoute: RouteConfig = {
  name: REGISTRATION_ACTIVATED,
  path: '/registration/activated',
  component: RegistrationActivated,
  meta: {
    requiresAuth: true,
    noMenu: true,
    mobile: true,
  }
};

const resetPassword: RouteConfig = {
  name: RESET_PASSWORD,
  path: '/reset_password',
  component: PasswordReset,
  meta: {
    noMenu: true,
    mobile: true,
  }
};

export const collectionsRoute: RouteConfig = {
  name: COLLECTIONS,
  path: 'projects',
  component: Projects,
  meta: {
    mobile: true,
    requiresAuth: true,
  }
};

export const homeChildren: RouteConfig[] = [
  collectionsRoute,
  {
    name: SHARED,
    path: 'shared',
    component: SharedBriefingsScreen,
    meta: {
      mobile: true,
      requiresAuth: true,
    }
  },
]


const homeRoute: RouteConfig = {
  path: '/',
  component: HomeScreen,
  meta: {
    requiresAuth: true,
    menuText: 'routes.projects',
    menuPosition: 2,
    mobile: true,
    defaultChild: COLLECTIONS,
    pageTitle: 'projects.meta.title',
    pageDescription: 'projects.meta.description'
  },
  children: homeChildren,
};

const profile: RouteConfig = {
  name: PROFILE,
  path: '/profile',
  component: Profile,
  meta: {
    requiresAuth: true,
    menuText: 'routes.profile',
    menuPosition: 3,
  }
};

export const toolboxChildren: RouteConfig[] = [
  {
    name: PARTICIPANTS,
    path: 'participants',
    component: ParticipantsPage,
  },
  {
    name: 'clients',
    path: 'clients',
    component: ClientsPage,
  },
]

const toolbox: RouteConfig = {
  name: TOOLBOX,
  path: '/toolbox',
  component: Toolbox,
  meta: {
    requiresAuth: true,
    menuText: 'routes.toolbox',
    menuPosition: 5,
    defaultChild: PARTICIPANTS,
  },
  children: toolboxChildren,
};

const trashBin: RouteConfig = {
  name: 'trashbin',
  path: '/deleted',
  component: TrashBin,
  meta: {
    requiresAuth: true,
    menuText: 'routes.trashbin',
    menuPosition: 5,
  }
};

const briefingSetup: RouteConfig = {
  name: NEW_BRIEFING_SETUP,
  path: '/briefings/new',
  component: BriefingSetup,
  props: (route) => ({
    // sometimes it's string, sometimes it's a numbes :/
    collectionId: (typeof route.query.collection === 'string')
      ? parseInt(route.query.collection)
      : route.query.collection
  }),
  meta: {
    requiresAuth: true,
    menuText: 'routes.new_briefing',
    menuPosition: 1,
  }
}

type EditBriefinProps = Readonly<{
  uuid: string;
  step?: number;
  field?: number;
}>;

const HASH_PTTRN = /#(?:([0-9]+)|field-([0-9]+))/;
const parseBriefEditFormPartFromHash = (route: Route) => {
  const match = HASH_PTTRN.exec(route.hash);

  if (match) {
    if (match[2]) {
      return {
        field: parseInt(match[2]),
      };
    } else {
      return {
        step: parseInt(match[1])
      };
    }
  }

  return {};
};

export const editFieldRoute = (uuid: string, fieldId?: number) => ({
  name: BRIEFING_EDIT,
  params: {
    uuid: uuid,
  },
  hash: fieldId ? `#field-${fieldId}` : undefined
});

const briefingEdit: RouteConfig = {
  name: BRIEFING_EDIT,
  path: '/briefings/:uuid/edit',
  component: EditBriefing,
  props: (route): EditBriefinProps => ({ uuid: route.params.uuid, ...parseBriefEditFormPartFromHash(route) }),
  meta: {
    requiresAuth: true,
  }
};

const share: RouteConfig = {
  name: SHARE,
  path: '/briefings/:uuid/share',
  component: SharePage,
  props: (route) => ({ uuid: route.params.uuid }),
  meta: {
    requiresAuth: true,
  }
};

const briefingCanvas: RouteConfig = {
  name: BRIEF_CANVAS,
  path: '/briefings/:uuid',
  component: BriefingCanvas,
  props: (route) => ({ uuid: route.params.uuid }),
  meta: {
    mobile: true,
  }
};

const invite: RouteConfig = {
  name: INVITE,
  path: '/invite/(sent)?',
  component: InvitePage,
  meta: {
    requiresAuth: true,
    menuText: 'routes.invite',
    menuPosition: 4,
    mobile: true,
  }
};

export const serverErrorRoute: RouteConfig = {
  name: SERVER_ERROR,
  path: '/error',
  component: ServerError,
  meta: {
    noMenu: true,
    mobile: true,
  }
};

const notFound: RouteConfig = {
  name: ERROR_NOT_FOUND,
  path: '*',
  component: NotFound404,
  meta: {
    noMenu: true,
    mobile: true,
  }
};

const routes = [
  loginRoute,
  registration,
  activatedRoute,
  resetPassword,
  homeRoute,
  profile,
  toolbox,
  trashBin,
  briefingSetup,
  briefingEdit,
  share,
  briefingCanvas,
  invite,
  serverErrorRoute,
  // fallback
  notFound,
];

// add some extra routes which are dedicated to testing the application
if (process.env.VUE_APP_TARGET_ENV !== 'production') {
  const editBriefingRaw: RouteConfig = {
    name: BRIEF_RAW,
    path: '/briefings/:uuid/raw',
    component: () => import(/* webpackChunkName: 'test-only' */ '@/pages/EditBriefingRaw.vue'),
    props: (route) => ({ uuid: route.params.uuid }),
    meta: {
      requiresAuth: true,
    },
  };

  const rawTemplate: RouteConfig = {
    name: BRIEF_TEMPLATE,
    path: '/templates/:id',
    component: () => import(/* webpackChunkName: 'test-only' */ '@/pages/RawTemplate.vue'),
    props: (route) => ({ id: parseInt(route.params.id) }),
  };

  routes.push(editBriefingRaw);
  routes.push(rawTemplate);
}

export default routes;
