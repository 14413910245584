<template>
  <svg
    id="multi-tiles"
    width="438.77"
    height="296.62"
    version="1.1"
    viewBox="0 0 438.77 296.62"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      transform="translate(312.93 260.94) rotate(166)"
      width="147.49"
      height="207.49"
      fill="#86cedc"
    />
    <rect
      transform="translate(114.62 278.93) rotate(219)"
      width="147.49"
      height="239.49"
      fill="#f04568"
    />
    <rect
      transform="translate(391 270.2) rotate(194)"
      width="147.49"
      height="197.49"
      fill="#fc0"
    />
    <path d="m233.38 66.941-113.75 28.359 33.826 135.67 111.88-138.15-31.955-25.877z" fill="#7e385a" />
    <path d="m277.12 117.3-29.227 117.22 62.326 15.539-33.1-132.76z" fill="#86a500" />
    <text
      v-if="title"
      x="50%"
      y="217"
      text-anchor="middle"
      fill="#fff"
      font-size="32"
    >{{ title.toUpperCase() }}</text>
  </svg>
</template>

<script>
export default {
  name: 'MultiTiles',
  props: {
    title: {
      type: String,
      default: undefined,
    },
  }
}
</script>

<style lang="sass">
#multi-tiles
  margin-top: -116px
  margin-right: 46px
  width: 438px

  @media #{map-get($display-breakpoints, 'sm-only')}
    width: 308px
    margin-right: 0

  @media #{map-get($display-breakpoints, 'xs-only')}
    width: 246px
    margin-top: -133px
    margin-right: -27px
  
</style>
