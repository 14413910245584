<template>
  <div class="briefing-radio briefing-field">
    <h2 v-if="value.Title" class="briefing-field-title">
      {{ value.Title }}
    </h2>
    <p v-if="value.FieldDescription" class="briefing-field-description" v-html="value.FieldDescription" />
    <v-radio-group v-model="data" hide-details @change="onChange">
      <v-radio
        v-for="o in value.Options"
        :key="o.value"
        :label="o.text"
        :ripple="false"
        :class="briefingType.BriefingType"
        :color="color"
        :value="o.value"
      />
    </v-radio-group>
    <add-user-option
      v-if="customOptionsAllowed"
      :color="color"
      :next-index="value.Options.length"
      :get-parent-id="getSubmittedFieldId"
      @option-added="onOptionAdded"
    />
  </div>
</template>

<script>
import FieldBase from './FieldBase';
import InputFieldBase from './InputFieldBase';
import OptionFieldBase from './OptionFieldBase';

export default {
  name: 'RadioField',
  mixins: [ FieldBase, InputFieldBase, OptionFieldBase ],
}
</script>

<style lang="sass">
.briefing-radio
  .v-input--selection-controls
    padding-top: 0
    margin-top: 0
</style>
