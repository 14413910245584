






import Vue from 'vue';

export default Vue.component('ButtonRow', {
  name: 'ButtonRow',
  props: {
    size: {
      type: String,
      default: 'l',
    },
    justify: {
      type: String,
      default: 'left',
    }
  }
});
