import Vue from 'vue';

Vue.use({
  install(Vue) {
    Vue.prototype.$APP = 'briefing.works';
    Vue.prototype.$TABLE_FOOTER_PROPS = { 'next-icon': 'mdi-menu-right', 'prev-icon': 'mdi-menu-left' };
    Vue.prototype.$DISQUS_SHORTNAME = 'brief-ing';
    Vue.prototype.$DISQUS_DOMAIN = window.location.origin;

    Vue.mixin({
      computed: {
        $isMobilePortrait() {
          return (this.$vuetify as any).breakpoint.xsOnly;
        },
        $isMobileLandscape() {
          return document.documentElement.clientHeight < 600;
        },
        $isMobile() {
          return this.$isMobilePortrait || this.$isMobileLandscape;
        },
        $isTabletOrMobile() {
          return (this.$vuetify as any).breakpoint.smAndDown;
        },
        $isTablet() {
          return (this.$vuetify as any).breakpoint.smOnly && !this.$isMobileLandscape;
        }
      }
    });
  }
});
