import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import enV from 'vuetify/lib/locale/en.js';
import deV from 'vuetify/lib/locale/de';
import en from '@/i18n/en.json';
import de from '@/i18n/de.json';

Vue.use(Vuetify);

export default new Vuetify({
  // there're some default texts in vuetify
  // use vuetify's built in i18n feature for them
  // overwrite the defaults with our translation, if exists
  lang: {
    locales: {
      en: {
        ...enV,
        ...en,
      },
      de: {
        ...deV,
        ...de,
      }
    },
    current: 'de',
  },
  theme: {
    themes: {
      light: {
        primary: '#000000',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c',
        success: '#4CAF50',
        warning: '#FFC107',
        anchor: '#8c9eff',
        main: "#0000ff",
      },
    },
    options: {
      variations: false
    }
  },
});
