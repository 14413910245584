


















import Vue from 'vue';
import ButtonBase from './ButtonBase';

export default Vue.component('ButtonL', {
  name: 'ButtonL',
  mixins: [ButtonBase],
  props: {
    emphasize: {
      type: Boolean,
      default: false,
    },
  },
});
