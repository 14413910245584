



























































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapMutations, mapState } from 'vuex';
import { mapBriefingState, BriefingStoreState } from '@/store/interfaces/BriefingStore';
import { queryFormModelHierarchy, initialVisibilities, createDisplayRules, updateVisibility, BriefingFormData } from '@/model/brief';
import { VisibilitiesMap, DisplayRuleMap, FieldUpdatedEvent } from '@/model/brief2';
import { BRIEF_CANVAS } from '@/routes';
import { StoreState, StoreMutations } from '@/store/interfaces';
import TableOfContentsLayout from '@/components/layout/TableOfContentsLayout.vue';
import FieldGroup from '@/components/editform/FieldGroup';
import BriefingTitle from '@/components/BriefingTitle.vue';
import BriefingStepsTile from '@/components/decoration/BriefingStepsTile.vue';
import AutoScrollList from '@/components/layout/AutoScrollList.vue';
import NewParticipantAndRoleDialog from '@/components/dialogs/NewParticipantAndRoleDialog.vue';

@Component({
  components: {
    BriefingTitle,
    TableOfContentsLayout,
    BriefingStepsTile,
    FieldGroup,
    AutoScrollList,
    NewParticipantAndRoleDialog,
  },
  computed: {
    ...mapState(['userId']),
    ...mapBriefingState(['createParticipantDialogShown']),
  },
  methods: {
    ...mapMutations([ 'loadStart', 'loadSuccess', 'loadError' ]),
  },
})
export default class EditBriefing extends Vue {
  /*
   * vuex
   */
  readonly userId!: StoreState['userId'];
  readonly createParticipantDialogShown!: BriefingStoreState['createParticipantDialogShown'];

  readonly loadStart!: StoreMutations.loadStart;
  readonly loadSuccess!: StoreMutations.loadSuccess;
  readonly loadError!: StoreMutations.loadError;

  /*
   * props
   */
  @Prop({ type: String, required: true }) uuid!: string;
  @Prop({ type: Number, default: 1 }) step!: number;
  @Prop({ type: Number, default: undefined }) field!: number;

  /*
   * data
   */
  dialog = false;
  currentStep = 1;
  tree: BriefingFormData | null = null; // initialized in the created hook!
  visibilities: VisibilitiesMap = {};
  displayRules: DisplayRuleMap = {}; // initialized in the created hook!

  /*
   * hooks
   */
  async created() {
    try {
      this.loadStart();

      const tree = await queryFormModelHierarchy(this.uuid);

      const allFields = tree.allFields;
      // TODO
      this.visibilities = initialVisibilities(allFields) as VisibilitiesMap;
      this.displayRules = createDisplayRules(allFields.flatMap(f => f.Rules));

      this.tree = tree;

      if (this.field) {
        const foundStep = this.tree.Steps.findIndex(
          s => s.allFields.some(f => f.ID === this.field)
        );

        if (foundStep < 0) {
          this.currentStep = this.step;
        } else {
          this.currentStep = foundStep + 1;
        }
      } else {
        this.currentStep = this.step;
      }

      Vue.$gtm.tag({
        briefingType: this.tree.BriefingType.Title,
        projectType: this.tree.ProjectType.Title,
      });

      this.loadSuccess();

      if (this.field) {
        this.$nextTick(() => this.$vuetify.goTo(`#field-${this.field}`));
      }
    } catch (e) {
      this.loadError(e);
    }
  }

  beforeDestroy() {
    Vue.$gtm.tag({
      briefingType: undefined,
      projectType: undefined,
    });
  }

  /*
   * methods
   */
  nextStep(n: number) {
    if (n < this.tree!.Steps.length) {
      this.currentStep = n + 1;
      this.$nextTick(() => {
        this.$vuetify.goTo(0);
      });
    } else {
      this.$router.push({ name: BRIEF_CANVAS, params: { uuid: this.uuid } });
    }
  }

  prevStep(n: number) {
    if (n > 0) {
      this.currentStep = n - 1;
      this.$nextTick(() => {
        this.$vuetify.goTo(0);
      });
    }
  }

  jumpToStep(n: number) {
    this.currentStep = n - 1;
    this.dialog = false;
    this.$nextTick(() => {
      this.$vuetify.goTo(0);
    });
  }

  onUpdated({ id, value }: FieldUpdatedEvent) {
    updateVisibility(id, value, this.displayRules, this.visibilities);
  }
}
