import {
  ContentText,
  ContentImage,
  ContentColumns,
  Content,
  ContentTable,
  ContentStack,
  ContentTocItem,
  CanvasLine,
  ContentCanvas,
  ContentSvg,
  TableCell,
  Column,
} from 'pdfmake/interfaces';
import { BriefingFieldGroup, BriefingStep } from '@/model/brief';
import { BriefingParticipantType } from '@/model/participant';
import { ClientType } from '@/model/clients';
import { downloadAsDataUri, downloadAsString } from '@/api';
import {
  TileItemData,
  TimingData,
  UploadedFileData,
  RelatedBriefingData
} from '@/model/fields';
import { splitIntoChunks } from '@/util/data';
import i18n from '@/i18n';
import { BriefingFieldInterface } from '@/model/brief2';
import { STYLES, ITEM_MARGIN, lineColor, TABLE_LAYOUTS, FONT_BASE } from './constants';
import noImageLogo from '!!raw-loader!@/assets/image-off-outline.svg';

export interface PdfDisplayable {
  renderPdfContent: () => Content | undefined | Promise<Content | undefined>;
}

const removeHtml = (value: string | undefined | null): string | undefined => {
  return value
    ?.replace(/<[/]?p>/gi, '')
    ?.replace('&nbsp;', ' ')
    ?.replace('&shy;', ' ');
};

export const resizeSvg = (content: string, width: number, height: number) => {
  return content
    .replace(/width="(?:[0-9]+)p[xt]"/, `width="${width}px"`)
    .replace(/height="(?:[0-9]+)p[xt]"/, `height="${height}px"`);
};

const WIDTH_PTTRN = /width="([0-9]+)(?:p[xt])?"/;
const HEIGHT_PTTRN = /height="([0-9]+)(?:p[xt])?"/;
const VIEWBOX_PTTRN = /viewBox="([-0-9.]+)[,\s]+([-0-9.]+)[,\s]+([-0-9.]+)[,\s]+([-0-9.]+)"/;

export const centeredSvg = (
  content: string,
  width: number,
  height: number
): ContentSvg => {
  // const widthRes = WIDTH_PTTRN.exec(content);
  // const heightRes = HEIGHT_PTTRN.exec(content);

  // if (!widthRes || !heightRes) {
  //   throw new Error(`svg has no width/height attribute set!: ${content}`);
  // }

  const viewboxRes = VIEWBOX_PTTRN.exec(content);

  if (!viewboxRes) {
    throw new Error(`svg has no viewboxRes attribute set!: ${content}`);
  }

  const left = parseFloat(viewboxRes[1]);
  const top = parseFloat(viewboxRes[2]);
  const right = parseFloat(viewboxRes[3]);
  const bottom = parseFloat(viewboxRes[4]);

  const origWidth = right - left;
  const origHeight = bottom - top;

  let newWidth: number,
    newHeight: number,
    margin: [number, number, number, number];
  if (origWidth / origHeight > width / height) {
    // original image is wider
    newWidth = width;
    newHeight = (width / origWidth) * origHeight;
    margin = [0, (height - newHeight) / 2, 0, (height - newHeight) / 2];
  } else {
    // original image is narrower
    newHeight = height;
    newWidth = (height / origHeight) * origWidth;
    margin = [(width - newWidth) / 2, 0, (width - newWidth) / 2, 0];
  }

  const newContent = content
    .replace(WIDTH_PTTRN, `width="${newWidth}px"`)
    .replace(HEIGHT_PTTRN, `height="${newHeight}px"`);

  return {
    svg: newContent,
    width: newWidth,
    height: newHeight,
    margin
  };
};

export const coverPage = (title: string, date: string): ContentStack => ({
  margin: [0, 300, 0, 0],
  alignment: 'left',
  stack: [
    { text: title, style: STYLES.TITLE },
    { text: date, style: STYLES.SUBTITLE }
  ],
  pageBreak: 'after'
});

export const stepTitle = (step: BriefingStep, color: string): ContentTocItem => ({
  margin: [0, ITEM_MARGIN],
  text: removeHtml(step.Title)!.toUpperCase(),
  style: STYLES.STEP_TITLE,
  color,
  pageBreak: 'before',
  headlineLevel: 1,
  tocItem: true,
  tocStyle: {
    bold: true
  },
  tocNumberStyle: {
    bold: true
  }
});

export const fieldGroupTitle = (group: BriefingFieldGroup): ContentTocItem => ({
  margin: [0, ITEM_MARGIN],
  text: removeHtml(group.Title)!.toUpperCase(),
  style: STYLES.GROUP_TITLE,
  headlineLevel: 2,
  tocItem: true,
  tocMargin: [ITEM_MARGIN, 0, 0, 0]
});

// TODO: add typing
export const fieldTitle = (field: any): ContentText => ({
  margin: [0, ITEM_MARGIN],
  text: removeHtml(field.CanvasTitle) || removeHtml(field.Title)!,
  style: STYLES.FIELD_TITLE,
  headlineLevel: 3
});

export const field = (content: Array<Content>): ContentStack => ({
  style: STYLES.FIELD_WRAPPER,
  stack: content,
  margin: [0, 0, 0, ITEM_MARGIN],
});

export const fieldGroup = (content: Array<Content>): ContentStack => ({
  style: STYLES.FIELD_GROUP_WRAPPER,
  stack: content
});

export const chartImage = (canvas: HTMLCanvasElement): ContentImage => ({
  image: canvas.toDataURL(),
  width: 500,
  height: 500
});

const tableHeader = (text: string): ContentText => ({
  text,
  bold: true
})

export const participantTable = (
  participants: Array<BriefingParticipantType>
): ContentTable => ({
  layout: TABLE_LAYOUTS.FIELD_TABLE,
  table: {
    headerRows: 1,
    widths: ['*', 'auto', 'auto', 'auto'],
    body: [
      [
        tableHeader(i18n.t('canvas.pdf.participants.name') as string),
        tableHeader(i18n.t('canvas.pdf.participants.role') as string),
        tableHeader(i18n.t('canvas.pdf.participants.company') as string),
        tableHeader(i18n.t('canvas.pdf.participants.email') as string),
      ],
      ...participants.map(p => [
        p.Title || '',
        p.Role || '',
        p.Company || '',
        p.Email || ''
      ])
    ]
  }
});

const NO_LOGO_SIZE = 130;
const NO_LOGO_FONT_SIZE = 14;
const NO_LOGO_ICON_SIZE = 40;
export const clientField = async (
  client: ClientType
): Promise<[ContentColumns, ContentText]> => {
  let logo: Content;
  if (client.Logo) {
    const image = await downloadAsDataUri(client.Logo);
    logo = <ContentImage> {
      image,
      fit: [NO_LOGO_SIZE, NO_LOGO_SIZE]
    };
  } else {
    logo = <ContentStack> {
      stack: [
        <ContentSvg> {
          svg: resizeSvg(noImageLogo, NO_LOGO_ICON_SIZE, NO_LOGO_ICON_SIZE),
          width: NO_LOGO_ICON_SIZE,
          height: NO_LOGO_ICON_SIZE,
        },
        <ContentText> {
          text: (i18n.t('canvas.client.no_logo') as string).toUpperCase(),
          fontSize: NO_LOGO_FONT_SIZE,
          bold: true,
        }
      ],
      width: NO_LOGO_SIZE,
      margin: [0, (NO_LOGO_SIZE - NO_LOGO_ICON_SIZE + NO_LOGO_FONT_SIZE) / 2, 0, 0],
      alignment: "center",
    };
  }

  let link: Array<ContentText>;
  if (client.CompanyURL) {
    link = [
      {
        text: client.CompanyURL,
        link: client.CompanyURL,
        margin: [0, 0, 0, ITEM_MARGIN]
      }
    ];
  } else {
    link = [];
  }

  return [
    {
      columns: [
        { ...logo, width: 150 },
        // [
        <ContentStack> {
          margin: [ITEM_MARGIN, 0, 0, 0],
          stack: [
            <ContentText> {
              text: client.CompanyName,
              bold: true,
              margin: [0, 0, 0, ITEM_MARGIN]
            },
            <ContentText> {
              text: client.Street
            },
            <ContentText> {
              text: `${client.Zip} ${client.Location}`
            },
            <ContentText> {
              text: client.Country,
              margin: [0, 0, 0, ITEM_MARGIN]
            },
            ...link,
            <ContentText> {
              text: client.Email
            },
          ]
        }
      ],
      margin: [0, 0, 0, ITEM_MARGIN]
    },
    {
      alignment: 'justify',
      text: client.Description || ''
    }
  ];
};

export const milestonesTable = (timings: Array<TimingData>, color: string): ContentTable => {
  const body: TableCell[][] = timings.map(t => [
    t.Title || '',
    t.From || (!t.To ? i18n.t('generic.to_be_done') : ''),
    t.To || '',
    t.CheckboxValue ? <ContentText> { text: '\uE800', alignment: 'right', style: STYLES.ICON, color } : ''
  ]);

  return {
    layout: TABLE_LAYOUTS.FIELD_TABLE,
    table: {
      headerRows: 1,
      widths: ['*', 85, 85, 'auto'],
      body: [
        [
          tableHeader(i18n.t(
            'create_briefing.milestones.table_header.name'
          ) as string),
          tableHeader(i18n.t(
            'create_briefing.milestones.table_header.from'
          ) as string),
          tableHeader(i18n.t(
            'create_briefing.milestones.table_header.to'
          ) as string),
          tableHeader(i18n.t(
            'create_briefing.milestones.table_header.checkbox'
          ) as string)
        ],
        ...body
      ]
    }
  };
};

export const filesTable = (timings: Array<UploadedFileData>): ContentTable => {
  const body: TableCell[][] = timings.map(t => [
    t.TagValue,
    t.Extension.toUpperCase(),
    <ContentText> {
      text: '\uE803',
      link: window.location.origin + t.URL,
      style: STYLES.ICON
    }
  ]);

  return {
    layout: TABLE_LAYOUTS.FIELD_TABLE,
    table: {
      headerRows: 1,
      widths: ['*', 'auto', 'auto'],
      body: [
        [
          tableHeader(i18n.t(
            'create_briefing.upload.table_header.tagname'
          ) as string),
          tableHeader(i18n.t(
            'create_briefing.upload.table_header.extension'
          ) as string),
          ''
        ],
        ...body
      ]
    }
  };
};

export const relatedBriefings = (
  briefings: Array<RelatedBriefingData>
): ContentTable => {
  const body: TableCell[][] = briefings.map(b => [
    {
      text: b.Title,
      link: window.location.origin + `/briefings/${b.Uuid}`
    } as ContentText,
    b.SubmitterName,
    b.Created
  ]);

  return {
    layout: 'lightHorizontalLines',
    table: {
      headerRows: 1,
      widths: ['*', 'auto', 'auto'],
      body: [
        [
          (i18n.t(
            'create_briefing.related.table_header.name'
          ) as string).toUpperCase(),
          (i18n.t(
            'create_briefing.related.table_header.submitter'
          ) as string).toUpperCase(),
          (i18n.t(
            'create_briefing.related.table_header.date'
          ) as string).toUpperCase()
        ],
        ...body
      ]
    }
  };
};

const TILES_IN_A_ROW = 3;
interface TileResult {
  isText: boolean;
  isSvg: boolean;
  src?: string;
  text: string;
}

const TILE_CONTAINER_SIZE = [161, 161];
const TILE_TEXT_MARGIN = (TILE_CONTAINER_SIZE[1] - FONT_BASE) / 2;
export const tilesField = async (
  tiles: Array<TileItemData>
): Promise<Array<ContentColumns>> => {
  const images: Array<TileResult> = await Promise.all(
    tiles.map(async t => {
      if (t.Tile) {
        if (t.Tile.URL.toLowerCase().endsWith('.svg')) {
          return {
            isText: false,
            isSvg: true,
            src: await downloadAsString(t.Tile.URL),
            text: t.Title,
          };
        } else {
          return {
            isText: false,
            isSvg: false,
            src: await downloadAsDataUri(t.Tile.URL),
            text: t.Title,
          };
        }
      } else {
        return {
          isText: true,
          isSvg: false,
          // TODO
          text: (t.Title || (t as any).text)
        };
      }
    })
  );

  return splitIntoChunks(images, TILES_IN_A_ROW).map(
    row =>
      ({
        columnGap: 16,
        columns: row.map(tile => {
          if (tile.isText) {
            return {
              width: TILE_CONTAINER_SIZE[0],
              text: tile.text,
              style: {
                alignment: 'center',
                fontSize: FONT_BASE,
                bold: true,
                margin: [0, TILE_TEXT_MARGIN, 0, 0],
              }
            };
          } else if (tile.isSvg) {
            return centeredSvg(
              tile.src!,
              TILE_CONTAINER_SIZE[0],
              TILE_CONTAINER_SIZE[1]
            );
          } else {
            return {
              image: tile.src,
              fit: TILE_CONTAINER_SIZE
            } as ContentImage;
          }
        })
      } as ContentColumns)
  );
};

export const underline = (width = 515): ContentCanvas => ({
  canvas: [
    {
      type: 'line',
      x1: 0,
      y1: 5,
      x2: width,
      y2: 5,
      lineWidth: 1,
      lineColor
    } as CanvasLine
  ]
});

const fieldTitleLeft = (field: BriefingFieldInterface): ContentText & Column => ({
  text: removeHtml(field.CanvasTitle) || removeHtml(field.Title)!,
  style: STYLES.FIELD_TITLE,
  headlineLevel: 3,
  width: 143
});

export const singleTextField = (field: BriefingFieldInterface, value: string): ContentColumns => ({
  columnGap: 11,
  columns: [
    fieldTitleLeft(field),
    <ContentText> {
      text: value,
      style: STYLES.SINGLE_TEXTFIELD
    }
  ]
});

export const optionField = (field: BriefingFieldInterface, values: Array<string>): ContentColumns => ({
  columnGap: 11,
  columns: [
    fieldTitleLeft(field),
    <ContentText> {
      text: values.flatMap(o => [
        {
          text: o,
          style: STYLES.OPTIONS_FIELD
        },
        '            '
      ])
    }
  ]
});

export const yesnoField = (value: 'ja' | 'nein'): Array<Content> => [
  {
    columns: [
      {
        text: 'JA',
        style: value === 'ja' ? STYLES.YESNO_ACTIVE : STYLES.YESNO_INACTIVE,
        margin: [0, 0, 10, 0]
      } as ContentText,
      {
        text: 'NEIN',
        style: value === 'nein' ? STYLES.YESNO_ACTIVE : STYLES.YESNO_INACTIVE
      } as ContentText
    ]
  } as ContentColumns,
  underline()
];

export const constWidthColumns = (
  width: number,
  contents: ReadonlyArray<ContentText | Array<Content>>
): ContentColumns => ({
  columns: contents.map(c => ({
    ...c,
    width
  }))
});

export const columns = (value: ContentColumns) => value;
