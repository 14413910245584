<template>
  <div class="milestones-field briefing-field">
    <h2 v-if="value.Title" class="briefing-field-title">
      {{ value.Title }}
    </h2>
    <p v-if="value.FieldDescription" class="briefing-field-description" v-html="value.FieldDescription" />

    <div
      v-for="(o, i) in titles.filter(t => !timings.some(m => m.Title === t))"
      :key="`sw-${i}`"
      class="d-flex table-row justify-space-between available-timing"
      @click="addNewTiming(o)"
    >
      <span class="milestone-item">{{ o }}</span>
      <v-icon size="12pt" :title="$t('generic.add')">
        mdi-plus
      </v-icon>
    </div>

    <add-new-value :color="color" :label="$t('create_briefing.upload.new_category')" @input="addNewTiming" />

    <h2 v-if="timings.length > 0" class="briefing-field-title mb-0">
      {{ $t('create_briefing.milestones.added_title') }}
    </h2>

    <v-data-table
      v-if="timings.length > 0"
      class="complex-table added-milestones"
      item-key="ID"
      :headers="headers"
      :items="timings"
    >
      <template #item.Title="{ item }">
        <v-textarea
          v-if="!$isTabletOrMobile"
          v-model="item.Title"
          :loading="item._isSaving"
          :rows="1"
          auto-grow
          hide-details
          single-line
          @change="submitEdit(item)"
        />
        <div v-if="$isTabletOrMobile">
          <span class="table-emphasized">{{ item.Title }}</span>
          <br>
          <span v-if="$isTabletOrMobile" class="date">{{ !item.CheckboxValue ? `${item.From} - ` : '' }}{{ item.To }}</span>
        </div>
      </template>
      <template #item.From="{ item }">
        <date-picker
          v-if="!item.CheckboxValue"
          v-model="item.From"
          :briefing-type="briefingType"
          :is-saving="item._isSaving"
          hide-details
          single-line
          :max="item.To"
          :start-with="item.To"
          @input="submitEdit(item)"
        />
      </template>
      <template #item.To="{ item }">
        <date-picker
          v-model="item.To"
          :briefing-type="briefingType"
          :is-saving="item._isSaving"
          hide-details
          single-line
          :min="item.CheckboxValue ? null : item.From"
          :start-with="item.CheckboxValue ? null : item.From"
          @input="submitEdit(item)"
        />
      </template>
      <template #item.CheckboxValue="{ item }">
        <v-switch
          v-model="item.CheckboxValue"
          class="v-input-switch--single-line"
          :color="color"
          :loading="item._isSaving"
          hide-details
          @change="submitEdit(item)"
        />
      </template>
      <template #item.action="{ item }">
        <v-icon size="18pt" :title="$t('generic.delete')" @click="showDeleteDialog(item.ID, item.Title)">
          mdi-delete-outline
        </v-icon>
      </template>
    </v-data-table>

    <milestone-delete-dialog
      :id="deleteDialog.id"
      :show="deleteDialog.show"
      :title="deleteDialog.title"
      :color="color"
      @success="onDelete"
      @dismiss="dismissDeleteDialog"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { sortFormData } from '@/model/brief';
import i18n from '@/i18n';
import { createSubmittedMilestone, createBriefingMilestone, editBriefingMilestone } from '@/api';
import FieldBase from './FieldBase';
import AddNewValue from './AddNewValue.vue';
import DatePicker from '@/components/common/DatePicker.vue';
import MilestoneDeleteDialog, {
  emptyDeleteDialogData, dismissDeleteDialog, showDeleteDialog
} from '@/components/dialogs/MilestonesDeleteDialog.vue';

const mapCheckboxValue = (value) => {
  return value ? '1' : '0';
};

const mapMilestoneOption = (res) => ({
  ...res,
  CheckboxValue: res.CheckboxValue === 1 || res.CheckboxValue === '1',
  _isSaving: false,
});

export default {
  name: 'MilestonesField',
  components: {
    AddNewValue,
    DatePicker,
    MilestoneDeleteDialog,
  },
  mixins: [ FieldBase ],
  data: () => ({
    titles: [],
    timings: [],
    headers: [
      { text: i18n.t('create_briefing.milestones.timing'), value: 'Title' },
      { text: i18n.t('create_briefing.milestones.from'), value: 'From', width: '200px' },
      { text: i18n.t('create_briefing.milestones.to'), value: 'To', width: '200px' },
      { text: i18n.t('create_briefing.milestones.is_milestone'), value: 'CheckboxValue' },
      { text: '', value: 'action', align: 'center', width: '1em', sortable: false },
    ],
    deleteDialog: emptyDeleteDialogData(),
  }),
  computed: {
    ...mapState(['userId']),
  },
  created() {
    this.timings = this.value.SubmittedData?.MilestoneOptions?.map(mapMilestoneOption)?.sort(sortFormData) || [];
    this.titles = this.value.MilestoneOptions.map(o => o.Title);
  },
  methods: {
    ...mapActions(['saveSuccess']),
    ...mapMutations([ 'saveStart', 'saveError', 'setError' ]),
    async addNewTiming(title) {
      if (this.submittedId == null) {
        const { ID, Parent, FieldType, Name, Sort, Title, } = this.value;

        const { ID: submittedId } = await createSubmittedMilestone({
          ParentID: this.submittedFormId,
          ParentElementID: Parent,
          BriefingFieldClassName: FieldType,
          Name: Name,
          Sort: Sort,
          Title: Title,
          RelatedFieldID: ID,
        });

        this.submittedId = submittedId;
      }

      const query = {
        Title: title,
        Sort: this.timings.length + 1,
        From: null,
        To: null,
        CheckboxValue: mapCheckboxValue(false),
        Parent: this.submittedId,
      };

      const res = await createBriefingMilestone(query);

      this.timings.push(mapMilestoneOption(res));
    },
    showDeleteDialog(id, title) {
      showDeleteDialog(this.deleteDialog, id, title);
    },
    dismissDeleteDialog() {
      dismissDeleteDialog(this.deleteDialog);
    },
    async onDelete() {
      this.timings = this.timings.filter(t => t.ID != this.deleteDialog.id);
      this.dismissDeleteDialog();
    },
    async submitEdit(item) {
      try {
        item._isSaving = true;

        const res = await editBriefingMilestone(item.ID, {
          Title: item.Title,
          From: item.CheckboxValue ? null : item.From,
          To: item.To,
          CheckboxValue: mapCheckboxValue(item.CheckboxValue),
        });

        this.timings = this.timings.map(t => t.ID === res.ID ? mapMilestoneOption(res) : t);
      } catch (e) {
        this.saveError(e);
      }

      item._isSaving = false;
    },
    
  }
}
</script>

<style lang="sass">
.milestone-item
  font-size: $font-size-table-emphasized
  font-weight: $font-light

  @media #{map-get($display-breakpoints, 'sm-and-down')}
    font-size: $font-size-table-emphasized-m

.milestones-field
  .datepickers > div
    padding-top: 0
    padding-bottom: 0

.v-data-table.complex-table.added-milestones tbody td
  border-bottom: none !important

.added-milestones tbody td
  vertical-align: bottom
  padding-bottom: 14px !important

  .v-input--switch
    margin-bottom: 10px
  
  .mdi-delete-outline
    margin-bottom: 8px

.available-timing
  cursor: pointer
</style>
