



















import Vue from 'vue';

export default Vue.component('DialogContent', {
  name: 'DialogContent',
  props: {
    title: {
      type: String,
      required: true,
    }
  },
  computed: {
    alignButtons(): string {
      return this.$isMobile ? 'center' : 'end';
    },
  }
});
