









































































































import { Vue, Component } from 'vue-property-decorator';
import { mapMutations, mapActions } from 'vuex';
import { register, resendActivationMail } from '@/api';
import ExternalLayout from '@/components/layout/ExternalLayout.vue';
import MultiTiles from '@/components/layout/MultiTiles.vue';
import { StoreActions, StoreMutations } from '@/store/interfaces';
import { RegistrationFormHelper, RegistrationFormType } from '@/model/user';

@Component({
  components: {
    MultiTiles,
    ExternalLayout,
  },
  methods: {
    ...mapMutations(['saveStart', 'saveError', 'setError']),
    ...mapActions(['saveSuccess']),
  }
})
export default class Registration extends Vue {
  /*
   * vuex
   */
  readonly saveStart!: StoreMutations.saveStart;
  readonly saveError!: StoreMutations.saveError;
  readonly setError!: StoreMutations.setError;
  readonly saveSuccess!: StoreActions.saveSuccess;

  /*
   * data
   */
  step = 1;
  valid = false;
  error: string | null = null;
  userId: number | null = null;
  emailCheckInProgress = false;
  emailError: string | null = null;
  form: RegistrationFormType = RegistrationFormHelper.create();
  validation = RegistrationFormHelper.validation;

  /*
   * methods
   */
  async submit() {
    const query = RegistrationFormHelper.finalize(this.valid, this.form);

    if (!query) {
      return;
    }

    try {
      this.saveStart();
      const res = await register(query);

      if (res.UserID) {
        this.userId = res.UserID;
        this.saveSuccess();
        this.step = 2
      } else {
        throw new Error(`No userid in the response!`);
      }
    } catch (e) {
      this.saveError(e);
    }
  }

  async resendEmail() {
    try {
      this.saveStart();
      await resendActivationMail(this.userId!);
      this.saveSuccess();
    } catch (e) {
      this.saveError(e);
    }
  }
}
