

























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { mapMutations, mapState, mapGetters } from 'vuex';
import { BRIEF_CANVAS, BRIEFING_EDIT, PROFILE, redirectToLogin } from '@/routes';
import { clearAuth } from '@/util/auth';
import MainMenu from '@/components/layout/MainMenu.vue';
import AppLogo from '@/components/layout/AppLogo.vue';
import AppLogoMobile from '@/components/layout/AppLogoMobile.vue';
import { StoreState, StoreGetters, StoreMutations } from './store/interfaces';
import { isNoAuthError, isAxiosError, sendMobileReminderEmail, isMultilangError } from './api';

@Component({
  components: {
    MainMenu,
    AppLogo,
    AppLogoMobile,
  },
  computed: {
    ...mapState(['loading', 'error', 'successPopupTex', 'dontShowMobileWarningDialog', 'dontShowTabletWarningDialog', 'profileNotCompleted']),
    ...mapGetters(['isAuthenticated']),
  },
  methods: mapMutations([
    'clearUser',
    'dismissError',
    'dismissSaveSuccessPopup',
    'dismissMobileWarningDialog',
    'dismissTabletWarningDialog',
    'dismissProfileNotCompletedDialog',
  ])
})
export default class App extends Vue {
  /*
   * vuex
   */
  loading!: StoreState['loading'];
  error!: StoreState['error'];
  successPopupTex!: StoreState['successPopupTex'];
  dontShowMobileWarningDialog!: StoreState['dontShowMobileWarningDialog'];
  dontShowTabletWarningDialog!: StoreState['dontShowTabletWarningDialog'];
  readonly profileNotCompleted!: StoreState['profileNotCompleted'];

  isAuthenticated!: StoreGetters.isAuthenticated;

  clearUser!: StoreMutations.clearUser;
  dismissError!: StoreMutations.dismissError;
  dismissSaveSuccessPopup!: StoreMutations.dismissSaveSuccessPopup;
  dismissMobileWarningDialog!: StoreMutations.dismissMobileWarningDialog;
  dismissTabletWarningDialog!: StoreMutations.dismissTabletWarningDialog;
  readonly dismissProfileNotCompletedDialog!: StoreMutations.dismissProfileNotCompletedDialog;

  /*
   * data
   */
  drawer = false;
  mobileDialog = {
    reminderCheckbox: true,
  };
  tabletDialog = {
    checkbox: false,
  };

  /*
   * computed
   */
  get mobileWarningDialog() {
    return this.isAuthenticated && this.$isMobile && !this.dontShowMobileWarningDialog;
  }

  get tabletWarningDialog() {
    return this.isAuthenticated && this.$isTablet && !this.dontShowTabletWarningDialog;
  }

  get layoutClass() {
    return this.$route.meta?.layoutClass;
  }

  get isUnauthorizedError() {
    return this.error
      && (isNoAuthError(this.error)
        || (isAxiosError(this.error)
          && this.error.response?.status == 403
        )
      );
  }

  get errorMessage() {
    if (this.error) {
      if (isMultilangError(this.error)) {
        return this.$t(this.error.messageId);
      } else if (this.isUnauthorizedError) {
        return this.$t('login.unauthorized_error');
      } else if (typeof this.error === 'string') {
        return this.$t('server_error');
      } else {
        return this.error.message;
      }
    }

    return null;
  }

  get shouldDrawerBeFullWidth() {
    return this.$isMobile || this.$isMobileLandscape ? '100%' : '334px';
  }

  /*
   * hooks
   */
  mounted() {
    this.drawer = !this.$isMobile;
  }

  /*
   * methods
   */
  cleanupAuth() {
    this.clearUser();
    clearAuth();
    this.$router.push(redirectToLogin(this.$router.currentRoute));
  }

  onCloseErrorDialog() {
    if (this.isUnauthorizedError) {
      const route = this.$router.currentRoute;

      // if the user was seeing a canvas/brief edit page don't log her out but redirect
      // to the page
      if (route.name === BRIEF_CANVAS || route.name === BRIEFING_EDIT) {
        this.$router.push({
          name: BRIEF_CANVAS,
          params: route.params
        });
      } else {
        this.cleanupAuth();
      }
    }

    this.dismissError();
  }

  finishProfile() {
    this.dismissProfileNotCompletedDialog();
    this.$router.push({ name: PROFILE });
  }

  toggleMenu() {
    if (this.isAuthenticated) {
      this.drawer = !this.drawer;
    }
  }

  onMobileWarningDialogOk() {
    if (this.mobileDialog.reminderCheckbox) {
      sendMobileReminderEmail();
    }

    this.dismissMobileWarningDialog();
  }
}
