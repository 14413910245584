<script>
import { fieldTypes } from '@/model/brief';
import ElementBase from './ElementBase';
import Textarea from './Textarea.vue';
import Participants from './Participants.vue';
import Milestones from './Milestones.vue';
import Files from './Files.vue';
import RelatedBriefings from './RelatedBriefings.vue';
import YesNo from './YesNo.vue';
import SimpleText from './SimpleText.vue';
import Options from './Options.vue';
import Budget from './Budget.vue';
import SingleDate from './SingleDate.vue';
import TileGroup from './TileGroup.vue';
import Clients from './Clients.vue';
import MatrixChartCanvas from './MatrixChartCanvas.vue';
import SpiderChartCanvas from './SpiderChartCanvas.vue';

export default {
  name: 'CanvasFragment',
  mixins: [ ElementBase ],
  methods: {
    renderPdfContent() {
      if (this.$refs.field.renderPdfContent) {
        return this.$refs.field.renderPdfContent();
      }
      
      return `TODO: '${this.value.Title}'  @${this.value.FieldType}`
    }
  },
  render(h) {
    // ignore *CustomOption typed fields
    // user options will be implemented differently and these fields are not needed anymore
    // they will be removed in the future when the prototype application won't be needed anymore
    if (this.value.FieldType.endsWith('CustomOption')) {
      return null;
    }

    const options = {
      ref: 'field',
      props: {
        value: this.value,
        briefingType: this.briefingType,
      }
    };

    if (this.value.Model !== undefined) {
      return h(RelatedBriefings, options);
    }

    switch (this.value.FieldType) {
      case fieldTypes.BriefingEmailField:
        return h(Textarea, options);
      case fieldTypes.BriefingTextField:
        if (this.value.Rows > 1) {
          return h(Textarea, options);
        } else {
          return h(SimpleText, options);
        }
      case fieldTypes.BriefingYesNoRadioField:
        return h(YesNo, options);
      case fieldTypes.BriefingDropdown:
      case fieldTypes.BriefingRadioField:
      case fieldTypes.BriefingSidebysideSelect:
        return h(Options, options);
      case fieldTypes.BriefingPeopleDropdown:
        return h(Participants, options);
      case fieldTypes.BriefingMilestoneField:
        return h(Milestones, options);
      case fieldTypes.BriefingFileField:
        return h(Files, options);
      case fieldTypes.BriefingBudgetField:
        return h(Budget, options);
      case fieldTypes.BriefingDateField:
        return h(SingleDate, options);
      case fieldTypes.BriefingTilesField:
        return h(TileGroup, options);
      case fieldTypes.BriefingClientField:
        return h(Clients, options);
      case fieldTypes.BriefingSpiderChartField:
        return h(SpiderChartCanvas, options);
      case fieldTypes.BriefingMatrixChartField:
        return h(MatrixChartCanvas, options);
      default:
        console.log(`Unknown fieldtype: ${this.value.FieldType}, field ID #${this.value.ID}`);
        return h();
    }
  },
}
</script>
