<template>
  <div>
    <h2 v-if="value.Title" class="briefing-field-title">
      {{ value.Title }}
    </h2>
    <p v-if="value.FieldDescription" class="briefing-field-description" v-html="value.FieldDescription" />

    <input
      ref="hidden"
      type="file"
      multiple
      style="visibility: hidden; height: 0px; position: fixed;"
      @change="onFileSelected"
    >

    <div
      class="fileupload briefing-field d-flex flex-column justify-space-between"
      :class="{ 'active': isOver }"
      @drop.prevent="onFileDropped"
      @dragenter.prevent="isOver = true"
      @dragover.prevent="isOver = true"
      @dragleave.prevent="isOver = false"
      @dragend.prevent="isOver = false"
    >
      <div v-if="!disabled" class="upload-area-wrapper" @click="onClickArea">
        <div class="upload-area d-flex flex-column justify-center" :style="{ 'border-color': color }">
          <p class="ma-1" v-html="'&nbsp;'" />
          <p class="ma-1 strong" :style="{ color: color }">
            {{ $t('create_briefing.upload.choose') }}
          </p>
          <p class="ma-1">
            {{ $t('create_briefing.upload.choose_subline') }}
          </p>
        </div>
      </div>
      <div v-else class="progress-area pa-6">
        <div v-for="f in filesToUpload" :key="f.file.name" class="file-progress pa-4 d-flex align-center">
          <span class="white--text mr-5 flex-grow-0 flex-shring-0">{{ f.file.name }}</span>
          <v-progress-linear
            :value="f.progress"
            :color="color"
            height="10px"
            background-opacity="0"
          />
          <v-icon class="ml-5" color="red" @click="onAbortUpload(f)">
            mdi-close
          </v-icon>
        </div>
        <p class="ma-2 white--text strong">
          {{ $t('create_briefing.upload.is_uploading') }}
        </p>
      </div>
    </div>

    <h2 v-if="uploadedFiles.length > 0" class="briefing-field-title uploaded-title">
      {{ $t('create_briefing.upload.uploaded_title') }}
    </h2>

    <card-list
      v-if="uploadedFiles.length > 0"
      :value="uploadedFiles"
      can-delete
      can-edit
      @deleted="showDeleteDialog"
      @edited="showEditDialog"
    >
      <template #default="{ item }">
        <div>{{ item.FileSize }}</div>
        <div>{{ item.Name }}</div>
      </template>
    </card-list>

    <v-dialog v-model="dialog.shown" persistent max-width="720px">
      <dialog-content v-if="dialog.shown" class="upload-tagchoose-dialog" :title="$t('create_briefing.upload.title')">
        <template #top>
          <span class="upload-step">{{ $t('create_briefing.upload.counter', [ dialog.step, files.length ]) }}</span>
        </template>

        <p class="fileupload-dialog-text">
          {{ $t('create_briefing.upload.text1', [ files.length ]) }}
          <br>
          {{ $t('create_briefing.upload.text2') }}
        </p>
        <p class="filename" :style="{ color: color }">
          {{ currentFile.name }}
        </p>
        <div class="text-center" style="margin-top: -4px; margin-bottom: 15px">
          <img src="@/assets/upload-arrow.svg">
        </div>

        <v-item-group v-model="dialog.tag" class="tags">
          <button-row size="l">
            <v-item
              v-for="t in tags"
              :key="t"
              v-slot="{ active, toggle }"
            >
              <v-btn
                large
                class="tag"
                tile
                :outlined="!active"
                depressed
                color="black"
                :class="`${briefingType.BriefingType}--text`"
                :style="{ 'border-color': active ? `${color} !important` : 'black' }"
                @click="toggle"
              >
                {{ t }}
              </v-btn>
            </v-item>
          </button-row>

          <add-new-value :color="color" :label="$t('create_briefing.upload.new_category')" @input="addNewTag" />
        </v-item-group>

        <template #actions>
          <button-xl :color="color" @click="dismissDialog">
            {{ $t('generic.cancel') }}
          </button-xl>
          <button-stack size="xl" @click.prevent="skipFile">
            <template #secondary>
              {{ $t('create_briefing.upload.skip') }}
            </template>

            <button-xl
              emphasize
              :color="color"
              :disabled="dialog.tag == null"
              @click="nextFile"
            >
              {{ $t('generic.forward') }}
            </button-xl>
          </button-stack>
        </template>
      </dialog-content>
    </v-dialog>


    <v-dialog v-model="deleteDialog.shown" max-width="720px" persistent>
      <dialog-content :title="$t('create_briefing.upload.delete.title')">
        <template #default>
          <p>{{ $t('create_briefing.upload.delete.text', [ deleteDialog.file.Title ]) }}</p>
        </template>
        <template #actions>
          <button-xl :color="color" @click="dismissDelete">
            {{ $t('generic.cancel') }}
          </button-xl>
          <button-xl emphasize :color="color" @click="submitDelete">
            {{ $t('generic.ok') }}
          </button-xl>
        </template>
      </dialog-content>
    </v-dialog>

    <v-dialog v-model="editDialog.shown" max-width="720px" persistent>
      <dialog-content :title="$t('create_briefing.upload.edit.title')">
        <p v-if="editDialog.file">
          {{ editDialog.file.Name }}
          <a :href="editDialog.file.URL" class="float-right" download>
            <v-icon size="20px" :title="$t('generic.download')" class="mr-1">
              mdi-download-outline
            </v-icon>
          </a>
        </p>
        
        <v-item-group v-model="editDialog.tag" class="tags">
          <button-row size="l">
            <v-item
              v-for="t in tags"
              :key="`tag-${t}`"
              v-slot="{ active, toggle }"
            >
              <v-btn
                large
                class="tag"
                tile
                :outlined="!active"
                depressed
                color="black"
                :class="`${briefingType.BriefingType}--text`"
                :style="{ 'border-color': active ? `${color} !important` : 'black' }"
                @click="toggle"
              >
                {{ t }}
              </v-btn>
            </v-item>
          </button-row>

          <add-new-value :color="color" :label="$t('create_briefing.upload.new_category')" @input="addNewTagInEdit" />
        </v-item-group>

        <template #actions>
          <button-xl :color="briefingType.Color" @click="dismissEditDialog">
            {{ $t('generic.cancel') }}
          </button-xl>
          <button-xl
            emphasize
            :color="briefingType.Color"
            :disabled="editDialog.tag == null"
            @click="submitEditDialog"
          >
            {{ $t('generic.save') }}
          </button-xl>
        </template>
      </dialog-content>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapMutations } from 'vuex';
import { uploadFile, createSubmittedFile, deleteSubmittedFile, editSubmittedFile } from '@/api';
import CardList from '@/components/common/CardList.vue';
import FieldBase from './FieldBase';
import AddNewValue from './AddNewValue.vue';

export default {
  name: 'FileUploadField',
  components: {
    CardList,
    AddNewValue,
  },
  mixins: [ FieldBase ],
  data: () => ({
    dialog: {
      shown: false,
      step: null,
      tag: null,
    },
    deleteDialog: {
      shown: false,
      file: null,
    },
    editDialog: {
      shown: false,
      file: null,
      tag: null,
    },
    isOver: false,
    tags: [],
    filename: null,
    files: [],
    filesToUpload: [],
    uploadedFiles: [],
  }),
  computed: {
    ...mapState(['userId']),
    currentFile() {
      return this.files[this.dialog.step - 1];
    },
    disabled() {
      return this.filesToUpload.length > 0;
    }
  },
  created() {
    this.uploadedFiles = this.value
      ?.SubmittedData
      ?.FileOptions
      ?.map(f => ({
        ID: f.ID,
        Name: f.Title,
        FileSize: f.Size,
        URL: f.URL,
        Title: f.TagValue,
      }))
     || [];

    this.tags = (this.value.Tags || [])
      .map(t => t.Title)
      // add custom tags from the uploaded fields as well
      .concat(this.uploadedFiles
        .map(f => f.Title)
        .filter(tagName => tagName && !this.value.Tags.some(t => t?.Title?.toLowerCase() === tagName.toLowerCase()))
      );
  },
  methods: {
    ...mapMutations(['setError']),
    onClickArea() {
      this.$refs.hidden.click();
    },
    async upload(fileWrapper) {
      try {
        const name = fileWrapper.file.name;
        const size = fileWrapper.file.size;
        this.isOver = false;

        const req = new FormData();

        req.append('TagValue', fileWrapper.tag);
        req.append('file', fileWrapper.file);

        const canceler = axios.CancelToken.source();
        fileWrapper.canceler = canceler;
        const { BriefingFile, FileUrl } = await uploadFile(this.submittedId, req, (progress) => {
          fileWrapper.progress = progress;
        }, canceler);

        this.uploadedFiles.push({
          ID: BriefingFile,
          Name: name,
          FileSize: this.$formatFileSize(size),
          URL: FileUrl,
          Title: fileWrapper.tag,
        });

        this.filesToUpload = this.filesToUpload.filter(f => f !== fileWrapper);
      } catch (e) {
        this.filesToUpload = this.filesToUpload.filter(f => f !== fileWrapper);
        this.setError(e);
      }
    },
    checkSizes(files) {
      if (this.value.MaxFileSizeMB) {
        files.forEach(f => {
          if (f.size > this.value.MaxFileSizeMB * 1024 * 1024) {
            throw new Error(this.$t('create_briefing.upload.file_too_big', [ this.value.MaxFileSizeMB ]));
          }
        });
      }
    },
    showDialog(files) {
      try {
        this.checkSizes(files);
        this.dialog.step = 1;
        this.dialog.shown = true;
      } catch (e) {
        this.setError(e);
      }
    },
    onFileSelected() {
      if (!this.disabled) {
        this.files = Array.from(this.$refs.hidden.files);
        this.showDialog(this.files);
      }
    },
    onFileDropped(event) {
      if (!this.disabled) {
        const dnd = event.dataTransfer;
        dnd.dropEffect = "copy";

        this.files = Array.from(dnd.files);
        this.showDialog(this.files);
      }
    },
    endDialog() {
      this.dialog.step = 0;
      this.dialog.tag = null;
      this.dialog.shown = false;
    },
    dismissDialog() {
      this.filesToUpload = [];
      this.endDialog();
    },
    async uploadAllFiles() {
      if (this.submittedId == null) {
        const { ID, Parent, FieldType, Name, Sort, Title, } = this.value;

        const { ID: submittedId } = await createSubmittedFile({
          ParentID: this.submittedFormId,
          ParentElementID: Parent,
          BriefingFieldClassName: FieldType,
          Name: Name,
          Sort: Sort,
          Title: Title,
          RelatedFieldID: ID,
        });

        this.submittedId = submittedId;
      }
      
      this.filesToUpload.forEach(f => {
        this.upload(f);
      });
    },
    skipFile() {
      if (this.dialog.step === this.files.length) {
        this.uploadAllFiles();
        this.endDialog();
      }

      this.dialog.step += 1;
      this.dialog.tag = null;
    },
    nextFile() {
      const tag = this.tags[this.dialog.tag];

      this.filesToUpload.push({
        tag,
        file: this.files[this.dialog.step - 1],
        progress: 0,
        canceler: null,
      });

      this.skipFile();
    },
    onAbortUpload(fileWrapper) {
      fileWrapper.canceler.cancel(this.$t('create_briefing.upload.upload_cancelled'));
      this.filesToUpload.filter(f => f !== fileWrapper);
    },
    showDeleteDialog(file) {
      this.deleteDialog.shown = true;
      this.deleteDialog.file = file;
    },
    dismissDelete() {
      this.deleteDialog.file = null;
      this.deleteDialog.shown = false
    },
    async submitDelete() {
      try {
        const file = this.deleteDialog.file;
        await deleteSubmittedFile(file.ID);
        this.uploadedFiles = this.uploadedFiles.filter(f => f !== file);
        this.dismissDelete();
      } catch (e) {
        this.setError(e);
      }
    },
    addNewTag(newTag) {
      this.tags.push(newTag);
      this.dialog.tag = this.tags.length - 1;
    },
    addNewTagInEdit(newTag) {
      this.tags.push(newTag);
      this.editDialog.tag = this.tags.length - 1;
    },
    showEditDialog(file) {
      this.editDialog.shown = true;
      this.editDialog.file = file;
    },
    dismissEditDialog() {
      this.editDialog.shown = false;
      this.editDialog.file = null;
      this.editDialog.tag = null;
    },
    async submitEditDialog() {
      try {
        const file = this.editDialog.file;
        const tag = this.tags[this.editDialog.tag];
        await editSubmittedFile(file.ID, { TagValue: tag });
        file.Title = tag;
        this.dismissEditDialog();
      } catch (e) {
        this.setError(e);
      }
    }
  }
}
</script>

<style lang="sass">
$fileupload-padding: 8px

.fileupload
  padding: $fileupload-padding
  background-color: black
  min-height: 262px

  &.active .upload-area-wrapper, &:hover .upload-area-wrapper
    padding: 12px

  .upload-area-wrapper
    padding: 24px
    height: 100%
    transition: padding 0.5s ease
    cursor: pointer
    height: 262px - 2 * $fileupload-padding

    .upload-area
      color: white
      height: 100%
      border: 4px dashed

      p
        text-align: center

        &.strong
          font-size: 1.2em
          text-transform: uppercase
          font-weight: $font-bold

  .progress-area
    text-align: center

    .v-progress-linear
      border: 1px solid #707070

    .strong
      font-weight: $font-medium

.file-progress
  span
    text-align: left
    width: 10em
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden

.tags
  .v-item--active
    color: white
  
  .v-btn.tag
    margin: $btn-l-margin / 2
    border: 2px dashed black

.v-card.upload-tagchoose-dialog
  .v-card__actions
    padding-top: 32px !important

.upload-step
  padding: 16px 24px
  color: $gray-color
  font-size: 10.5pt
  font-weight: bold
  text-transform: uppercase

.uploaded-title
  margin-top: $field-text-margin
  margin-bottom: 0 !important

p.filename
  line-height: 1
  padding-top: 12.375pt
  padding-bottom: 12.375pt
  font-size: 16.5pt
  font-weight: $font-bold
  background-color: black
  text-align: center
  margin-bottom: 0

p.fileupload-dialog-text
  margin-bottom: $field-text-margin
</style>
