import { VNode, CreateElement } from 'vue';
import FieldBase from './FieldBase';

export default class UnknownField extends FieldBase {
  render(h: CreateElement): VNode {
    if (process.env.VUE_APP_TARGET_ENV === 'production') {
      return h();
    } else {
      return h('div', {
        class: 'unknown-field-type'
      }, [
        `Unknown field (#${this.value.ID}): ${this.value.Title}`
      ]);
    }
  }
}
