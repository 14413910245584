






















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Content } from 'pdfmake/interfaces';
import { BriefingFieldGroup, BriefingStep, CanvasData } from '@/model/brief';
import { stepTitle, PdfDisplayable } from '@/pdf/components';
import CanvasGroup from '@/components/canvas/CanvasGroup.vue';

type GroupScrollThreshold = {
  id: number;
  start: number;
};

@Component({
  components: {
    CanvasGroup
  }
})
export default class CanvasContent extends Vue implements PdfDisplayable {
  /*
   * refs
   */
  $refs!: {
    [key: string]: Array<HTMLElement & PdfDisplayable>
  };

  /*
   * props
   */
  @Prop({ type: CanvasData, required: true })
  readonly value!: CanvasData;

  /*
   * data
   */
  thresholds: GroupScrollThreshold[] = [];

  /*
   * hooks
   */
  mounted() {
    if (this.value.Steps.length < 1) {
      return;
    }

    const thresholds = [{
      id: this.value.Steps[0].ID,
      start: 0,
    }];

    for(let i = 1; i < this.value.Steps.length; ++i) {
      const step = this.value.Steps[i];
      const ref = this.$refs[this.generateStepKey(step)][0];
      const topPosition = ref.getBoundingClientRect().top;

      thresholds.push({
        id: step.ID,
        start: topPosition - 150,
      });
    }

    this.thresholds = thresholds.reverse();
  }

  /*
   * methods
   */
  generateStepKey(s: BriefingStep) {
    return `step-${s.ID}`;
  }

  generateGroupKey(f: BriefingFieldGroup) {
    return `fieldset-${f.ID}`;
  }

  onScroll() {
    this.$emit('scrolled-to', this.thresholds.find(t => t.start <= window.scrollY)?.id);
  }

  async renderPdfContent() {
    return Promise.all(
      this.value!.Steps.map(step =>
        Promise.all(
          step.Groups
            .map((group) => {
              const r = this.$refs[this.generateGroupKey(group)][0];
              return r.renderPdfContent()
            })
        ).then(groupsRendered => [
          stepTitle(step, this.value.BriefingType.Color),
          ...groupsRendered.filter((group): group is Content => group !== undefined),
        ])
      ),
    );
  }
}
