<template>
  <dialog-content :title="id === null ? $t('client.create') : $t('client.edit')">
    <v-form ref="form" v-model="valid" class="client-form">
      <v-text-field
        v-model="form.CompanyName"
        :label="$t('client.labels.name')"
        :rules="validation.CompanyName"
        :color="color || 'black'"
        required
      />

      <v-text-field
        v-model="form.Street"
        :label="$t('client.labels.street')"
        :rules="validation.Street"
        :color="color || 'black'"
        required
      />

      <v-text-field
        v-model="form.Zip"
        :label="$t('client.labels.zip')"
        :rules="validation.Zip"
        :color="color || 'black'"
        required
      />

      <v-text-field
        v-model="form.Location"
        :label="$t('client.labels.location')"
        :rules="validation.Location"
        :color="color || 'black'"
        required
      />

      <v-text-field
        v-model="form.Country"
        :label="$t('client.labels.country')"
        :rules="validation.Country"
        :color="color || 'black'"
        required
      />

      <v-text-field
        v-model="form.Description"
        :label="$t('client.labels.description')"
        :rules="validation.Description"
        :color="color || 'black'"
        required
      />

      <v-text-field
        v-model="form.CompanyURL"
        :label="$t('client.labels.url')"
        :rules="validation.CompanyURL"
        :color="color || 'black'"
        required
      />

      <v-text-field
        v-model="form.Email"
        :label="$t('profile.labels.email')"
        :rules="validation.Email"
        :color="color || 'black'"
        type="email"
        required
      />

      <div class="d-flex align-center">
        <div class="client-logo" :class="logoUrl ? null : 'no-image'">
          <v-img
            v-if="logoUrl"
            :src="logoUrl"
            contain
            width="100%"
            height="100%"
          />
          <span v-else>No logo</span>
        </div>
        <v-file-input
          v-model="logo"
          :label="$t('client.labels.logo')"
        />
      </div>
    </v-form>

    <template #actions>
      <button-xl :color="color" @click="dismiss">
        {{ $t('generic.cancel') }}
      </button-xl>
      <button-xl
        emphasize
        :color="color"
        :disabled="!valid"
        @click="submit"
      >
        {{ $t('generic.ok') }}
      </button-xl>
    </template>
  </dialog-content>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { ClientData } from '@/model/userdata';

export default {
  name: 'ClientDialog',
  props: {
    id: {
      type: Number,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
    afterCreated: {
      type: Function,
      default: null,
    },
    color: {
      type: String,
      default: undefined,
    }
  },
  data: () => ({
    valid: false,
    validation: ClientData.validation,
    form: null,
    logo: null,
    logoDataUrl: null,
  }),
  computed: {
    logoUrl() {
      return this.logoDataUrl || this.value?.Logo;
    }
  },
  watch: {
    value(newValue) {
      this.form = { ...newValue };
    },
    logo(newValue) {
      if (newValue) {
        const reader = new FileReader();

        reader.addEventListener('load', () => {
          this.logoDataUrl = reader.result;
        }, false);

        reader.readAsDataURL(newValue);
      }
    }
  },
  created() {
    this.form = this.value ? ClientData.copy(this.value) : ClientData.create();
  },
  methods: {
    ...mapActions(['createClient', 'editClient', 'uploadClientLogo', 'saveSuccess']),
    ...mapMutations(['saveStart', 'saveError']),
    dismiss() {
      this.reset();
      this.$emit('dismiss');
    },
    reset() {
      this.form = ClientData.create();
      this.logo = null;
      this.logoDataUrl = null;
      this.$refs.form.resetValidation();
    },
    async submit() {
      try {
        this.saveStart();

        let res;
        if (this.id) {
          res = await this.editClient({
            id: this.id,
            form: {
              ...this.form,
            }
          });

          if (this.logo) {
            await this.uploadClientLogo({ id: this.id, logo: this.logo });
          }
        } else {
          res = await this.createClient({ ...this.form });

          if (this.logo) {
            await this.uploadClientLogo({ id: res.ID, logo: this.logo });
          }

          if (this.afterCreated) {
            await this.afterCreated(res);
          }
        }

        this.saveSuccess();
        this.$emit('success', res);
        this.reset();
      } catch (e) {
        this.saveError(e);
      }
    }
  },
}
</script>

<style lang="sass">
.client-form .client-logo
  width: 100px
  height: 100px
</style>
