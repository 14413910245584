<template>
  <div>
    <div v-for="c in collections" :key="`collection-${c.ID}`" class="collection-wrapper">
      <h1 :id="c.ID" class="d-flex align-center justify-space-between">
        {{ c.Title }}
        <v-menu v-if="!$isMobile" bottom left>
          <template #activator="{ on }">
            <v-btn
              icon
              class="dots-menu"
              height="24pt"
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="showRenameCollectionDialog(c)">
              <v-list-item-title>{{ $t('projects.rename_collection.title') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="showCollectionDeleteDialog(c)">
              <v-list-item-title>{{ $t('projects.delete_collection.title') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </h1>

      <v-data-table
        class="collection-table gray-text complex-table"
        item-key="ID"
        :headers="headers"
        :items="c.Briefings"
        :items-per-page="5"
        :mobile-breakpoint="0"
        :footer-props="$TABLE_FOOTER_PROPS"
      >
        <template #item.Title="{ item }">
          <router-link class="table-emphasized" :to="{ name: 'brief_canvas', params: { uuid: item.Uuid } }">
            {{ item.Title }}
          </router-link>
        </template>
        <template #item.action="{ item }">
          <div class="actions">
            <router-link
              v-if="!$isMobile"
              class="icon-link"
              :to="{ name: 'briefing_edit', params: { uuid: item.Uuid } }"
              :title="$t('generic.edit')"
            >
              <v-icon size="18px" :title="$t('generic.edit')" class="mr-1">
                mdi-pencil-outline
              </v-icon>
            </router-link>
            <v-icon
              v-if="!$isMobile"
              size="16px"
              :title="$t('generic.rename')"
              class="mr-1"
              @click="showEditBriefingNameDialog(item)"
            >
              mdi-cursor-text
            </v-icon>
            <v-icon
              v-if="!$isMobile"
              size="16px"
              :title="$t('generic.copy')"
              class="mr-1"
              @click="openCloneDialog(c, item)"
            >
              mdi-content-copy
            </v-icon>
            <v-icon
              size="16px"
              :title="$t('projects.copy_link.icon_text')"
              class="mr-1"
              @click="doCopy(item.Uuid)"
            >
              mdi-share-variant
            </v-icon>
            <v-icon
              v-if="!$isMobile"
              size="20px"
              :title="$t('generic.delete')"
              @click="openDeleteDialog(item, c)"
            >
              mdi-delete-outline
            </v-icon>
          </div>
        </template>
      </v-data-table>

      <div v-if="!$isMobile" class="d-flex justify-end new-briefing-btn">
        <button-l emphasize :to="{ name: 'new_briefing_setup', query: { collection: c.ID } }">
          {{ $t('projects.labels.new_briefing') }}
        </button-l>
      </div>
    </div>

    <floating-action-btn v-if="!$isMobile" icon="mdi-plus">
      <floating-menu-item color="main" @click="toNewBriefing">
        {{ $t('projects.labels.new_briefing') }}
      </floating-menu-item>
      <floating-menu-item color="main" @click="showNewCollectionDialog">
        {{ $t('projects.new_collection.title') }}
      </floating-menu-item>
    </floating-action-btn>

    <v-dialog v-model="newCollection.dialog" max-width="720px" persistent>
      <dialog-content :title="$t('projects.new_collection.title')">
        <v-form ref="newCollectionForm" v-model="newCollection.valid" @submit.prevent="submitNewCollectionDialog">
          <v-text-field
            v-model.trim="newCollection.form.Name"
            :rules="newCollection.validation.Name"
            :label="$t('projects.new_collection.name')"
            required
          />
        </v-form>

        <template #actions>
          <button-xl @click.prevent="dismissNewCollectionDialog">
            {{ $t('generic.cancel') }}
          </button-xl>
          <button-stack
            size="xl"
            style="max-width: 250px"
            :disabled="!newCollection.valid"
            @click.prevent="submitNewCollectionDialogAndForward"
          >
            <template #secondary>
              {{ $t('projects.new_collection.submit_and_brief') }}
            </template>

            <button-xl
              emphasize
              :disabled="!newCollection.valid"
              @click.prevent="submitNewCollectionDialog"
            >
              {{ $t('generic.save') }}
            </button-xl>
          </button-stack>
        </template>
      </dialog-content>
    </v-dialog>

    <v-dialog v-model="cloneBriefing.dialog" max-width="720px">
      <dialog-content :title="$t('projects.clone_briefing.title')">
        <template #default>
          <p v-if="cloneBriefing.briefing">
            {{ $t('projects.clone_briefing.text', [ cloneBriefing.briefing.Title ]) }}
          </p>
        </template>
        <template #actions>
          <button-xl @click="dismissCloneBriefing">
            {{ $t('generic.cancel') }}
          </button-xl>
          <button-xl emphasize @click="submitCloneBriefing">
            {{ $t('generic.ok') }}
          </button-xl>
        </template>
      </dialog-content>
    </v-dialog>

    <v-dialog v-model="deleteBriefing.dialog" max-width="720px" persistent>
      <dialog-content :title="$t('projects.delete_briefing.title')">
        <template #default>
          <p>{{ $t('projects.delete_briefing.text', [ deleteBriefing.briefing ? deleteBriefing.briefing.Title : '' ]) }}</p>
        </template>
        <template #actions>
          <button-xl @click="dismissDeleteDialog">
            {{ $t('generic.cancel') }}
          </button-xl>
          <button-xl emphasize @click="submitDeleteDialog">
            {{ $t('generic.delete') }}
          </button-xl>
        </template>
      </dialog-content>
    </v-dialog>

    <v-dialog v-model="deleteCollection.dialog" max-width="720px" persistent>
      <dialog-content :title="$t('projects.delete_collection.title')">
        <template #default>
          <p>{{ $t('projects.delete_collection.text', [ deleteCollection.collection.Title ]) }}</p>
          <p><strong>{{ $t('projects.delete_collection.confirm') }}</strong></p>
          <v-form ref="deleteCollectionForm" v-model="deleteCollection.valid">
            <v-text-field
              v-model="deleteCollection.confirm"
              :label="$t('projects.delete_collection.input')"
              :rules="deleteValidation"
              hide-details="auto"
              required
            />
          </v-form>
        </template>
        <template #actions>
          <button-xl @click="dismissCollectionDeleteDialog">
            {{ $t('generic.cancel') }}
          </button-xl>
          <button-xl emphasize :disabled="!deleteCollection.valid" @click="submitCollectionDeleteDialog">
            {{ $t('generic.delete') }}
          </button-xl>
        </template>
      </dialog-content>
    </v-dialog>

    <v-dialog v-model="editBriefingName.dialog" max-width="720px" persistent>
      <dialog-content :title="$t('projects.edit_name.title')">
        <template #default>
          <v-form ref="editNameForm" v-model="editBriefingName.valid" @submit.prevent="submitEditBriefingNameDialog">
            <v-text-field
              v-model.trim="editBriefingName.form.Name"
              :rules="editBriefingName.validation.Name"
              :label="$t('projects.edit_name.name')"
              required
            />
          </v-form>
        </template>
        <template #actions>
          <button-xl @click.prevent="dismissEditBriefingNameDialog">
            {{ $t('generic.cancel') }}
          </button-xl>
          <button-xl
            emphasize
            :disabled="!editBriefingName.valid"
            @click.prevent="submitEditBriefingNameDialog"
          >
            {{ $t('generic.save') }}
          </button-xl>
        </template>
      </dialog-content>
    </v-dialog>

    <v-dialog v-model="renameCollection.dialog" max-width="720px" persistent>
      <dialog-content :title="$t('projects.rename_collection.title')">
        <template #default>
          <v-form ref="renameCollectionForm" v-model="renameCollection.valid" @submit.prevent="submitRenameCollectionDialog">
            <v-text-field
              v-model.trim="renameCollection.form.Name"
              :rules="renameCollection.validation.Name"
              :label="$t('projects.rename_collection.name')"
              required
            />
          </v-form>
        </template>
        <template #actions>
          <button-xl @click.prevent="dismissRenameCollectionDialog">
            {{ $t('generic.cancel') }}
          </button-xl>
          <button-xl
            emphasize
            :disabled="!renameCollection.valid"
            @click.prevent="submitRenameCollectionDialog"
          >
            {{ $t('generic.save') }}
          </button-xl>
        </template>
      </dialog-content>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { parseISO } from 'date-fns';
import i18n from '@/i18n';
import { required } from '@/util/validation';
import {
  getProjectCollections,
  deleteSubmittedBriefingForm,
  cloneBriefing,
  updateSubmittedBriefingForm,
  createProjectCollection,
  updateProjectCollection,
  deleteProjectCollection
} from '@/api'
import { NEW_BRIEFING_SETUP, BRIEF_CANVAS } from '@/routes';
import FloatingActionBtn from '@/components/FloatingActionBtn.vue';
import FloatingMenuItem from '@/components/global/FloatingMenuItem.vue';

export default {
  name: 'Projects',
  components: {
    FloatingActionBtn,
    FloatingMenuItem,
  },
  data: () => ({
    showNewDial: false,
    briefingOptionsDialog: false,
    deleteValidation: [ (val) => val?.toUpperCase() === 'DELETE' ],
    newCollection: {
      dialog: false,
      valid: false,
      form: {
        Name: null
      },
      validation: {
        Name: [ required(i18n.t('projects.new_collection.name')) ]
      },
    },
    cloneBriefing: {
      dialog: false,
      briefing: null,
      collection: null,
    },
    deleteBriefing: {
      dialog: false,
      briefing: null,
      collection: null,
    },
    deleteCollection: {
      dialog: false,
      collection: null,
      valid: false,
      confirm: null,
    },
    editBriefingName: {
      dialog: false,
      valid: false,
      briefing: null,
      form: {
        Name: null
      },
      validation: {
        Name: [ required(i18n.t('projects.edit_name.name')) ]
      },
    },
    renameCollection: {
      dialog: false,
      valid: false,
      collection: null,
      form: {
        Name: null
      },
      validation: {
        Name: [ required(i18n.t('projects.edit_name.name')) ]
      },
    },
    collections: null,
  }),
  computed: {
    ...mapState(['userId']),
    headers() {
      const titleCol = { text: this.$t('projects.labels.name'), value: 'Title' };
      const dateCol = { text: this.$t('projects.labels.date'), width: '97px', value: 'Created' };
      const actionCol = { text: '', value: 'action', align: 'right', width: '160px', sortable: false };
      if (this.$isMobile) {
        return [ titleCol, actionCol ];
      } else {
        return [ titleCol, dateCol, actionCol ];
      }
    }
  },
  async created() {
    try {
      this.loadStart();
      const collections = await getProjectCollections(this.userId);

      this.collections = collections.map(c => ({
        ID: c.ID,
        Title: c.Title,
        Briefings: c.Briefings?.map((b) => this.mapBriefing(b)) || []
      }));
      this.loadSuccess();
    } catch (e) {
      this.loadError(e);
    }
  },
  methods: {
    ...mapActions(['saveSuccess', 'showSnackbar']),
    ...mapMutations([ 'saveStart', 'saveError', 'loadStart', 'loadSuccess', 'loadError' ]),
    toNewBriefing() {
      this.$router.push({ name: NEW_BRIEFING_SETUP });
    },
    showNewCollectionDialog() {
      this.newCollection.dialog = true;
    },
    dismissNewCollectionDialog() {
      this.newCollection.dialog = false;
      this.newCollection.form.Name = null;
      this.$refs.newCollectionForm.resetValidation();
    },
    async submitNewCollectionDialogHelper(successCallback) {
      try {
        this.saveStart();
        const { form: { Name } } = this.newCollection;
        const res = await createProjectCollection({ Title: Name, User: this.userId });
        this.collections.push(res);
        this.dismissNewCollectionDialog();
        this.saveSuccess();

        if (successCallback) {
          successCallback(res.ID);
        }
      } catch (e) {
        this.saveError(e);
      }
    },
    submitNewCollectionDialog() {
      this.submitNewCollectionDialogHelper();
    },
    submitNewCollectionDialogAndForward() {
      this.submitNewCollectionDialogHelper((collectionId) => {
        this.$router.push({ name: NEW_BRIEFING_SETUP, query: { collection: collectionId } });
      });
    },
    showEditBriefingNameDialog(briefing) {
      this.editBriefingName.dialog = true;
      this.editBriefingName.briefing = briefing;
      this.editBriefingName.form.Name = briefing.Title;
    },
    dismissEditBriefingNameDialog() {
      this.editBriefingName.dialog = false;
      this.editBriefingName.form.Name = null;
      this.editBriefingName.briefing = null;
      this.$refs.editNameForm.resetValidation();
    },
    async submitEditBriefingNameDialog() {
      try {
        this.saveStart();
        const { briefing, form: { Name } } = this.editBriefingName;
        await updateSubmittedBriefingForm(briefing.ID, { Title: Name });
        briefing.Title = Name;
        this.dismissEditBriefingNameDialog();
        this.saveSuccess();
      } catch (e) {
        this.saveError(e);
      }
    },
    showRenameCollectionDialog(collection) {
      this.renameCollection.dialog = true;
      this.renameCollection.collection = collection;
      this.renameCollection.form.Name = collection.Title;
    },
    dismissRenameCollectionDialog() {
      this.renameCollection.dialog = false;
      this.renameCollection.form.Name = null;
      this.renameCollection.collection = null;
      this.$refs.renameCollectionForm.resetValidation();
    },
    async submitRenameCollectionDialog() {
      try {
        this.saveStart();
        const { collection, form: { Name } } = this.renameCollection;
        await updateProjectCollection(collection.ID, { Title: Name });
        collection.Title = Name;
        this.dismissRenameCollectionDialog();
        this.saveSuccess();
      } catch (e) {
        this.saveError(e);
      }
    },
    openCloneDialog(collection, briefing) {
      this.cloneBriefing.dialog = true;
      this.cloneBriefing.collection = collection;
      this.cloneBriefing.briefing = briefing;
    },
    async submitCloneBriefing() {
      try {
        this.saveStart();
        const res = await cloneBriefing(this.userId, this.cloneBriefing.briefing.ID);
        this.cloneBriefing.collection.Briefings.push(this.mapBriefing(res));
        this.dismissCloneBriefing();
        this.saveSuccess();
      } catch (e) {
        this.saveError(e);
      }
    },
    dismissCloneBriefing() {
      this.cloneBriefing.dialog = false;
      this.cloneBriefing.briefing = null;
      this.cloneBriefing.collection = null;
    },
    openDeleteDialog(briefing, collection) {
      this.deleteBriefing.dialog = true;
      this.deleteBriefing.briefing = briefing;
      this.deleteBriefing.collection = collection;
    },
    async submitDeleteDialog() {
      try {
        this.saveStart();
        const { briefing, collection } = this.deleteBriefing;
        const { ID } = briefing;
        await deleteSubmittedBriefingForm(ID);
        collection.Briefings = collection.Briefings.filter(b => b != briefing);
        this.dismissDeleteDialog();
        this.saveSuccess();
      } catch (e) {
        this.saveError(e);
      }
    },
    dismissDeleteDialog() {
      this.deleteBriefing.dialog = false;
      this.deleteBriefing.briefing = null;
      this.deleteBriefing.collection = null;
    },
    showCollectionDeleteDialog(collection) {
      this.deleteCollection.dialog = true;
      this.deleteCollection.collection = collection;
    },
    async submitCollectionDeleteDialog() {
      try {
        this.saveStart();
        const { collection } = this.deleteCollection;
        await deleteProjectCollection(collection.ID);
        this.collections = this.collections.filter(c => c != collection);
        this.dismissCollectionDeleteDialog();
        this.saveSuccess();
      } catch (e) {
        this.saveError(e);
      }
    },
    dismissCollectionDeleteDialog() {
      this.deleteCollection.dialog = false;
      this.deleteCollection.collection = null;
      this.deleteCollection.confirm = null;
      this.$refs.deleteCollectionForm.resetValidation();
    },
    async doCopy(uuid) {
      const { href } = this.$router.resolve({ name: BRIEF_CANVAS, params: { uuid } }, this.$router.options.base, true);

      if (navigator.share) {
        // native share on Android/iOS
        try {
          await navigator.share({
            title: 'Share briefing',
            url: href
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      } else {
        try {
          await this.$copyText(`${window.location.origin}${href}`);
          this.showSnackbar(this.$t('projects.copy_link.notification')); 
        } catch (e) {
          this.loadError(e);
        }
      }
    },
    mapBriefing(b) {
      return {
        ID: b.ID,
        Title: b.Title,
        Created: this.$formatDate(parseISO(b.Created), 'P'),
        Uuid: b.Uuid
      };
    }
  }
}
</script>

<style lang="sass">
.v-btn.dots-menu
  padding: 0 !important
  margin-right: -19px
  height: $line-height-h1 !important
</style>
