import { VSwitch } from 'vuetify/lib';

export default (VSwitch as any).extend({
  name: 'BriefingSwitch',
  methods: {
    genDefaultSlot(): any {
      return [
        (this as any).genLabel(),
        (this as any).genSwitch(),
      ]
    },
  }
});
