<template>
  <div class="success-screen">
    <h1>{{ title }}</h1>

    <slot />

    <div v-for="bg in buttonGroups" :key="bg.title" class="buttongroup">
      <h2>{{ bg.title }}</h2>
      <button-row size="xl">
        <button-xl
          v-for="(b, i) in bg.buttons.filter(b => !($isMobile && b.hideOnMobile))"
          :key="i"
          :to="b.to"
          :emphasize="b.emphasize"
        >
          {{ b.title }}
        </button-xl>
      </button-row>
    </div>
  </div>
</template>

<script>
export class SuccessButton {
  /**
   * @param {string} title
   * @param {Object} to
   */
  constructor(title, to, emphasize, hideOnMobile=false) {
    this.title = title;
    this.to = to;
    this.emphasize = emphasize;
    this.hideOnMobile = hideOnMobile;
  }
}

export class SuccessButtonGroup {
  /**
   * @param {string} title
   * @param {SuccessButton[]} buttons
   */
  constructor(title, buttons) {
    this.title = title;
    this.buttons = buttons;
  }
}

export default {
  name: 'SuccessScreen',
  props: {
    title: {
      type: String,
      required: true,
    },
    buttonGroups: {
      type: Array,
      default: () => [],
    }
  }
}
</script>

<style lang="sass">
.success-screen
  p
    font-size: 24px

  .buttongroup
    margin-top: 30px

    h2
      @include normal-text
      font-weight: $font-bold
      margin-bottom: $btn-xl-margin

  .theme--light.v-btn--active::before
    opacity: 0
</style>
