











import { Component } from 'vue-property-decorator';
import ElementBase from './ElementBase';
import CardList from '@/components/common/CardList.vue';
import { participantTable, field, PdfDisplayable } from '@/pdf/components';

@Component({
  components: {
    CardList,
  }
})
export default class Participants extends ElementBase implements PdfDisplayable {
  /*
   * computed
   */
  get participants() {
    return this.value.SubmittedData?.Participants || [];
  }

  /*
   * methods
   */
  renderPdfContent() {
    if (this.participants.length === 0) {
      return undefined;
    }

    return field([
      participantTable(this.participants),
    ]);
  }
}
