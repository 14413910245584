import { parseISO } from 'date-fns';
import { isBlank } from '@/util/data';
import { queryPublicSBF, getTemplate, getBriefingData, getBriefings } from '@/api';
import store from '@/store';
import { BRIEFING_MUTATION_KEYS, commitKey } from '@/store/interfaces/BriefingMutations';

export const colors = {
  strategic: "#86cedc",
  creative: "#f04568",
  operative: "#ffcc00"
};

export const fieldTypes = {
  BriefingFieldSet: 'BriefingFieldSet',
  BriefingTextField: 'BriefingTextField',
  BriefingEmailField: 'BriefingEmailField',
  BriefingDateField: 'BriefingDateField',
  BriefingRadioField: 'BriefingRadioField',
  BriefingDropdown: 'BriefingDropdown',
  BriefingSidebysideSelect: 'BriefingSidebysideSelect',
  BriefingPeopleDropdown: 'BriefingPeopleDropdown',
  BriefingCheckbox: 'BriefingCheckbox',
  BriefingFormHeading: 'BriefingFormHeading',
  BriefingLiteralField: 'BriefingLiteralField',
  BriefingFileField: 'BriefingFileField',
  BriefingMilestoneField: 'BriefingMilestoneField',
  BriefingYesNoRadioField: 'BriefingYesNoRadioField',
  BriefingBudgetField: 'BriefingBudgetField',
  BriefingClientField: 'BriefingClientField',
  BriefingTilesField: 'BriefingTilesField',
  BriefingMatrixChartField: 'BriefingMatrixChartField',
  BriefingSpiderChartField: 'BriefingSpiderChartField',
};

const conditionTypes = {
  IsBlank: 'IsBlank',
  IsNotBlank: 'IsNotBlank',
  HasValue: 'HasValue',
  ValueNot: 'ValueNot',
  ValueLessThan: 'ValueLessThan',
  ValueLessThanEqual: 'ValueLessThanEqual',
  ValueGreaterThan: 'ValueGreaterThan',
  ValueGreaterThanEqual: 'ValueGreaterThanEqual',
};

export const isOptionFieldType = (t) => {
  return t === fieldTypes.BriefingRadioField || t === fieldTypes.BriefingDropdown || t === fieldTypes.BriefingSidebysideSelect || t === fieldTypes.BriefingTilesField;
};

/**
 * Sort form items (form steps, form fields in a step) using the `Sort` field of the item descriptor.
 * Items which has no `Sort` field are moved to the end of the collection.
 */
export const sortFormData = (a, b) => {
  if (b.Sort === undefined) {
    return +1;
  }

  if (a.Sort === undefined) {
    return -1;
  }

  return a.Sort - b.Sort;
};

export const isSubmittedOf = (submittedField, templateField) => {
  const relatedField = (submittedField.RelatedField || submittedField.RelatedFieldID).toString();

  return relatedField === templateField.ID.toString();
};

export class DisplayRule {
  constructor(rule) {
    this.hide = rule.Display === 'Hide';
    /** @type {string} */
    this.condition = rule.ConditionOption;

    switch(rule.ConditionOption) {
      case conditionTypes.ValueLessThan:
      case conditionTypes.ValueLessThanEqual:
      case conditionTypes.ValueGreaterThan:
      case conditionTypes.ValueGreaterThanEqual:
        this.value = Number(rule.FieldValue);
      break;
      default:
        this.value = rule.FieldValue?.toString()?.toLowerCase();
    }

    /** @type {number} */
    this.targetField = rule.Parent;
  }
}

export class Option {
  constructor(res) {
    /** @type {string} */
    this.text = res.Title;
    /** @type {string} */
    this.value = res.ID.toString();
    /** @type {string} */
    this.matchValue = res.Value;

    if (res.Tile) {
      this.Tile = res.Tile;
    }
  }
}

export class SubmittedField {
  constructor(res) {
    Object.entries(res).forEach(([key, value]) => this[key] = value);
  }
}

export class BriefingField {
  /** @type {number} */
  ID = undefined;
  /** @type {Option[]} */
  Tags = [];
  /** @type {Option[]} */
  Options = [];
  /** @type {DisplayRule[]} */
  Rules = [];
  /** @type {SubmittedField} */
  SubmittedData = null;

  constructor(res) {
    Object.entries(res).forEach(([key, value]) => this[key] = value);
  }
}

export class BriefingFieldGroup {
  isVisible = false;

  constructor(res) {
    /** @type {number} */
    this.ID = res.ID;
    /** @type {string} */
    this.Title = res.Title;
    /** @type {string} */
    this.Sort = res.Sort;
    /** @type {string} */
    this.Description = res.HTML;

    /** @type {BriefingField[]} */
    this.Fields = res.Fields
      // fieldsets aren't used anymore
      // TODO: remove filtering when there're no such fields on the backend
      .filter(field => field.FieldType != fieldTypes.BriefingFieldSet)
      .sort(sortFormData)
      .map((x) => new BriefingField(x));
  }
}

export class BriefingStep {
  constructor(res) {
    /** @type {number} */
    this.ID = res.ID;
    /** @type {string} */
    this.Title = res.Title;
    /** @type {string} */
    this.Sort = res.Sort;

    /** @type {BriefingFieldGroup[]} */
    this.Groups = res.Groups
      .sort(sortFormData)
      .map(x => new BriefingFieldGroup(x));
  }

  get allFields() {
    return this.Groups
      .flatMap(group => group.Fields);
  }
}

export class BriefingData {
  constructor(briefingType, projectType, briefingData) {
    /** @type {string} */
    this.Title = briefingData.Title;

    this.BriefingType = briefingType;
    this.ProjectType = projectType;

    this.Submitter = {
      /** @type {number} */
      ID: briefingData.SubmittedBy,
      /** @type {string} */
      Name: briefingData.SubmitterName,
    };

    /** @type {string} */
    this.Version = briefingData.Version;
    /** @type {string} */
    this.Uuid = briefingData.Uuid;

    /** @type {Date} */
    this.Created = parseISO(briefingData.Created);
    /** @type {Date} */
    this.LastEdited = parseISO(briefingData.LastEdited);
  }
}

export class BriefingFormData extends BriefingData {
  constructor(briefingType, projectType, briefingSteps, briefingData) {
    super(briefingType, projectType, briefingData);

    /** @type {number} */
    this.ID = briefingData.ID;

    /** @type {BriefingStep[]} */
    this.Steps = briefingSteps
      .sort(sortFormData)
      .map(x => new BriefingStep(x));

    this.allFields.forEach(field => {
      field.SubmittedData = briefingData.Values.find(sub => isSubmittedOf(sub, field));
    });
  }

  /**
   * @returns {BriefingField[]}
   */
  get allFields() {
    return this.Steps
      .flatMap(step => step.allFields);
  }
}

export class CanvasData extends BriefingData {
  /**
   * 
   * @param {*} briefingType 
   * @param {*} projectType 
   * @param {*} submittedFormRoot 
   * @param {BriefingFormData} briefingFormData 
   */
  constructor(briefingType, projectType, briefingData, steps) {
    super(briefingType, projectType, briefingData);

    /** @type {BriefingStep[]} */
    this.Steps = steps
      .sort(sortFormData)
      .map(x => new BriefingStep(x));

    const submittedFields = briefingData.Values;

    this.Groups.forEach(g => {
      g.Fields = g.Fields
        .map(field => ({
          ...field,
          SubmittedData: submittedFields.find(sub => isSubmittedOf(sub, field)),
        }))
        .filter(f => f.NotOnCanvas !== 1)
        .filter(f => !!f.SubmittedData);
    });

    this.Steps.forEach(step => {
      step.Groups = step.Groups.filter(g => g.Fields.length > 0);
    });

    this.Steps = this.Steps.filter(step => step.Groups.length > 0);
  }

  /**
   * @returns {BriefingFieldGroup[]}
   */
  get Groups() {
    return this.Steps.flatMap(
      step => step.Groups
    );
  }

  /**
   * @returns {BriefingField[]}
   */
  get allFields() {
    return this.Groups.flatMap(g => g.Fields);
  }
}

export const initialVisibilities = (displayRuledFields) => {
  const results = {};

  displayRuledFields.forEach(field => {
    const initial = field.ShowOnLoad > 0;
    results[field.ID] = {
      and: field.DisplayRulesConjunction === 'And',
      initial,
      current: initial,
      results: {}
    };
  });

  return results;
};

export const createDisplayRules = (rules) => rules
  .reduce((res, rule) => {
    if (!res[rule.ConditionField]) {
      res[rule.ConditionField] = [];
    }

    res[rule.ConditionField].push(new DisplayRule(rule));

    return res;
  }, {});

const andRules = (results) => Object.values(results)
  .reduce((res, curr) => res && curr, true);

const orRules = (results) => Object.values(results)
  .reduce((res, curr) => res || curr, false);

const calculateVisibilityFromRuleResults = (visibility) => {
  if (visibility.and) {
    return andRules(visibility.results);
  } else {
    return orRules(visibility.results);
  }
};

export const updateVisibility = (fieldId, newValue, displayRules, visibilities) => {
  const rules = displayRules[fieldId];

  if (rules) {
    rules.forEach(rule => {
      let result;
      switch (rule.condition) {
        case conditionTypes.IsBlank:
          result = isBlank(newValue);
        break;
        case conditionTypes.IsNotBlank:
          result = !isBlank(newValue);
        break;
        case conditionTypes.HasValue:
          if (Array.isArray(newValue)) {
            result = newValue.some(v => v?.toString()?.toLowerCase() === rule.value);
          } else {
            result = newValue?.toString().toLowerCase() === rule.value;
          }
        break;
        case conditionTypes.ValueNot:
          if (Array.isArray(newValue)) {
            result = ! newValue.some(v => v?.toString().toLowerCase() === rule.value);
          } else {
            result = newValue?.toString().toLowerCase() !== rule.value;
          }
        break;
        case conditionTypes.ValueLessThan:
          result = newValue ? Number(newValue) < rule.value : false;
        break;
        case conditionTypes.ValueLessThanEqual:
          result = newValue ? Number(newValue) <= rule.value : false;
        break;
        case conditionTypes.ValueGreaterThan:
          result = newValue ? Number(newValue) > rule.value : false;
        break;
        case conditionTypes.ValueGreaterThanEqual:
          result = newValue ? Number(newValue) >= rule.value : false;
        break;
        default:
          throw new Error(`Unknoknw displayrule condition type: ${rule.condition}`);
      }

      if (rule.hide) {
        result = !result;
      }

      const v = visibilities[rule.targetField];
      v.results[fieldId] = result;

      v.current = calculateVisibilityFromRuleResults(v);
    });
  }
};

/**
 * @param {BriefingField[]} fieldTemplates
 */
export const addOptionsToFields = (fieldTemplates) => {
  const optionFields = fieldTemplates
    .filter(field => isOptionFieldType(field.FieldType));

  const normalOptionFields = optionFields.filter(field => !field.Model);

  // add the queried options and useroptions to the fields
  normalOptionFields.forEach((field) => {
    if (!field.Options) {
      throw new Error(`Missing options for field #${field.ID} ('${field.Title}')`);
    }

    let options;
    const useroptions = field.SubmittedData?.UserOptions;
    if (useroptions) {
      options = [
        ...field.Options.sort(sortFormData),
        ...useroptions.sort(sortFormData)
      ];
    } else {
      options = field.Options.sort(sortFormData);
    }

    field.Options = options.map(o => new Option(o));
  });
};

/**
 * 
 * @param {BriefingField[]} fieldTemplates 
 */
export const addBriefings = async (briefings, fieldTemplates) => {
  fieldTemplates.forEach(field => {
    if (field.Model !== undefined) {
      field.Briefings = briefings
        .filter(m => field.Model === m.BriefingType);
      
      field.Options = field.Briefings.map(m => ({
          text: m.Title,
          value: m.ID?.toString(),
        }));
    }
  });
};

const getParticipantData = (briefingData, steps) => {
  const result = steps.flatMap(s => s.Groups.flatMap(g => g.Fields))
    .filter(f => f.FieldType === fieldTypes.BriefingPeopleDropdown);
  
  if (result.length <= 0) {
    throw new Error('No participant field in response!');
  }

  const participantsField = result[0];
  const participantsSbf = briefingData.Values.find(sub => isSubmittedOf(sub, participantsField));

  return {
    participantsField,
    participantsSbf,
  };
}

export const queryPublicBriefing = async (uuid) => {
  store.commit(commitKey(BRIEFING_MUTATION_KEYS.clear));

  const briefingData = await queryPublicSBF(uuid);

  const {
    BriefingType,
    ProjectType,
    Steps,
  } = await getTemplate(briefingData.ProjectType);

  BriefingType.Color = colors[BriefingType.BriefingType];

  const canvasData = new CanvasData(BriefingType, ProjectType, briefingData, Steps);

  const {
    participantsField,
    participantsSbf,
  } = getParticipantData(briefingData, Steps);

  store.commit(commitKey(BRIEFING_MUTATION_KEYS.initialize), {
    uuid: canvasData.Uuid,
    briefingType: canvasData.BriefingType,
    participantsField,
    participants: participantsSbf?.Participants || [],
  });

  return canvasData;
};

export const queryPrivateBriefing = async (uuid) => {
  store.commit(commitKey(BRIEFING_MUTATION_KEYS.clear));

  const participantsPromise = store.dispatch('fetchParticipants');
  const clientsPromise = store.dispatch('fetchClients');
  const briefingsPromise = getBriefings();

  const briefingData = await getBriefingData(uuid);

  const {
    BriefingType,
    ProjectType,
    Steps,
  } = await getTemplate(briefingData.ProjectType);

  BriefingType.Color = colors[BriefingType.BriefingType];

  const canvasData = new CanvasData(BriefingType, ProjectType, briefingData, Steps);

  const fields = canvasData.allFields;
  addOptionsToFields(fields);
  
  const [briefings,] = await Promise.all([ briefingsPromise, participantsPromise, clientsPromise ]);
  addBriefings(briefings, fields);

  const {
    participantsField,
    participantsSbf,
  } = getParticipantData(briefingData, Steps);

  store.commit(commitKey(BRIEFING_MUTATION_KEYS.initialize), {
    uuid: canvasData.Uuid,
    briefingType: canvasData.BriefingType,
    participantsField,
    participants: participantsSbf?.Participants || [],
  });

  return canvasData;
};

export const queryFormModelHierarchy = async (uuid) => {
  store.commit(commitKey(BRIEFING_MUTATION_KEYS.clear));

  const participantsPromise = store.dispatch('fetchParticipants');
  const clientsPromise = store.dispatch('fetchClients');
  const briefingsPromise = getBriefings();

  const briefingData = await getBriefingData(uuid);

  const {
    BriefingType,
    ProjectType,
    Steps,
  } = await getTemplate(briefingData.ProjectType);

  BriefingType.Color = colors[BriefingType.BriefingType];

  const form = new BriefingFormData(BriefingType, ProjectType, Steps, briefingData);

  const fields = form.allFields;
  addOptionsToFields(fields);

  const [briefings,] = await Promise.all([ briefingsPromise, participantsPromise, clientsPromise ]);
  addBriefings(briefings, fields);

  const {
    participantsField,
    participantsSbf,
  } = getParticipantData(briefingData, Steps);

  store.commit(commitKey(BRIEFING_MUTATION_KEYS.initialize), {
    uuid: form.Uuid,
    sbfId: form.ID,
    briefingType: form.BriefingType,
    participantsField,
    participantsSbf,
    participants: participantsSbf?.Participants || [],
  });

  return form;
};

export const getBriefingTypeOf = async (uuid) => {
  const briefingData = await getBriefingData(uuid);

  const { BriefingType } = await getTemplate(briefingData.ProjectType);
  BriefingType.Color = colors[BriefingType.BriefingType];

  return BriefingType;
};
