<template>
  <v-text-field
    v-model="data"
    class="briefing-field"
    :label="value.Title"
    :placeholder="value.Placeholder"
    :color="color"
    :rules="validation"
    :loading="isSaving"
    :disabled="isSaving"
    :hint="value.RightTitle"
    persistent-hint
    @change="onChange"
  />
</template>

<script>
import { fieldTypes } from '@/model/brief';
import { email } from '@/util/validation';
import FieldBase from './FieldBase';
import InputFieldBase from './InputFieldBase';

export default {
  name: 'TextField',
  mixins: [ FieldBase, InputFieldBase ],
  created() {
    if (this.value.FieldType === fieldTypes.BriefingEmailField) {
      this.validation.push(email(this.value.Title));
    }

    this.update();
  },
  methods: {
    update() {
      this.onUpdated({
        id: this.value.ID,
        value: this.data,
      });
    },
    async onChange() {
      try {
        this.isSaving = true;
        await this.submit(this.data);
        this.update();
      } catch (e) {
        this.setError(e);
      }

      this.isSaving = false;
    }
  }
}
</script>
