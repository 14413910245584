




























import { Component } from 'vue-property-decorator';
import InputFieldBase from './InputFieldBase';

@Component
export default class TextareaField extends InputFieldBase {
  created() {
    this.update();
  }

  /*
   * methods
   */
  update() {
    this.onUpdated({
      id: this.value.ID,
      value: this.data,
    });
  }

  async onChange() {
    try {
      this.isSaving = true;
      await this.submit(this.data);
      this.update();
    } catch (e) {
      this.setError(e);
    }

    this.isSaving = false;
  }
}
