<template>
  <div class="spider-chart-field">
    <h2 v-if="value.Title" class="briefing-field-title">
      {{ value.Title }}
    </h2>

    <p
      v-if="value.FieldDescription"
      class="briefing-field-description"
      v-html="value.FieldDescription"
    />

    <div class="d-flex justify-center">
      <div class="spider-chart-wrapper">
        <spider-chart
          ref="chart"
          draggable
          :data="axes"
          :color="color"
          @change="onDragged"
        />
      </div>
    </div>

    <div>
      <v-row>
        <v-col v-for="(d, i) in axes" :key="i" cols="6">
          <v-combobox
            v-model="d.Title"
            :items="options"
            :loading="isSaving"
            :label="$t('create_briefing.spider_chart.existing_dimension')"
            hide-details
            :append-outer-icon="i > 2 ? 'mdi-delete-outline' : null"
            @change="changeDimensionLabel(i)"
            @click:append-outer="deleteDimension(i)"
          />
        </v-col>
      </v-row>
      <add-new-value
        v-if="axes.length < 8"
        :options="options"
        :loading="isSaving"
        :color="color"
        :label="$t('create_briefing.spider_chart.new_dimension')"
        @input="addDimension"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import {
  createSpiderChartField,
  createSpiderChartValue,
  deleteSpiderChartValue,
  editSpiderChartValue
} from '@/api';
import { sortFormData } from '@/model/brief';
import {
  convertSpiderChartValueFromAPI,
  SPIDER_CHART_MAX_VALUE,
  createNewSpiderChartValue
} from '@/model/charts';
import FieldBase from './FieldBase';
import AddNewValue from './AddNewValue.vue';
import SpiderChart from '@/components/common/SpiderChart.vue'

export default {
  name: 'SpiderChartField',
  components: {
    SpiderChart,
    AddNewValue,
  },
  mixins: [ FieldBase ],
  data: () => ({
    axes: [
      { Title: '', Value: 10, Sort: 1 },
      { Title: '', Value: 6, Sort: 2 },
      { Title: '', Value: 4, Sort: 3 },
    ],
  }),
  computed: {
    options() {
      return this.value.SpiderOptions
        ?.slice() // clone the array since sort is a destructive operation
        ?.sort(sortFormData)
        ?.map((o) => o.Title);
    }
  },
  created() {
    if (this.value.SubmittedData?.SpiderOptions?.length > 0) {
      this.axes = this.value.SubmittedData.SpiderOptions
        .sort(sortFormData)
        .map(convertSpiderChartValueFromAPI);
    }
  },
  methods: {
    ...mapMutations(['setError']),
    reRenderChart() {
      this.$refs.chart.reRender();
    },
    async onDragged({ index, value }) {
      this.axes[index].Value = value;
      await this.onDimensionChange(index);
      // chart is already updated, doesn't need rerender in this case!
    },
    async changeDimensionLabel(index) {
      await this.onDimensionChange(index);
      this.reRenderChart();
    },
    async onDimensionChange(index) {
      try {
        this.isSaving = true;
        const fieldId = await this.getSubmittedFieldId();

        // getSubmittedFieldId can change the axes array
        // if this is the first modification of the field
        // we need to get the point after calling it!
        const point = this.axes[index];

        await editSpiderChartValue(fieldId, point.ID, {
          ...point,
        });
      } catch (e) {
        this.setError(e);
      } finally {
        this.isSaving = false;
      }
    },
    async addDimension(label) {
      try {
        this.isSaving = true;
        const fieldId = await this.getSubmittedFieldId();
        const res = await createSpiderChartValue(fieldId, createNewSpiderChartValue(label, this.axes.length + 1));
        this.axes.push(convertSpiderChartValueFromAPI(res));
        this.reRenderChart();
      } catch (e) {
        this.setError(e);
      } finally {
        this.isSaving = false;
      }
    },
    async deleteDimension(i) {
      try {
        this.isSaving = true;
        const fieldId = await this.getSubmittedFieldId();
        await deleteSpiderChartValue(fieldId, this.axes[i].ID);
        this.axes.splice(i, 1);

        // fix Sort numbers
        this.axes
          .filter((_, index) => index >= i)
          .forEach((point) => {
            point.Sort -= 1;
            editSpiderChartValue(fieldId, point.ID, {
              ...point
            });
          });

        this.reRenderChart();
      } catch (e) {
        this.setError(e);
      } finally {
        this.isSaving = false;
      }
    },
    async getSubmittedFieldId() {
      if (!this.submittedId) {
        // force creation of the submitted field

        const { ID: fid, Parent, FieldType, Name, Sort, Title, } = this.value;

        let body = {
          ParentID: this.submittedFormId,
          ParentElementID: Parent,
          BriefingFieldClassName: FieldType,
          Name,
          Sort,
          Title,
          RelatedFieldID: fid
        };

        if (this.transformSubmitBody) {
          body = this.transformSubmitBody(body);
        }

        this.value.SubmittedData = await createSpiderChartField(body);
        this.submittedId = this.value.SubmittedData.ID;

        const results = await Promise.all(this.axes.map((point) => {
          return createSpiderChartValue(this.submittedId, {
            ...point
          });
        }));

        this.axes = results.map(convertSpiderChartValueFromAPI);
      }

      return this.submittedId;
    },
  }
}
</script>

<style lang="sass">
.spider-chart-field .col
  padding-top: 0
</style>
