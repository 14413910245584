<template>
  <div>
    <v-autocomplete
      v-model="data"
      class="briefing-field"
      :label="value.Title"
      :items="value.Options"
      :color="color"
      :rules="validation"
      :loading="isSaving"
      :disabled="isSaving"
      @change="onChange"
    />

    <add-user-option
      v-if="customOptionsAllowed"
      :color="color"
      :next-index="value.Options.length"
      :get-parent-id="getSubmittedFieldId"
      @option-added="onOptionAdded"
    />
  </div>
</template>

<script>
import FieldBase from './FieldBase';
import InputFieldBase from './InputFieldBase';
import OptionFieldBase from './OptionFieldBase';
import AddUserOption from './AddUserOption';

export default {
  components: {
    AddUserOption
  },
  mixins: [ FieldBase, InputFieldBase, OptionFieldBase ]
}
</script>
