


















import Vue from 'vue';
import Component from 'vue-class-component';
import { mapActions } from 'vuex';
import routes from '@/routes';
import { LOGIN } from '@/routes';
import LogoutBtn from '@/components/global/LogoutBtn.vue';
import Feedback from '@/components/layout/Feedback.vue';
import { StoreActions } from '@/store/interfaces';
import MainMenuItem from './MainMenuItem.vue';

const menuRoutes = routes
  .filter((r) => r.meta?.menuText)
  .sort((a, b) => a.meta.menuPosition - b.meta.menuPosition);

@Component({
  components: {
    LogoutBtn,
    Feedback,
    MainMenuItem,
  },
  methods: mapActions(['logout'])
})
export default class MainMenu extends Vue {
  /*
   * vuex
   */
  logout!: StoreActions.logout;

  get menu() {
    return menuRoutes
      .filter((r) => !this.$isMobile || r.meta?.mobile);
  }

  async onLogout() {
    try {
      this.logout();
      this.$router.push({ name: LOGIN });
    } catch (e) {
      alert(e.message);
    }
  }
}
