


















import Vue from 'vue';

export default Vue.component('ButtonStack', {
  name: 'ButtonStack',
  props: {
    size: {
      type: String,
      default: "l"
    },
    to: {
      type: Object,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    unimportant: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    buttonClasses(): string[] {
      const res = [ this.size ];

      if (this.unimportant) {
        res.push('unimportant');
      }

      return res;
    }
  }
});
