<script>
import { BriefingFieldGroup } from '@/model/brief';
import { fieldGroupTitle, fieldGroup } from '@/pdf/components';
import CanvasFragment from './CanvasFragment';

const generateRef = (id) => `field-${id}`;

export default {
  name: 'CanvasGroup',
  components: {
    CanvasFragment
  },
  props: {
    value: {
      type: BriefingFieldGroup,
      required: true,
    },
    briefingType: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async renderPdfContent() {
      const fields = await Promise.all(
        this.value.Fields
          .map(f => {
            const r = this.$refs[generateRef(f.ID)];
            return r.renderPdfContent();
          })
      );

      const filtered = fields.filter(value => !!value);

      if (filtered.length > 0) {
        return fieldGroup([
          fieldGroupTitle(this.value),
          ...fields.filter(value => !!value)
        ]);
      } else {
        return undefined;
      }
    }
  },
  render(h) {
    const fields = this.value.Fields
      .map(field => {
        return h(CanvasFragment, {
          props: {
            value: field,
            briefingType: this.briefingType,
          },
          ref: generateRef(field.ID)
        })
      });

    return h(
      'div',
      { class: 'canvas-fieldset', attrs: { 'data-group-id': this.value.ID } }, [
        h('h1', { attrs: { id: `group-${this.value.ID}` }, domProps: { innerHTML: this.value.Title } }),
        ...fields
      ]
    );
  },
}
</script>

<style lang="sass">
.canvas-fieldset > h1
  margin-bottom: $canvas-field-title-margin
  font-size: $header-font-size-m

  @media print
    page-break-after: avoid
</style>
