import axios, { CancelTokenSource, AxiosError, AxiosResponse } from 'axios';
import { requestLogger, responseLogger, errorLogger } from 'axios-logger';
import { fromUnixTime } from 'date-fns';
import i18n from './i18n';
import { SpiderChartFormValue, MatrixChartFormValue, MatrixChartAPIValue, SpiderChartAPIValue } from './model/charts';
import { getAuth, setAuth, clearAuth, refreshAuth } from './util/auth';
import { SubmittedFormFieldData, SubmittedData, BriefingType, TemplateStep, ProjectType } from './model/brief2';
import { ParticipantType, ParticipantDataType, BriefingParticipantType, BriefingRoleType } from './model/participant';
import { ClientType, ClientDataType } from './model/clients';
import { UserType, RegistrationDataType, UserEditType, FeedbackData } from './model/user';
import { Collection, SharedBriefing } from './model/collection';

export type NoAuthError = Readonly<{ isNoUserIdError: boolean, message: string }>;
export type MultilangError = Readonly<{ messageId: string }>;
export type ApiError = NoAuthError | AxiosError | MultilangError | { message: string };

export const isNoAuthError = (e: ApiError): e is NoAuthError => (e as any).isNoUserIdError;
export const isAxiosError = (e: ApiError): e is AxiosError => (e as any).isAxiosError;
export const isMultilangError = (e: ApiError): e is MultilangError => (e as any).messageId;

const handleApiError = (e: any): never => {
  if (e.response?.data) {
    throw e.response.data;
  }

  throw e;
};

const withErrorHandling = async <T = any>(prom: Promise<AxiosResponse<T>>) => {
  try {
    const res = await prom;
    return res.data;
  } catch (e) {
    console.error(e);
    return handleApiError(e);
  }
};

const api = axios.create({
  baseURL: '/api',
});

const controllerApi = axios.create({
  baseURL: '/rest',
});

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV != 'production') {
  // declare request/response interceptor for logging requests in dev/test mode
  api.interceptors.request.use(requestLogger, errorLogger);
  api.interceptors.response.use(responseLogger, errorLogger);
}

export default api;

export const login = async (email: string, pwd: string) => {
  const body = new FormData();

  body.set('email', email);
  body.set('pwd', pwd);

  const res = await api.post('/auth/login', body);

  const json = res.data;

  if (json.result) {
    setAuth(json.userID, json.token, fromUnixTime(json.expire));
    return json.userID;
  } else if (json.message) {
    throw new Error(json.message);
  } else {
    return undefined;
  }
};

export const logout = async () => {
  await controllerGet('/out');
  clearAuth();
};

export const register = async (user: RegistrationDataType) => {
  try {
    const res = await controllerApi.post('/createuser', user);
    return res.data;
  } catch (e) {
    if (e.response && e.response.data && e.response.data.Result) {
      switch (e.response.data.Result) {
        case 'ExistingEmail':
          throw new Error(i18n.t('registration.existing_email') as string);
        case 'NoEmailGiven':
          throw new Error(i18n.t('registration.no_email') as string);
      }

      throw new Error(`Unknown response: ${e.response.data.Result}`);
    }

    throw e;
  }
};

export const resendActivationMail = async (id: number) => {
  const res = await controllerApi.post(`/sendauthenticationcode/${id}`);
  return res.data;
};

export const passwordReset = async (email: string) => {
  await api.get('/auth/lostPassword', {
    params: {
      email,
    }
  });
};

/*
 * Authenticated endpoints
 */

const checkAuthenticated = () => {
  const a = getAuth();
  if (a) {
    return a;
  } else {
    throw { isNoUserIdError: true, message: 'Login needed!' } as ApiError;
  }
};

const getNoAuth = async (url: string, params?: any) => {
  const res = await api.get(url, {
    params,
  });

  return res.data;
};

/**
 * Authenticated HTTP GET request helper, that returns the response body if the response status is HTTP OK, or throws an exception.
 */
const get = async (url: string, params?: any) => {
  const { token } = checkAuthenticated();

  const res = await api.get(url, {
    headers: {
      'X-Silverstripe-Apitoken': token,
    },
    params,
  });

  refreshAuth();

  return res.data;
};

/**
 * Authenticated HTTP POST request helper, that returns the response body if the response status is HTTP OK, or throws an exception.
 */
const post = async (url: string, body: any, params?: any) => {
  const a = checkAuthenticated();

  const res = await api.post(url, body, {
    headers: {
      'X-Silverstripe-Apitoken': a.token,
    },
    params,
  });

  return res.data;
};

/**
 * Authenticated HTTP PUT request helper, that returns the response body if the response status is HTTP OK, or throws an exception.
 */
const put = async (url: string, body: any, params?: any) => {
  const { token } = checkAuthenticated();

  const res = await api.put(url, body, {
    headers: {
      'X-Silverstripe-Apitoken': token,
    },
    params,
  });

  refreshAuth();

  return res.data;
};

/**
 * Authenticated HTTP DELETE request helper, that returns the response body if the response status is HTTP OK, or throws an exception.
 */
const del = async (url: string, params?: any) => {
  const { token } = checkAuthenticated();

  const res = await api.delete(url, {
    headers: {
      'X-Silverstripe-Apitoken': token,
    },
    params,
  });

  refreshAuth();

  return res.data;
};

const controllerPost = async <T = any, R = any, P = any>(url: string, body?: T, params?: P): Promise<R> => {
  const { token } = checkAuthenticated();

  const res = await withErrorHandling(controllerApi.post<R>(url, body, {
    headers: {
      'X-Silverstripe-Apitoken': token,
    },
    params,
  }));

  refreshAuth();

  return res;
};

const controllerPut = async <T = any, R = any, P = any>(url: string, body: T, params?: P): Promise<R> => {
  const { token } = checkAuthenticated();

  const res = await withErrorHandling(controllerApi.put<R>(url, body, {
    headers: {
      'X-Silverstripe-Apitoken': token,
    },
    params,
  }));

  refreshAuth();

  return res;
};

const controllerDelete = async <P = any>(url: string, params?: P) => {
  const { token } = checkAuthenticated();

  const res = await withErrorHandling(controllerApi.delete(url, {
    headers: {
      'X-Silverstripe-Apitoken': token,
    },
    params,
  }));

  refreshAuth();

  return res;
};

const controllerGet = async <T = any, P = any>(url: string, params?: P): Promise<T> => {
  const { token } = checkAuthenticated();

  const res = await withErrorHandling(controllerApi.get<T>(url, {
    headers: {
      'X-Silverstripe-Apitoken': token,
    },
    params,
  }));

  refreshAuth();

  return res;
};

const controllerGetNoAuth = async (url: string, params?: any) => {
  const res = await controllerApi.get(url, {
    params,
  });

  return res.data;
};


/**
 * Query the given user's data.
 */
export const user = (id: number): Promise<UserType> => {
  return get(`/user/${id}`);
};

// TODO: add type to data
export const updateUser = (id: number, data: UserEditType): Promise<UserType> => {
  return put(`/user/${id}`, data);
};

// TODO: add type to data
export const changePassword = (userId: number, data: any) => {
  return controllerPost(`/changepw/${userId}`, data);
};

/*
 * Briefing related endpoints
 */

const SUBMITTED_BRIEFING_FORM = '/submittedbriefingform';
// TODO: add type to data, return
export const createSubmittedBriefingForm = (data: any) => {
  return post(SUBMITTED_BRIEFING_FORM, data);
};

// TODO: add type to return
export const getBriefings = () => {
  return controllerGet('/userbriefings');
};

// TODO: add type to data, return
export const updateSubmittedBriefingForm = (id: number, data: any) => {
  return put(`${SUBMITTED_BRIEFING_FORM}/${id}`, data);
};

export const deleteSubmittedBriefingForm = (id: number): Promise<void> => {
  return controllerPost(`deletebriefing/${id}`);
};

export const restoreSubmittedBriefingForm = (id: number, collectionId: number): Promise<void> => {
  return controllerPost(`restorebriefing/${id}/${collectionId}`);
}

export const getDeletedBriefingForms = (userId: string) => {
  return get(SUBMITTED_BRIEFING_FORM, {
    SubmittedByEx: userId,
  });
};

export const getProjectCollections = (): Promise<readonly Collection[]> => {
  return controllerGet('/collections');
};

export const getSharedBriefings = (): Promise<readonly SharedBriefing[]> => {
  return controllerGet('/shared');
};

const COLLECTION = '/projectcollection';

// TODO: add type to data, return
export const createProjectCollection = (data: any) => {
  return post(COLLECTION, data);
};

// TODO: add type to data, return
export const updateProjectCollection = (id: number, data: any) => {
  return put(`${COLLECTION}/${id}`, data);
};

export const deleteProjectCollection = (id: number): Promise<void> => {
  return controllerPost(`/deletecollection/${id}`);
};

// TODO: add type to return
export const getBriefingTypes = () => {
  return getNoAuth('/template');
};

// TODO: add type to return
export const getProjectTypes = (): Promise<readonly ProjectType[]> => {
  return controllerGet('/bbdata');
};

const SUBMITTED_BRIEFING_FIELD = '/submittedbriefingfield';

// TODO: add type to data, return
export const createSubmittedFormField = (data: SubmittedFormFieldData): Promise<SubmittedData> => {
  return post(SUBMITTED_BRIEFING_FIELD, data);
};

// TODO: add type to data, return
export const updateSubmittedFormField = (id: number, data: any) => {
  return put(`${SUBMITTED_BRIEFING_FIELD}/${id}`, data);
};

const PARTICIPANT = '/userparticipants';
export const getProjectParticipants = (): Promise<ParticipantType[]> => {
  return controllerGet(PARTICIPANT);
};

export const createProjectParticipant = (data: ParticipantDataType): Promise<ParticipantType> => {
  return controllerPost(PARTICIPANT, data);
};

export const editProjectParticipant = (id: number, data: ParticipantDataType): Promise<ParticipantType> => {
  return controllerPut(`${PARTICIPANT}/${id}`, data);
};

export const deleteProjectParticipant = (id: number): Promise<void> => {
  return controllerDelete(`${PARTICIPANT}/${id}`);
};

const BRIEF_PARTICIPANT = '/participants';
export const addParticipantToBriefing = (uuid: string, id: number, participant: number, data: BriefingRoleType): Promise<BriefingParticipantType> => {
  return controllerPost(`${BRIEF_PARTICIPANT}/${id}`, {
    ...data,
    Uuid: uuid,
    Participant: participant,
  });
};

export const editParticipantRole = (uuid: string, id: number, briefingParticipant: number, data: BriefingRoleType): Promise<BriefingParticipantType> => {
  return controllerPut(`${BRIEF_PARTICIPANT}/${id}/${briefingParticipant}`, {
    Uuid: uuid,
    ...data
  });
};

export const removeParticipantFromBriefing = (id: number, briefingParticipant: number): Promise<void> => {
  return controllerDelete(`${BRIEF_PARTICIPANT}/${id}/${briefingParticipant}`);
};

const CLIENT = '/userclients';
// TODO: add type to data, return
export const getClients = (): Promise<ClientType[]> => {
  return controllerGet(CLIENT);
};

// TODO: add type to data, return
export const createClient = (data: ClientDataType): Promise<ClientType> => {
  return controllerPost(CLIENT, data);
};

// TODO: add type to data, return
export const editClient = (id: number, data: ClientDataType): Promise<ClientType> => {
  return controllerPut(`${CLIENT}/${id}`, data);
};

export const deleteClient = (id: number): Promise<void> => {
  return controllerDelete(`${CLIENT}/${id}`);
};

// TODO: add type to data, return
export const uploadClientLogo = async (id: number, data: any) => {
  const { token } = checkAuthenticated();

  const res = await controllerApi.post(`/clientlogo/${id}`, data, {
    headers: {
      'X-Silverstripe-Apitoken': token,
      'Content-Type': 'multipart/form-data'
    }
  });

  refreshAuth();

  return res.data;
}

const USER_OPTION = '/UserOption';
// TODO: add type to data, return
export const createUserOption = (data: any) => {
  return post(USER_OPTION, data);
};

const USERFILE = 'userfile';
// TODO: add type to return
export const uploadFile = async (
  submittedFiledId: number, data: FormData,
  onProgress: (progress: number) => void,
  canceler: CancelTokenSource
) => {
  const { token } = checkAuthenticated();
  const url = submittedFiledId ? `${USERFILE}/${submittedFiledId}` : USERFILE;
  const res = await controllerApi.post(url, data, {
    cancelToken: canceler.token,
    onUploadProgress: (progressEvent) => {
      const totalLength = progressEvent.lengthComputable ? progressEvent.total : null;

      if (totalLength !== null && totalLength > 0) {
        onProgress(Math.round( (progressEvent.loaded * 100) / totalLength ));
      }
    },
    headers: {
      'X-Silverstripe-Apitoken': token,
      'Content-Type': 'multipart/form-data'
    }
  });

  refreshAuth();

  return res.data;
};

const SUBMITTED_FILE = 'submittedbriefingfile';
// TODO: add type to data, return
export const createSubmittedFile = (data: any) => {
  return post(SUBMITTED_FILE, data);
};

const BRIEFINGFILE = 'briefingfile';
export const deleteSubmittedFile = (id: number): Promise<void> => {
  return del(`${BRIEFINGFILE}/${id}`);
};

// TODO: add type to data, return
export const editSubmittedFile = (id: number, data: any) => {
  return put(`${BRIEFINGFILE}/${id}`, data);
};

// TODO: add type to return
export const cloneBriefing = (userId: number, briefingId: number) => {
  return controllerPost(`/duplicateproject/${userId}/${briefingId}`);
};

const SUBMITTED_MILESTONE = 'SubmittedBriefingMilestone';

// TODO: add type to data, return
export const createSubmittedMilestone = (data: any) => {
  return post(SUBMITTED_MILESTONE, data);
};

const BRIEFING_MILESTONE = 'BriefingMilestone';
// TODO: add type to data, return
export const createBriefingMilestone = (data: any) => {
  return post(BRIEFING_MILESTONE, data);
};

export const deleteBriefingMilestone = (id: number): Promise<void> => {
  return del(`${BRIEFING_MILESTONE}/${id}`);
};

// TODO: add type to data, return
export const editBriefingMilestone = (id: number, data: any) => {
  return put(`${BRIEFING_MILESTONE}/${id}`, data);
};

const SPIDER_CHART = '/submittedspiderchart';
// TODO: add type to data, return
export const createSpiderChartField = (data: any) => {
  return post(SPIDER_CHART, data);
};

// TODO: add type to data, return
export const updateSpiderChartField = (id: number, data: any) => {
  return put(`${SPIDER_CHART}/${id}`, data);
};

const SPIDER_VALUE = '/spiderchart';
export const createSpiderChartValue = (sbfieldId: number, data: SpiderChartFormValue): Promise<SpiderChartAPIValue> => {
  return controllerPost(`${SPIDER_VALUE}/${sbfieldId}`, data);
}

export const editSpiderChartValue = (sbfieldId: number, id: number, data: SpiderChartFormValue): Promise<SpiderChartAPIValue> => {
  return controllerPut(`${SPIDER_VALUE}/${sbfieldId}/${id}`, data);
}

export const deleteSpiderChartValue = (sbfieldId: number, id: number): Promise<void> => {
  return controllerDelete(`${SPIDER_VALUE}/${sbfieldId}/${id}`);
}

const MATRIX_CHART = '/submittedmatrixchart';
// TODO: add type to data, return
export const createMatrixChartField = (data: any) => {
  return post(MATRIX_CHART, data);
};

// TODO: add type to data, return
export const updateMatrixChartField = (id: number, data: any) => {
  return put(`${MATRIX_CHART}/${id}`, data);
};

const MATRIX_VALUE = '/matrixchart';
export const createMatrixChartValue = (sbfieldId: number, data: MatrixChartFormValue): Promise<MatrixChartAPIValue> => {
  return controllerPost(`${MATRIX_VALUE}/${sbfieldId}`, data);
};

export const editMatrixChartValue = (sbfieldId: number, id: number, data: MatrixChartFormValue): Promise<MatrixChartAPIValue> => {
  return controllerPut(`${MATRIX_VALUE}/${sbfieldId}/${id}`, data);
};

export const deleteMatrixChartValue = (sbfieldId: number, id: number): Promise<void> => {
  return controllerDelete(`${MATRIX_VALUE}/${sbfieldId}/${id}`);
}

export const PUBLIC_SBF = 'sbform' ;
// TODO: add type to return
export const queryPublicSBF = (uuid: string) => {
  return controllerGetNoAuth(`${PUBLIC_SBF}/${uuid}`);
};

export const sendInvitations = (addresses: Array<String>): Promise<void> => {
  return controllerPost('invite', addresses);
};

export const shareBriefing = (uuid: string): Promise<void> => {
  return controllerPost(`share/${uuid}`);
};

type TemplateResult = Readonly<{
  BriefingType: BriefingType;
  ProjectType: ProjectType;
  Steps: ReadonlyArray<TemplateStep>;
}>;
// TODO: add type to return
export const getTemplate = (id: number): Promise<TemplateResult> => {
  return controllerGetNoAuth(`/template/${id}`);
};

// TODO: add type to return
export const getBriefingData = (uuid: string) => {
  return controllerGet(`/editbriefing/${uuid}`);
};

export const convertToDataUri = (data: any): Promise<string> => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => reject();
    reader.readAsDataURL(data); 
  });
};

export const downloadAsString = async (url: string): Promise<string> => {
  const res = await axios.get(url, {
    responseType: 'text'
  });

  return res.data;
};

export const downloadAsDataUri = async (url: string): Promise<string> => {
  const res = await axios.get(url, {
    responseType: 'blob'
  });

  return convertToDataUri(res.data);
};

export const downloadAsBase64 = async (url: string): Promise<string> => {
  const res = await axios.get(url, {
    responseType: 'arraybuffer'
  });

  return Buffer.from(res.data, 'binary').toString('base64');
};

export const submitFeedback = async (data: FeedbackData): Promise<void> => {
  return await controllerPost('/feedback', data);
};

export const sendMobileReminderEmail = async (): Promise<void> => {
  try {
    await controllerApi.post('/mobilereminder');
  } catch (e) {
    console.error(e);
  }
};
