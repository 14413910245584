import { getAuth } from '@/util/auth';

export type GtmParams = Readonly<{
  userId?: number;
  briefingType?: string;
  projectType?: string;
  action?: string;
  mobile?: boolean;
}>;

export type LayerData = GtmParams & Readonly<{
  event?: 'interaction' | 'content-view';
  'content-view-name'?: string;
  'content-name'?: string;
}>;

export type PossibleActions = 'login' | 'logout';

declare global {
  interface Window { dataLayer: LayerData[]; }
}

const loadScript = (id: string): Promise<void> => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${id}`;

    document.body.appendChild(script);
    script.onload = () => resolve();
  });
}

export type GTM = Readonly<{
  tag: (params: LayerData) => void;
  action: (action: PossibleActions, params: GtmParams) => void;
  navigation: (route: string, path: string) => void;
}>;

export const revokedGTM: GTM = {
  tag: () => {},
  action: () => {},
  navigation: () => {},
};

export const acceptedGTM = (id: string): GTM => {
  let dataLayer = [];

  (window.dataLayer as any) = window.dataLayer || [];
  dataLayer = window.dataLayer;

  const result: GTM = {
    tag: (params: LayerData) => {
      dataLayer.push(params);
    },
    action(action: PossibleActions, params: GtmParams) {
      this.tag({
        event: 'interaction',
        action,
        ...params
      });
    },
    navigation(route: string, path: string) {
      this.tag({
        event: 'content-view',
        'content-view-name': route,
        'content-name': path,
      });
    }
  };

  if (document.documentElement.clientWidth <= 600) {
    result.tag({
      mobile: true,
    });
  }
  
  const auth = getAuth();
  if (auth) {
    // if the user is logged in, set the userId before the script is loaded
    result.tag({
      userId: auth.id,
    });
  }

  loadScript(id);

  return result;
};
