<template>
  <v-switch
    v-model="data"
    class="briefing-field"
    true-value="1"
    false-value="0"
    :label="value.Title"
    :color="color"
    :loading="isSaving"
    :disabled="isSaving"
    @change="onChange"
  />
</template>

<script>
import FieldBase from './FieldBase';
import InputFieldBase from './InputFieldBase';

export default {
  name: 'SingleCheckbox',
  mixins: [ FieldBase, InputFieldBase ],
  created() {
    // if haven't been pupulated with the already submitted data
    // in the FieldBase mixin, use a default of '0'
    if (this.data === null) {
      this.data = "0";
    }

    this.update();
  },
  methods: {
    update() {
      this.onUpdated({
        id: this.value.ID,
        value: this.data,
      });
    },
    async onChange() {
      try {
        this.isSaving = true;
        await this.submit(this.data);
        this.update();
      } catch (e) {
        this.setError(e);
      }

      this.isSaving = false;
    },
  }
}
</script>
