<script>
import { Bubble } from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { chartImage } from '@/pdf/components';

export const BUBBLE_SIZE = 10;

const mapChartData = (data, color) => {
  const colors = Array(data.length).fill('black');
  colors[0] = color;

  return [{
    label: '',
    borderColor: colors,
    backgroundColor: colors,
    datalabels: {
      align: 'bottom',
      offset: 10
    },
    data: data.map((d) => ({
      label: d.Title,
      x: d.ValueX,
      y: d.ValueY,
      r: BUBBLE_SIZE
    }))
  }];
}

export default {
  name: 'MatrixChart',
  extends: Bubble,
  props: {
    labelX: {
      type: String,
      default: undefined,
    },
    labelY: {
      type: String,
      default: undefined,
    },
    data: {
      type: Array,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    draggable: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    chartData: {
      datasets: []
    }
  }),
  computed: {
    options() {
      return {
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            title: () => '',
            label: (tooltipItem) => `${tooltipItem.xLabel}, ${tooltipItem.yLabel}`,
            footer: () => ''
          }
        },
        animation: false,
        dragData: this.draggable,
        dragDataRound: 0,
        dragX: true,
        dragOptions: {
          showTooltip: true
        },
        onDrag: (e) => {
          // change cursor style to grabbing during drag action
          e.target.style.cursor = 'grabbing';
        },
        onDragEnd: (e, datasetIndex, index, value) => {
          // restore default cursor style upon drag release
          e.target.style.cursor = 'default';
          this.$emit('change', {
            index,
            value
          });
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.labelY,
            },
            ticks: {
              min: -25,
              max: +25,
              display: false,
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.labelX,
            },
            ticks: {
              min: -25,
              max: +25,
              display: false,
            }
          }],
        }
      };
    }
  },
  watch: {
    labelX() {
      this.reRender();
    },
    labelY() {
      this.reRender();
    },
  },
  created() {
    this.chartData = {
      datasets: mapChartData(this.data, this.color)
    };
  },
  mounted () {
    this.addPlugin(ChartDataLabels);
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.reRender();
  },
  methods: {
    reRender() {
      this.chartData = {
        datasets: mapChartData(this.data, this.color)
      };

      this.renderChart(this.chartData, this.options);
    },
    renderPdfContent() {
      return [
        chartImage(this.$refs.canvas)
      ];
    }
  }
}
</script>