// importing the datalabels plugin it registers itself
// it's not needed for every chart, so we unregister it
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-plugin-dragdata';

Chart.plugins.unregister(ChartDataLabels);

Chart.defaults.global.tooltips.displayColors = false;
//Chart.defaults.global.tooltips.enabled = false;
Chart.defaults.global.defaultFontFamily = 'TTNorms';
Chart.defaults.global.defaultFontSize = 24;
