<template>
  <internal-layout>
    <template #header>
      <multi-tiles :title="$t('invite.tile')" />
    </template>

    <template #mobile-header>
      <multi-tiles :title="$t('invite.tile')" />
    </template>

    <template default>
      <invite-form
        v-if="!invitesSent"
        v-model="emails"
        @submit.prevent="sendInvites"
      >
        <template #default="{ valid }">
          <div class="float-right">
            <button-xl
              emphasize
              type="submit"
              :disabled="!valid"
              @click.prevent="sendInvites"
            >
              {{ $t('invite.action') }}
            </button-xl>
          </div>
        </template>
      </invite-form>
      <success-screen v-else :title="$t('invite.success.title')" :button-groups="buttonGroups">
        <p>{{ $t('invite.success.text') }}</p>
      </success-screen>
    </template>
  </internal-layout>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import { COLLECTIONS, NEW_BRIEFING_SETUP } from '@/routes';
import { sendInvitations } from '@/api';
import i18n from '@/i18n';
import InviteForm from '@/components/InviteForm.vue';
import InternalLayout from '@/components/layout/InternalLayout.vue';
import SuccessScreen from '@/components/SuccessScreen.vue';
import MultiTiles from '@/components/layout/MultiTiles.vue';

export default {
  name: 'InvitePage',
  components: {
    InternalLayout,
    InviteForm,
    SuccessScreen,
    MultiTiles,
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path.endsWith('/sent')) {
      this.invitesSent = true;
    } else {
      // this way the user could navigate back to the invite page!
      this.emails = [];
      this.invitesSent = false;
    }

    next();
  },
  data: () => ({
    emails: [],
    invitesSent: false,
    buttonGroups: [
      { title: i18n.t('invite.success.options'), buttons: [
          { title: i18n.t('share.to.collections'), to: { name: COLLECTIONS }, emphasize: true },
          { title: i18n.t('share.to.new_briefing'), to: { name: NEW_BRIEFING_SETUP }, hideOnMobile: true },
      ]}
    ]
  }),
  methods: {
    ...mapMutations(['saveStart', 'saveError']),
    ...mapActions(['saveSuccess']),
    async sendInvites() {
      try {
        this.saveStart();
        await sendInvitations(this.emails);
        this.$router.push({
          path: '/invite/sent'
        });
        this.saveSuccess();
      } catch (e) {
        this.saveError(e);
      }
    },
  }
}
</script>

<style lang="sass">
#invite-tiles
  height: 150px

#invite-mobile-tiles
  height: 105px
</style>
