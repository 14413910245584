<template>
  <div>
    <v-data-table
      class="complex-table"
      item-key="ID"
      :headers="headers"
      :items="participants || []"
      :items-per-page="5"
      :search="search"
      :loading="loading"
      :footer-props="$TABLE_FOOTER_PROPS"
    >
      <template #top>
        <div class="participant-search d-flex align-end justify-space-between">
          <v-text-field
            v-model="search"
            :label="$t('generic.search')"
            hide-details
          />

          <span class="mx-5">{{ $t('generic.or') }}</span>

          <v-dialog v-model="createDialog.shown" persistent max-width="720px">
            <template #activator="{ on }">
              <button-text 
                :loading="loading"
                underlined
                v-on="on"
              >
                + {{ $t('participant.create.title') }}
              </button-text>
            </template>
            
            <participant-dialog @dismiss="closeCreateDialog" @success="closeCreateDialog" />
          </v-dialog>
        </div>
      </template>
      <template #item.Title="{ item }">
        <span class="table-emphasized">{{ item.Title }}</span>
      </template>
      <template #item.action="{ item }">
        <v-icon
          size="18px"
          class="mr-1"
          :title="$t('participant.edit.title')"
          @click="showEditDialog(item)"
        >
          mdi-pencil-outline
        </v-icon>
        <v-icon size="20px" :title="$t('participant.delete.title')" @click="showDeleteDialog(item)">
          mdi-delete-outline
        </v-icon>
      </template>
    </v-data-table>
    
    <v-dialog v-model="editDialog.shown" persistent max-width="720px">
      <participant-dialog
        :id="editDialog.id"
        :value="editDialog.form"
        @dismiss="closeEditDialog"
        @success="closeEditDialog"
      />
    </v-dialog>

    <v-dialog v-model="deleteDialog.shown" persistent max-width="720px">
      <participant-delete-dialog :value="deleteDialog.participant" @dismiss="closeDeleteDialog" @success="closeDeleteDialog" />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import i18n from '@/i18n';
import { ParticipantData } from '@/model/userdata';
import ParticipantDialog from '@/components/dialogs/ParticipantDialog.vue';
import ParticipantDeleteDialog from '@/components/dialogs/ParticipantDeleteDialog.vue';

export default {
  name: 'ParticipantsPage',
  components: {
    ParticipantDialog,
    ParticipantDeleteDialog
  },
  data: () => ({
    createDialog: {
      shown: false,
    },
    editDialog: {
      shown: false,
      id: null,
      form: ParticipantData.create(),
    },
    deleteDialog: {
      shown: false,
      participant: null,
    },
    headers: [
      { text: i18n.t('participant.labels.name'), value: 'Title' },
      { text: i18n.t('profile.labels.email'), value: 'Email' },
      { text: i18n.t('profile.labels.company'), value: 'Company' },
      { text: '', value: 'action', align: 'center', width: '5em', sortable: false },
    ],
    search: null,
    isSaving: false,
  }),
  computed: {
    ...mapState(['participants']),
    loading() {
      return this.participants === null || this.isSaving;
    },
  },
  created() {
    this.fetchParticipants();
  },
  methods: {
    ...mapMutations(['saveStart', 'saveError']),
    ...mapActions(['fetchParticipants', 'saveSuccess', 'addNewParticipant']),
    closeCreateDialog() {
      this.createDialog.shown = false;
    },
    showEditDialog(p) {
      this.editDialog.shown = true;
      this.editDialog.id = p.ID;
      this.editDialog.form = ParticipantData.copy(p);
    },
    closeEditDialog() {
      this.editDialog.shown = false;
      this.editDialog.id = null;
      this.editDialog.form = null;
    },
    showDeleteDialog(participant) {
      this.deleteDialog.shown = true;
      this.deleteDialog.participant = participant;
    },
    closeDeleteDialog() {
      this.deleteDialog.shown = false;
      this.deleteDialog.participant = null;
    },
  }
}
</script>
