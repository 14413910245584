import { InputValidationRule } from 'vuetify';
import { TranslateResult } from 'vue-i18n';
import i18n from '@/i18n';
import { Nullable } from './typehelpers';

export const EMAIL_PTTRN = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
export const EMAIL_TO_PTTRN = /^("[^"]+")?\s*[<]?([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)[>]?$/;

type ValidatorCreator = (label: TranslateResult) => InputValidationRule;
type RegexpValidatorCreator = (label: TranslateResult, exp: RegExp) => InputValidationRule;
type MinMaxValidatorCreator = (label: string, value: number) => InputValidationRule;
type MoneyValidatorCreator = (label: string, minDecimal: number, max: number) => InputValidationRule;

export type ValidatorCollection<T> = {
  readonly [key in keyof T]: InputValidationRule[]
};

export type FormType<T> = {
  empty: Nullable<T>;
  validation: ValidatorCollection<T>;
  copy?: (value: Nullable<T>) => Nullable<T>;
  create: () => Nullable<T>;
};

const nullVal = (v: string | number | undefined | null) => v === undefined || v === null;

export const required: ValidatorCreator = (fieldLabel) => 
  v => !nullVal(v) 
    && !(typeof v === 'string' && v.trim() === '')
    || i18n.t('validation.required', [fieldLabel]) as string;

export const regexp: RegexpValidatorCreator = (fieldLabel, exp) => 
  v => nullVal(v) 
    || (typeof v === 'string' && exp.test(v))
    || i18n.t('validation.invalid', [fieldLabel]) as string;

export const email: ValidatorCreator = (fieldLabel) => regexp(fieldLabel, EMAIL_PTTRN);

export const password: ValidatorCreator = (fieldLabel) => 
  v => nullVal(v)
    || (typeof v === 'string' && v.length >= 8)
    || i18n.t('validation.too_short', [fieldLabel]) as string;

export const parseMoney = (value: string | undefined | null) => {
  if (value === null || value === undefined) {
    return null;
  }

  value = value.trim().replace(/[.,-]+/g, '');

  if (value === '') {
    return null;
  }

  const res = parseInt(value);

  if (isNaN(res)) {
    return value;
  } else {
    return res;
  }
}

export const money: MoneyValidatorCreator = (fieldLabel, min, max) => {
  const minExp = min ? Math.pow(10, min) : undefined;
  const maxExp = max ? Math.pow(10, max) : undefined;

  return v => {
    if (nullVal(v)) {
      return true;
    }

    let n: number | null;
    if (typeof v === 'number') {
      n = v;
    } else if (typeof v === 'string') {
      const res = parseMoney(v);

      if (typeof res === 'string') {
        return i18n.t('validation.invalid', [fieldLabel]) as string;
      }

      n = res;
    } else {
      return i18n.t('validation.invalid', [fieldLabel]) as string;
    }

    return n === null
      || ((!minExp || n >= minExp) && (!maxExp || n <= maxExp))
      || i18n.t('validation.invalid', [fieldLabel]) as string;
  };
}

export const min: MinMaxValidatorCreator = (fieldLabel, thr) => 
  v => nullVal(v)
    || (typeof v === 'string' && v.length >= thr)
    || i18n.t('validation.too_short', [fieldLabel]) as string;

export const max: MinMaxValidatorCreator = (fieldLabel, thr) => 
  v => nullVal(v)
    || (typeof v === 'string' && v.length <= thr)
    || i18n.t('validation.too_long', [fieldLabel]) as string;

export const manualCheck = (value: any, rules: InputValidationRule[]) => {
  return rules.find(rule => {
    const res = rule(value);
    return res === true ? false : res;
  });
};

export const requiredEmail = [
  required(i18n.t('profile.labels.email')),
  email(i18n.t('profile.labels.email')),
];
