import Cookies from 'js-cookie';
import { differenceInMilliseconds, addMilliseconds } from 'date-fns';

export interface AuthCookie {
  readonly id: number;
  readonly token: string;
  // in ms
  readonly exp: number;
}

/**
 * Get auth info. Returns undefined if it is not set yet.
 */
export const getAuth = () => Cookies.getJSON('auth') as AuthCookie | undefined;

/**
 * Store the auth info in a cookie, JSON serialized.
 * TODO: set domain? (probably won't be needed)
 * 
 * @param id 
 * @param token 
 * @param exp
 * @param expires
 */
const setHelper = (id: number, token: string, exp: number, expires: Date) => {
  Cookies.set('auth',
    { id, token, exp } as AuthCookie,
    // eslint-disable-next-line no-undef
    { secure: true, expires, path: process.env.BASE_URL }
  );
};

/**
 * Store the auth info in a cookie, JSON serialized.
 * 
 * @param id 
 * @param token 
 * @param expires
 */
export const setAuth = (id: number, token: string, expires: Date) => {
  setHelper(id, token, differenceInMilliseconds(expires, new Date()), expires);
};

/**
 * Extend the expiration of the auth cookie.
 * On the backend, the auth token is refreshed for every API request.
 */
export const refreshAuth = () => {
  const auth = getAuth();

  if (auth) {
    setHelper(auth.id, auth.token, auth.exp, addMilliseconds(new Date(), auth.exp));
  }
};

/**
 * Clear the auth info, aka logout.
 */
export const clearAuth = () => Cookies.remove('auth', { path: process.env.BASE_URL });
