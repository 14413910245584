<template>
  <svg
    id="briefing-steps-tile"
    xmlns="http://www.w3.org/2000/svg"
    width="149.426"
    height="216.816"
    viewBox="0 0 149.426 216.816"
  >
    <g transform="translate(-1051.131 77.926)">
      <path d="M0,0H101.865V197.169H0Z" transform="matrix(0.966, 0.259, -0.259, 0.966, 1102.163, -77.926)" :fill="color" />
      <text
        transform="translate(1108.829 97)"
        fill="#fff"
        font-size="22"
        letter-spacing="-0.049em"
      ><tspan x="-15.068" y="0">{{ current }}/{{ count }}</tspan></text>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'BriefingStepsTile',
  props: {
    current: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  }
}
</script>

<style lang="sass">
#briefing-steps-tile
  margin-top: -78px
  margin-right: 0
  height: 216px
</style>
