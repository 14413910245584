<template>
  <div v-if="isRenderable" class="canvas-simpletext canvas-field">
    <h2 class="canvas-field-title" v-html="title" />
    <p v-html="htmlContent" />
    <v-divider />
  </div>
</template>

<script>
import ElementBase from './ElementBase';
import { singleTextField, field } from '@/pdf/components';
import { stylizeUserText } from '@/util/data';

export default {
  name: 'CanvasSimpletext',
  mixins: [ ElementBase ],
  computed: {
    isRenderable() {
      return this.content !== null;
    },
    htmlContent() {
      return stylizeUserText(this.content, this.briefingType.BriefingType);
    }
  },
  methods: {
    renderPdfContent() {
      if (!this.isRenderable) {
        return undefined;
      }

      return field([
        singleTextField(this.value, this.content),
      ]);
    }
  }
}
</script>

<style lang="sass">
.canvas-simpletext
  p
    font-size: $font-size-canvas-options
    line-height: 1
    margin-bottom: 0px
    padding-bottom: $canvas-option-bottom-padding
</style>
