<template>
  <div class="milestones-table briefing-field">
    <v-simple-table class="gray-text">
      <template #default>
        <tbody>
          <tr v-for="(t, i) in rows" :key="`timing-${i}`">
            <td class="name">
              <span class="table-emphasized">{{ t.Title }}</span>
              <br v-if="$isTabletOrMobile">
              <span v-if="$isTabletOrMobile" class="date">
                {{ !t.CheckboxValue ? `${t.From || $t('generic.to_be_done')} - ` : '' }}{{ t.To || $t('generic.to_be_done') }}
              </span>
            </td>
            <td v-if="!$isTabletOrMobile" class="date table-emphasized">
              {{ (!t.From && !t.To) ? $t('generic.to_be_done') : (!t.CheckboxValue && t.From ? t.From : '' ) }}
            </td>
            <td v-if="!$isTabletOrMobile" class="date-separator table-emphasized">
              {{ (t.From && t.To) ? '-' : '' }}
            </td>
            <td v-if="!$isTabletOrMobile" class="date table-emphasized">
              {{ t.To || '' }}
            </td>
            <td class="is-milestone">
              <v-icon
                v-if="!hideMilestone && t.CheckboxValue"
                size="12pt"
                color="white"
                :style="{ 'background-color': color }"
                title="Milestone"
              >
                mdi-check
              </v-icon>
            </td>
            <td v-if="editable" class="actions">
              <v-icon
                size="12pt"
                :title="$t('generic.edit')"
                class="mr-1"
                @click="showEditDialog(t)"
              >
                mdi-pencil-outline
              </v-icon>
              <v-icon size="12pt" :title="$t('generic.delete')" @click="showDeleteDialog(t.ID, t.Title)">
                mdi-delete-outline
              </v-icon>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <milestone-delete-dialog
      :id="deleteDialog.id"
      :show="deleteDialog.show"
      :title="deleteDialog.title"
      :color="color"
      @success="onDelete"
      @dismiss="dismissDeleteDialog"
    />

    <v-dialog v-model="editDialog.shown" max-width="720px" persistent>
      <dialog-content :title="$t('create_briefing.milestones.added_title')">
        <milestone-edit-form
          v-model="editDialog.form"
          edit
          :valid.sync="editDialog.valid"
          :briefing-type="briefingType"
          :titles="titles"
          @submit.prevent="submitEditDialog"
        />

        <template #actions>
          <button-xl :color="briefingType.Color" @click.prevent="dismissEditDialog">
            {{ $t('generic.cancel') }}
          </button-xl>
          <button-xl
            emphasize
            :color="briefingType.Color"
            :disabled="!editDialog.valid"
            @click.prevent="submitEditDialog"
          >
            {{ $t('generic.save') }}
          </button-xl>
        </template>
      </dialog-content>
    </v-dialog>
  </div>
</template>

<script>
import MilestoneEditForm, { emptyForm, milestoneFormFrom } from './MilestoneEditForm.vue';
import MilestoneDeleteDialog, { emptyDeleteDialogData, dismissDeleteDialog, showDeleteDialog } from '@/components/dialogs/MilestonesDeleteDialog.vue';
import { clone } from '@/util/data';
import { milestonesTable } from '@/pdf/components';

const mapCheckboxValue = (value) => {
  return value === 1 || value === '1';
};

export default {
  name: 'MilestonesTable',
  components: {
    MilestoneEditForm,
    MilestoneDeleteDialog,
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    editable: {
      type: Boolean,
      default: false,
    },
    briefingType: {
      type: Object,
      required: true,
    },
    hideMilestone: {
      type: Boolean,
      default: false
    },
    titles: {
      type: Array,
      required: true,
    }
  },
  data: () => ({
    deleteDialog: emptyDeleteDialogData(),
    editDialog: {
      valid: false,
      shown: false,
      id: null,
      form: emptyForm(),
    },
  }),
  computed: {
    rows() {
      return [ ...this.value ]
        .sort((a, b) => {
          const aFrom = a.From || a.To;
          const bFrom = b.From || b.To;
          if (bFrom <= aFrom) {
            return +1;
          } else {
            return -1;
          }
        }).map(m => ({
          ...m,
          CheckboxValue: mapCheckboxValue(m.CheckboxValue),
        }));
    },
    color() {
      return this.briefingType.Color;
    }
  },
  methods: {
    showDeleteDialog(id, title) {
      showDeleteDialog(this.deleteDialog, id, title);
    },
    dismissDeleteDialog() {
      dismissDeleteDialog(this.deleteDialog);
    },
    onDelete() {
      this.$emit('deleted', this.deleteDialog.id);
      this.dismissDeleteDialog();
    },
    showEditDialog(timing) {
      this.editDialog.shown = true;
      this.editDialog.id = timing.ID;
      this.editDialog.form = milestoneFormFrom(timing);
    },
    dismissEditDialog() {
      this.editDialog.shown = false;
      this.editDialog.id = null;
      this.editDialog.form = emptyForm();
      this.editDialog.valid = false;
    },
    submitEditDialog() {
      this.$emit('edited', this.editDialog.id, clone(this.editDialog.form));
      this.dismissEditDialog();
    },
    renderPdfContent() {
      return [
        milestonesTable(this.rows, this.color),
      ];
    }
  }
}
</script>

<style lang="sass">
.milestones-table
  @media #{map-get($display-breakpoints, 'sm-and-down')}
    margin-top: 40px
    margin-bottom: 40px

  td
    @include table-border-bottom
    padding: 0 6px
    &.name
      p
        margin: 0px

    &.is-milestone
      width: 30px
    &.actions
      text-align: right
      width: 60px
      padding-right: 5px
    &.date
      width: 118px
    &.date-separator
      width: 1em
  
  .is-milestone .v-icon
    border-radius: 30px
</style>
