<template>
  <internal-layout v-if="briefings">
    <template #header>
      <multi-tiles :title="$t('deleted.tile')" />
    </template>

    <template #mobile-header>
      <multi-tiles :title="$t('deleted.tile')" />
    </template>

    <template default>
      <div class="collection-wrapper">
        <h1>{{ $t('deleted.title') }}</h1>

        <v-data-table
          class="collection-table gray-text complex-table"
          item-key="ID"
          :headers="headers"
          :items="briefings"
          :items-per-page="5"
          :mobile-breakpoint="0"
          :footer-props="$TABLE_FOOTER_PROPS"
        >
          <template #item.Title="{ item }">
            <span class="table-emphasized">
              {{ item.Title }}
            </span>
          </template>
          <template #item.action="{ item }">
            <div class="restore-column">
              <v-switch
                v-model="item.Selected"
                color="primary"
                hide-details
              />
            </div>
          </template>
        </v-data-table>

        <div class="d-flex justify-end new-briefing-btn">
          <button-l emphasize :disabled="!isAnyRowSelected" @click="openRestoreDialog">
            {{ $t('deleted.restore') }}
          </button-l>
        </div>
      </div>
    </template>

    <v-dialog v-model="dialog.shown" max-width="720px" persistent>
      <dialog-content :title="$t('deleted.restore')">
        <v-form ref="restoreForm" v-model="dialog.valid" @submit.prevent="submitRestoreDialog">
          <p>{{ $t('deleted.restore_text') }}</p>

          <choose-collection
            v-model="dialog.collection"
            :collections="collections"
            required
          />
        </v-form>

        <template #actions>
          <button-xl @click.prevent="dismissRestoreDialog">
            {{ $t('generic.cancel') }}
          </button-xl>
          <button-xl
            emphasize
            :disabled="!dialog.valid"
            @click.prevent="submitRestoreDialog"
          >
            {{ $t('generic.save') }}
          </button-xl>
        </template>
      </dialog-content>
    </v-dialog>
  </internal-layout>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { parseISO } from 'date-fns';
import i18n from '@/i18n';
import { getDeletedBriefingForms, restoreSubmittedBriefingForm, getProjectCollections } from '@/api';
import InternalLayout from '@/components/layout/InternalLayout.vue';
import MultiTiles from '@/components/layout/MultiTiles.vue';
import ChooseCollection, { getCollectionID } from '@/components/ChooseCollection.vue';

export default {
  name: 'Projects',
  components: {
    InternalLayout,
    MultiTiles,
    ChooseCollection,
  },
  data: () => ({
    briefings: [],
    headers: [
      { text: i18n.t('projects.labels.name'), value: 'Title' },
      { text: i18n.t('deleted.expiration'), width: '220px', value: 'Expiration' },
      { text: i18n.t('deleted.restore'), value: 'action', align: 'right', width: '130px', sortable: false },
    ],
    dialog: {
      shown: false,
      valid: false,
      collection: null,
    },
    collections: [],
  }),
  computed: {
    ...mapState(['userId']),
    isAnyRowSelected() {
      return this.briefings.some(b => b.Selected);
    },
  },
  async created() {
    try {
      this.loadStart();
      const [ collections, result ] = await Promise.all([ getProjectCollections(this.userId), getDeletedBriefingForms(this.userId) ]);

      this.collections = collections;

      this.briefings = result.map(b => ({
        ID: b.ID,
        Title: b.Title,
        Expiration: b.Expiration ? this.$formatDate(parseISO(b.Expiration), 'P') : null,
        Uuid: b.Uuid,
        SubmittedBy: b.SubmittedBy,
        Selected: false,
      }));
      this.loadSuccess();
    } catch (e) {
      this.loadError(e);
    }
  },
  methods: {
    ...mapActions(['saveSuccess']),
    ...mapMutations([ 'saveStart', 'saveError', 'loadStart', 'loadSuccess', 'loadError' ]),
    openRestoreDialog() {
      this.dialog.shown = true;
    },
    async submitRestoreDialog() {
      try {
        this.saveStart();

        let collectionId = await getCollectionID(this.dialog.collection, this.userId);

        await Promise.all(this.briefings.filter(b => b.Selected).map(b => restoreSubmittedBriefingForm(b.ID, collectionId)));
        this.briefings = this.briefings.filter(b => !b.Selected);
        this.dismissRestoreDialog();
        this.saveSuccess();
      } catch (e) {
        this.saveError(e);
      }
    },
    dismissRestoreDialog() {
      this.dialog.shown = false;
      this.dialog.collection = null;
      this.$refs.restoreForm.resetValidation();
    },
  }
}
</script>

<style lang="sass">
.collection-wrapper
  .v-input--selection-controls
    margin-top: 0
    padding-top: 0

.restore-column
  display: inline-block
  margin-top: -$table-row-padding-top-raw + 6px
</style>
