<template>
  <v-container class="briefing-participants pa-0">
    <v-row v-if="added.length === 0" class="mt-4 mb-4">
      <v-col cols="5">
        <button-text
          underlined
          :color="color"
          :loading="loading"
          :disabled="!available && available.length === 0"
          @click="showSelectDialog"
        >
          + {{ $t('client.select.title') }}
        </button-text>
      </v-col>
      
      <v-col cols="2">
        {{ $t('generic.or') }}
      </v-col>

      <v-col cols="5">
        <button-text
          underlined
          :color="color"
          :loading="loading"
          @click="showCreateDialog"
        >
          + {{ $t('client.create') }}
        </button-text>
      </v-col>
    </v-row>
    <h2 v-else class="briefing-field-title participants-added">
      {{ $t('client.added') }}:
    </h2>

    <card-list
      v-if="added.length > 0"
      :value="added"
      can-delete
      @deleted="showDeleteDialog"
    >
      <template #title="{ item }">
        <div>{{ item.CompanyName || '&nbsp;' }}</div>
      </template>
      <template #default="{ item }">
        <div>{{ item.Email || '&nbsp;' }}</div>
      </template>
    </card-list>

    <v-dialog :value="selectDialog.shown" max-width="720px" persistent>
      <dialog-content :title="$t('client.select.title')">
        <v-form v-if="selectDialog.shown" @submit.prevent="onSelectDialogNext">
          <p>{{ $t('client.select.description') }}</p>
          <div class="participant-search d-flex align-end justify-space-between">
            <v-text-field
              v-model="selectDialog.search"
              :label="$t('generic.search')"
              :color="color"
              hide-details
            />

            <span class="mx-3">
              {{ $t('generic.or') }}
            </span>

            <button-text
              underlined
              :color="color"
              :loading="loading"
              @click="showCreateDialogFromSelect"
            >
              + {{ $t('client.create') }}
            </button-text>
          </div>

          <v-item-group
            :value="selectedIndex"
            :multiple="false"
            @change="onSelectionChange"
          >
            <div class="d-flex flex-wrap justify-center">
              <v-item v-for="(item, i) in available" :key="i" v-slot="{ active, toggle }">
                <div
                  class="tile-field-item"
                  :class="{ active: active }"
                  :style="{ color: color }"
                  @click="toggle"
                >
                  <img
                    v-if="item.Logo"
                    class="tile-field-image"
                    :src="item.Logo"
                    :title="item.CompanyName"
                    :alt="item.CompanyName"
                  >
                  <span v-else class="tile-field-placeholder d-flex align-center justify-center">{{ item.CompanyName }}</span>
                </div>
              </v-item>
            </div>
          </v-item-group>
        </v-form>

        <template #actions>
          <button-xl :color="color" @click.prevent="dismissSelectDialog">
            {{ $t('generic.cancel') }}
          </button-xl>
          <button-xl
            emphasize
            :color="color"
            :disabled="selectDialog.selected === null"
            @click.prevent="submitSelectDialog"
          >
            {{ $t('generic.ok') }}
          </button-xl>
        </template>
      </dialog-content>
    </v-dialog>

    <v-dialog v-model="createDialog.shown" persistent max-width="720px">
      <client-dialog
        :color="color"
        :after-created="addClientHelper"
        @dismiss="closeCreateDialog"
        @success="newClientCreated"
      />
    </v-dialog>

    <v-dialog v-model="deleteDialog.shown" persistent max-width="720px">
      <dialog-content :title="$t('client.unselect.title')">
        <p>{{ $t('client.unselect.text', [deleteDialog.client ? deleteDialog.client.CompanyName : '']) }}</p>

        <template #actions>
          <button-xl :color="color" @click="closeDeleteDialog">
            {{ $t('generic.cancel') }}
          </button-xl>
          <button-xl emphasize :color="color" @click="submitDelete">
            {{ $t('generic.ok') }}
          </button-xl>
        </template>
      </dialog-content>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import FieldBase from './FieldBase';
import ClientDialog from '@/components/dialogs/ClientDialog.vue';
import CardList from '@/components/common/CardList.vue';
import { wordStartPatternOf } from '@/util/data';

export default {
  name: 'ClientField',
  components: {
    ClientDialog,
    CardList,
  },
  mixins: [ FieldBase ],
  data: () => ({
    createDialog: {
      shown: false,
    },
    deleteDialog: {
      shown: false,
      client: null,
    },
    selectDialog:{
      shown: false,
      search: null,
      selected: null,
    },
    added: [],
    isSaving: false,
  }),
  computed: {
    ...mapState(['clients']),
    loading() {
      return this.clients === null || this.isSaving;
    },
    available() {
      const pattern = wordStartPatternOf(this.selectDialog.search);
      
      if (pattern) {
        return this.clients?.filter(cav => pattern.test(cav.CompanyName));
      } else {
        return this.clients;
      }
    },
    selectedIndex() {
      return this.available.findIndex((a) => a === this.selectDialog.selected);
    }
  },
  created() {
    this.added = this.value.SubmittedData?.Clients || [];
  },
  methods: {
    showCreateDialog() {
      this.createDialog.shown = true
    },
    closeCreateDialog() {
      this.createDialog.shown = false;
    },
    async newClientCreated(res) {
      this.closeCreateDialog();
      this.added.push(res);
    },
    showDeleteDialog(client) {
      this.deleteDialog.shown = true;
      this.deleteDialog.client = client;
    },
    closeDeleteDialog() {
      this.deleteDialog.shown = false;
      this.deleteDialog.client = null;
    },
    async addClientHelper(client) {
      await this.submit([ ...this.added, client ].filter(p => p !== undefined).map(p => p.ID).join(','));
    },
    async submitDelete() {
      try {
        const p = this.deleteDialog.client;
        this.isSaving = true;
        const newData = this.added.filter(i => i != p);
        await this.submit(newData.filter(p => p !== undefined).map(p => p.ID).join(','));
        this.added = newData;
        this.closeDeleteDialog();
      } catch (e) {
        this.setError(e);
      }

      this.isSaving = false;
    },
    showSelectDialog() {
      this.selectDialog.shown = true;
      this.selectDialog.selected = null;
      this.selectDialog.search = null;
    },
    dismissSelectDialog() {
      this.selectDialog.shown = false;
    },
    showCreateDialogFromSelect() {
      this.dismissSelectDialog();
      this.showCreateDialog();
    },
    async submitSelectDialog() {
      this.isSaving = true;

      try {
        await this.addClientHelper(this.selectDialog.selected);
        this.added = [ ...this.added, this.selectDialog.selected ];
        this.dismissSelectDialog();
      } catch (e) {
        this.setError(e);
      }

      this.isSaving = false;
    },
    onSelectionChange(i) {
      this.selectDialog.selected = this.available[i];
    }
  }
}
</script>
