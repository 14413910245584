

























import { Component } from 'vue-property-decorator';
import * as BriefingStoreMutations from '@/store/interfaces/BriefingMutations';
import InputFieldBase from './InputFieldBase';
import { YesNoFieldType } from '@/model/brief2';

@Component({
  methods: {
    ...BriefingStoreMutations.mapBriefingMutations(['showCreateParticipantDialog']),
  }
})
export default class YesNoField extends InputFieldBase {
  /*
   * vuex
   */
  showCreateParticipantDialog!: BriefingStoreMutations.showCreateParticipantDialog;

  created() {
    this.update();
  }

  /*
   * methods
   */
  update() {
    this.onUpdated({
      id: this.value.ID,
      value: this.data,
    });
  }

  async onChange() {
    try {
      this.isSaving = true;
      await this.submit(this.data);

      if (this.data === 'ja' && (this.value as YesNoFieldType).AddParticipant === 1) {
        this.showCreateParticipantDialog();
      }

      this.update();
    } catch (e) {
      this.setError(e);
    }

    this.isSaving = false;
  }
}
