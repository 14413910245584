<template>
  <v-container class="cardlist pa-0 mt-4">
    <v-row>
      <v-col
        v-for="(item, i) in value"
        :key="`card-${i}`"
        class="cardlist-wrapper"
        cols="12"
        sm="4"
      >
        <v-card class="cardlist-item" tile elevation="0">
          <v-card-title>
            <slot name="title" :item="item">
              {{ item.Title || '&nbsp;' }}
            </slot>
          </v-card-title>
          <v-card-text class="d-inline-flex align-end">
            <div>
              <slot :item="item" />
            </div>

            <v-spacer />
            <v-icon
              v-if="canEdit"
              :title="$t('generic.edit')"
              class="mr-2"
              @click="$emit('edited', item)"
            >
              mdi-pencil-outline
            </v-icon>
            <v-icon v-if="canDelete" :title="$t('generic.delete')" @click="$emit('deleted', item)">
              mdi-delete-outline
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CardList',
  props: {
    value: {
      type: Array,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style lang="sass">
.cardlist
  margin-bottom: $field-margin

.cardlist-wrapper
  padding-top: 0
  padding-bottom: 0

.cardlist-item.v-card
  padding-top: $table-row-padding-top
  @include table-border-bottom
  padding-bottom: $table-row-padding-bottom

  @media #{map-get($display-breakpoints, 'sm-and-down')}
    padding-top: $table-row-padding-top-m
    padding-bottom: $table-row-padding-bottom-m

  .v-card__title
    font-size: $font-size-table-emphasized
    line-height: 1

    @media #{map-get($display-breakpoints, 'sm-and-down')}
      font-size: $font-size-table-emphasized-m
  
  .v-card__text
    font-size: $font-size-table
    line-height: $line-height-table

    @media #{map-get($display-breakpoints, 'sm-and-down')}
      font-size: $font-size-table-m
      line-height: $line-height-table-m

.cardlist-item .v-card__text, .cardlist-item .v-card__title
  padding: 0
</style>
