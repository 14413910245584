<template>
  <v-dialog :value="show" max-width="720px" persistent>
    <dialog-content :title="$t('create_briefing.milestones.delete.title')">
      <template #default>
        <p>{{ $t('create_briefing.milestones.delete.text', [ title ]) }}</p>
      </template>
      <template #actions>
        <button-xl :color="color" @click="dismissDeleteDialog">
          {{ $t('generic.cancel') }}
        </button-xl>
        <button-xl emphasize :color="color" @click="submitDeleteDialog">
          {{ $t('generic.ok') }}
        </button-xl>
      </template>
    </dialog-content>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { deleteBriefingMilestone } from '@/api';

export const emptyDeleteDialogData = () => ({
  show: false,
  id: null,
  title: null,
});

export const dismissDeleteDialog = (data) => {
  data.show = false;
  data.id = null;
  data.title = null;
};

export const showDeleteDialog = (data, id, title) => {
  data.show = true;
  data.id = id;
  data.title = title;
};

export default {
  name: 'MilestonesDeleteDialog',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    color: {
      type: String,
      required: true,
    }
  },
  methods: {
    ...mapActions(['saveSuccess']),
    ...mapMutations([ 'saveStart', 'saveError', 'setError' ]),

    dismissDeleteDialog() {
      this.$emit('dismiss');
    },
    async submitDeleteDialog() {
      try {
        this.saveStart();
        await deleteBriefingMilestone(this.id);
        this.saveSuccess();
        this.$emit('success');
      } catch (e) {
        this.saveError(e);
      }
    },
  },
}
</script>
