


















































import { Vue, Component } from 'vue-property-decorator';
import { mapActions, mapMutations, mapState } from 'vuex';
import i18n from '@/i18n';
import { NEW_BRIEFING_SETUP, COLLECTIONS } from '@/routes';
import { required } from '@/util/validation';
//import { createProjectCollection } from '@/api';
import ExternalLayout from '@/components/layout/ExternalLayout.vue';
import InviteForm from '@/components/InviteForm.vue';
import MultiTiles from '@/components/layout/MultiTiles.vue';
import { StoreActions, StoreMutations, StoreState } from '@/store/interfaces';
import { createProjectCollection } from '@/api';

@Component({
  components: {
    MultiTiles,
    ExternalLayout,
    InviteForm,
  },
  computed: {
    ...mapState(['userId']),
  },
  methods: {
    ...mapMutations(['saveStart', 'saveError', 'setProfileNotCompleted']),
    ...mapActions(['updateUser', 'saveSuccess']),
  },
})
export default class Registration extends Vue {
  /*
   * vuex
   */
  readonly userId!: StoreState['userId'];
  readonly saveStart!: StoreMutations.saveStart;
  readonly saveError!: StoreMutations.saveError;
  readonly setError!: StoreMutations.setError;
  readonly saveSuccess!: StoreActions.saveSuccess;
  readonly updateUser!: StoreActions.updateUser;
  readonly setProfileNotCompleted!: StoreMutations.setProfileNotCompleted;

  /*
   * data
   */
  valid = false;
  error: string | null = null;
  form = {
    ProjectName: null,
  };
  projectNameValidation = [ required(i18n.t('registration.first_collection.labels.name')) ];

  /*
   * methods
   */
  async createNewProject() {
    const { ID: collection } = await createProjectCollection({ Title: this.form.ProjectName, User: this.userId });

    await this.updateUser({
      InitialUser: 0
    });

    this.setProfileNotCompleted();

    if (this.$isMobile) {
      this.$router.push({ name: COLLECTIONS });
    } else {
      this.$router.push({ name: NEW_BRIEFING_SETUP, query: { collection } });
    }
  }
}
