import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/i18n/en.json';
import de from '@/i18n/de.json';

Vue.use(VueI18n);

const messages = {
  en,
  de,
};

export default new VueI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages,
});
