import { Component } from 'vue-property-decorator';
import { InputValidationRule } from 'vuetify';
import { required, min, max } from '@/util/validation';
import FieldBase from './FieldBase';

@Component
export default class InputFieldBase extends FieldBase {

  /*
   * data
   */
  data = null;
  validation: InputValidationRule[] = [];

  created() {
    const { Title, Required } = this.value;

    if (Required !== undefined && Required > 0) {
      this.validation.push(required(Title));
    }

    this.createExtraValidationRules();
  }

  /*
   * methods
   */
  createExtraValidationRules() {
    const { Title, MinLength, MaxLength } = this.value;

    if (MinLength !== undefined && MinLength != 0) {
      this.validation.push(min(Title, MinLength));
    }

    if (MaxLength !== undefined && MaxLength != 0) {
      this.validation.push(max(Title, MaxLength));
    }
  }
}
