
import Vue from 'vue';
import { VBtn, VIcon } from 'vuetify/lib';

const childKey = (index: number) => `child-${index}`;

const scrollOffset = 3;

export default Vue.extend({
  name: 'AutoScrollList',
  props: {
    value: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      default: 'black'
    },
    removeNavWhenNotNeeded: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    containerHeight: 0,
    centeredItem: 0,
    scrollOffset: 0,
    children: [] as Array<string>,
    contentHeight: 0,
    minCenterableItem: 0,
    maxCenterableItem: 0,
    resizeHandler: null as (() => void) | null,
  }),
  computed: {
    isScrollingNeeded(): boolean {
      return this.containerHeight < this.contentHeight;
    },
    displayTopNav(): boolean {
      return this.removeNavWhenNotNeeded || this.isScrollingNeeded;
    },
    topNavVisible(): boolean {
      return this.isScrollingNeeded && this.centeredItem !== this.minCenterableItem;
    },
    displayBottomNav(): boolean {
      return this.removeNavWhenNotNeeded || this.isScrollingNeeded;
    },
    bottomNavVisible(): boolean {
      return this.isScrollingNeeded && this.centeredItem !== this.maxCenterableItem;
    }
  },
  watch: {
    value(newValue) {
      if (this.isScrollingNeeded) {
        this.centerItem(newValue);
      }
    },
    scrollOffset(val) {
      (this.$refs.content as HTMLElement).style.transform = `translateY(${-val}px)`
    },
  },
  mounted() {
    this.children = this.$slots.default?.map((child, index) => childKey(index)) || [];
    this.centeredItem = this.value;

    // add global window resize handler
    // window resize could affect list size, we have to handle that
    const handler = () => {
      this.handleChanges();
    };
    window.addEventListener('resize', handler);
    this.resizeHandler = handler;
  },
  beforeDestroy() {
    // cleanup global handler
    if (this.resizeHandler) {
      window.removeEventListener('resize', this.resizeHandler);
    }
  },
  updated() {
    this.handleChanges();
  },
  methods: {
    scroll(offset: number): void {
      this.centerItem(this.centeredItem + offset);
    },
    centerItem(item: number): void {
      item = Math.max(this.minCenterableItem, Math.min(item, this.maxCenterableItem));
      this.centeredItem = item;

      if (this.centeredItem <= this.minCenterableItem) {
        this.scrollOffset = 0;
        return;
      }

      if (this.centeredItem >= this.maxCenterableItem) {
        this.scrollOffset = this.contentHeight - this.containerHeight;
        return;
      }

      const child = this.$refs[this.children[this.centeredItem]] as HTMLElement;

      this.scrollOffset = child.offsetTop - this.containerHeight / 2 + child.clientHeight / 2;
    },
    handleChanges() {
      const newContainerHeight = (this.$refs.container as HTMLElement).clientHeight;
      const newContentHeight = (this.$refs.content as HTMLElement).clientHeight;

      if (newContentHeight === this.contentHeight && newContainerHeight === this.containerHeight) {
        // "viewport" doesn't changed, don't recalculate component parameters unnecessarily
        return;
      }

      this.containerHeight = newContainerHeight;
      this.contentHeight = newContentHeight;

      if (this.isScrollingNeeded) {
        for (let i = 0; i < this.children.length; i++) {
          const child = this.$refs[this.children[i]] as HTMLElement;
          if (child.offsetTop > this.containerHeight / 2 - child.clientHeight / 2) {
            this.minCenterableItem = i - 1;
            break;
          }
        }

        for (let i = this.children.length - 1; i >= 0; i--) {
          const child = this.$refs[this.children[i]] as HTMLElement;

          if (this.contentHeight - child.offsetTop >= this.containerHeight / 2 + child.clientHeight / 2) {
            this.maxCenterableItem = i + 1;
            break;
          }
        }

        this.centerItem(this.value);
      } else {
        this.scrollOffset = 0;
      }
    }
  },
  render(h): Vue.VNode {
    let listItems = this.$slots.default?.map((child, index) => {
      const key = this.children[index];
      return h('div', {
          class: 'auto-scroll-list-item-wrapper',
          ref: key, key
        },
        [child]
      );
    }) || [];

    if (!this.isScrollingNeeded && this.$slots.after) {
      listItems = listItems.concat(this.$slots.after);
    }

    const listContent = h('div', 
      {
        class: 'auto-scroll-container',
        ref: 'container'
      },

      [h('div',
        {
          class: 'auto-scroll-content',
          ref: 'content'
        }, 
        listItems
      )],
    );

    return h('div', 
      { class: 'auto-scroll-list' },

      [
        h(VBtn, {
          props: {
            block: true,
            tile: true,
            text: true,
            color: this.color
          },
          on: { click: () => this.scroll(-scrollOffset) },
          style: {
            'visibility': this.topNavVisible ? 'visible' : 'hidden',
            'display': this.displayTopNav ? 'block' : 'none',
            color: this.color,
          },
        }, [
          h(VIcon, { props: { color: 'black' } }, ['mdi-chevron-up'])
        ]),
        h('div', {
          class: 'auto-scroll-mask-top',
          style: {
            'visibility': this.topNavVisible ? 'visible' : 'hidden',
          },
        }),
        listContent,
        h('div', {
          class: 'auto-scroll-mask-bottom',
          style: {
            'visibility': this.bottomNavVisible ? 'visible' : 'hidden',
          },
        }),
        h(VBtn, {
          props: {
            block: true,
            tile: true,
            text: true,
            color: this.color
          },
          on: { click: () => this.scroll(scrollOffset) },
          style: {
            'visibility': this.bottomNavVisible ? 'visible' : 'hidden',
            'display': this.displayBottomNav ? 'block' : 'none'
          },
        }, [
          h(VIcon, { props: { color: 'black' } }, ['mdi-chevron-down'])
        ]),
        this.isScrollingNeeded ? this.$slots.after : null,
      ]
    );
  }
});
