import i18n from '@/i18n';
import Vue, { PluginFunction } from 'vue';
import { VueConstructor } from 'vue/types/umd';
import initFacebookPixel from './facebookpixel';
import initHotjar from './hotjar';
import { acceptedGTM, revokedGTM } from './gtm';

const CivicUkPlugin: PluginFunction<any> = (v: VueConstructor<Vue>, config: any) => {
  // just to be safe we initialize the $gtm object
  (v as any).$gtm = revokedGTM;

  (window as any).CookieControl.load({
    ...config,
    optionalCookies: [
      {
        name : 'analytics',
        label: i18n.t('cookie_consent.settings.statistic.title'),
        description: i18n.t('cookie_consent.settings.statistic.description'),
        cookies: ['_ga', '_gid', '_gali', '_gat', '__utma', '__utmt', '__utmb', '__utmc', '__utmz', '__utmv'],
        recommendedState: 'on',
        onAccept: () => {
          (v.$gtm as any) = acceptedGTM('GTM-5ZJ6SXX');
          initHotjar();
        },
        onRevoke: () => {
            //CookieControl.deleteAll();
          (v.$gtm as any) = revokedGTM;
        }
      },
      {
        name : 'marketing',
        label: i18n.t('cookie_consent.settings.marketing.title'),
        description: i18n.t('cookie_consent.settings.marketing.description'),
        cookies: ['fr'],
        onAccept: () => {
          initFacebookPixel('748425902399888');
        },
        onRevoke: () => {
          // FB pixel is only loaded on the first Accept.
          // after that revoking is not possible anyway
        }
      }
    ],
  });
};

Vue.use(CivicUkPlugin, {
  apiKey: 'cd8d83c2d23f06bac439c5e89877b2654f979d1f',
  product: 'PRO_MULTISITE',
  initialState: 'notify',
  branding : {
    fontFamily: "TTNorms",
    backgroundColor: '#fff',
    acceptBackground: '#000',
    toggleText: '#000',
    toggleColor: '#f0f0f0',
    toggleBackground: '#fff',
    buttonIcon: null,
    buttonIconWidth: 120,
    buttonIconHeight: 120,
    removeIcon: true,
    removeAbout: true
  },

  text: {
    title: i18n.t('cookie_consent.settings.title'),
    intro: i18n.t('cookie_consent.settings.description'),
    necessaryTitle : i18n.t('cookie_consent.settings.necessary.title'),
    necessaryDescription : i18n.t('cookie_consent.settings.necessary.description'),
    on: i18n.t('cookie_consent.settings.switch.on'),
    off: i18n.t('cookie_consent.settings.switch.off'),
    notifyTitle: i18n.t('cookie_consent.bottom_bar.title'),
    notifyDescription: i18n.t('cookie_consent.bottom_bar.description'),
    accept: i18n.t('cookie_consent.bottom_bar.btn.accept'),
    reject: i18n.t('cookie_consent.bottom_bar.btn.reject'),
    settings: i18n.t('cookie_consent.bottom_bar.btn.settings'),
    acceptSettings: i18n.t('cookie_consent.settings.btn.accept'),
    rejectSettings: i18n.t('cookie_consent.settings.btn.reject'),
    thirdPartyTitle : 'Warning: Some cookies require your attention',
    thirdPartyDescription : 'The consent to the cookies below cannot be automatically revoked. Please follow the link below to opt out of using these services.',
    //acceptRecommended: 'Alle Cookies akzeptieren',
    statement: {
        description: 'For more information please read our',
        name: 'Privacy Policy',
        url: 'https://www.civicuk.com/privacy-statement',
        updated : '08/05/2018'
    }
  },

  position: 'LEFT',
  theme: 'LIGHT'
});
