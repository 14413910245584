import { Vue, Component, Prop } from 'vue-property-decorator';
import CreativeTile from './CreativeTile.vue';
import OperativeTile from './OperativeTile.vue';
import StrategicTile from './StrategicTile.vue';
import { CreateElement, VNode } from 'vue/types/umd';

@Component
export default class BriefingTypeButton extends Vue {
  @Prop({ type: String, required: true }) readonly type!: string;

  render(h: CreateElement): VNode {
    switch(this.type) {
      case 'creative':
        return h(CreativeTile);
      case 'strategic':
        return h(StrategicTile);
      case 'operative':
        return h(OperativeTile);
      default:
        throw new Error(`Unknown briefing type: ${this.type}`);
    }
  } 
}
