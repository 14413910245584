import Vue from 'vue';
import { format } from 'date-fns';
import de from 'date-fns/locale/de';
import fileSize from 'filesize';

const eurFormat = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 });

Vue.use({
  install(Vue) {
    Vue.prototype.$formatDate = (date: Date, pattern: string): string => {
      return format(date, pattern, { locale: de });
    };

    Vue.prototype.$formatFileSize = (bytes: number): string => {
      return fileSize(bytes, { locale: 'de' });
    };

    Vue.prototype.$formatMoney = (amount: number): string => {
      return eurFormat.format(amount) + ',-';
    }
  }
});
