
















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { ParticipantData } from '@/model/userdata';
import { ParticipantFormType, ParticipantDataType } from '@/model/participant';
import { StoreState, StoreActions, StoreMutations } from '@/store/interfaces';

@Component({
  computed: {
    ...mapGetters(['companies']),
  },
  methods: {
    ...mapActions(['createParticipant', 'editParticipant', 'saveSuccess']),
    ...mapMutations(['saveStart', 'saveError']),
  },
})
export default class ParticipantDialog extends Vue {
  $refs!: {
    form: Element & {
      resetValidation: () => void;
    }
  }

  @Prop(Number) readonly id!: number | null;
  @Prop(Object) readonly value!: ParticipantFormType | null;
  @Prop(String) readonly color!: string | undefined;

  @Watch('value') onValueChanged(newValue: ParticipantFormType) {
      this.form = { ...newValue };
  }

  /*
   * data
   */
  valid = false;
  validation = ParticipantData.validation;
  form = ParticipantData.create();

  /*
   * vuex
   */
  companies!: StoreState['companies'];

  createParticipant!: StoreActions.createParticipant;
  editParticipant!: StoreActions.editParticipant;
  saveSuccess!: StoreActions.saveSuccess;

  saveStart!: StoreMutations.saveStart;
  saveError!: StoreMutations.saveError;

  created() {
    if (this.value) {
      this.form = this.value;
    }
  }

  /* 
   * methods
   */
  dismiss() {
    this.reset();
    this.$emit('dismiss');
  }

  reset() {
    this.form = { ...ParticipantData.empty };
    this.$refs.form.resetValidation();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isValid(_: ParticipantFormType): _ is ParticipantDataType {
    return this.valid;
  }

  async submit() {
    if (!this.isValid(this.form)) {
      return;
    }

    try {
      this.saveStart();

      let res;
      if (this.id) {
        res = await this.editParticipant({
          id: this.id,
          form: {
            ...this.form
          }
        });
      } else {
        res = await this.createParticipant({ ...this.form });
      }

      this.saveSuccess();
      this.$emit('success', res);
      this.reset();
    } catch (e) {
      this.saveError(e);
    }
  }
}
