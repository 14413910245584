











import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Route } from 'vue-router';

@Component
export default class MainMenuItem extends Vue {
  @Prop({ type: Object, required: true }) route!: Route;

  /*
   * computed
   */
  get isCurrent() {
    return this.$route.matched.some(matched => matched.name === this.route.name);
  }

  get target() {
    return this.route.meta.defaultChild || this.route.name;
  }
}
