<template>
  <svg
    id="canvas-tile"
    xmlns="http://www.w3.org/2000/svg"
    width="230.088"
    height="238.429"
    viewBox="0 0 230.088 238.429"
  >
    <g transform="translate(-963.912 69.539)">
      <path d="M0,0H185.373V197.169H0Z" transform="matrix(0.966, 0.259, -0.259, 0.966, 1014.944, -69.539)" :fill="color" />
      <text transform="translate(1000.912 101)" fill="#fff" font-size="32"><tspan x="0" y="0">CANVAS</tspan></text>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CanvasTile',
  props: {
    color: {
      type: String,
      required: true,
    },
  }
}
</script>

<style lang="sass">
#canvas-tile
  margin-top: -70px
  margin-right: 0
  height: 238px

  @media (max-width: $applogo-breakpoint)
    margin-top: -76px
</style>
