

































import Vue from 'vue';
import ButtonBase from './ButtonBase';

export default Vue.component('ButtonText', {
  name: 'ButtonText',
  mixins: [ButtonBase],
  props: {
    underlined: {
      type: Boolean,
      default: false,
    },
    download: {
      type: String,
      default: null,
    }
  },
});
