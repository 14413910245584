import { LocationType } from './location';
import { Nullable, ReadonlyPartial } from '@/util/typehelpers';
import { required, FormType, requiredEmail, password } from '@/util/validation';
import i18n from '@/i18n';

export type RegistrationDataType = {
  Email: string;
  FirstName: string;
  Surname: string;
  Password: string;
  Newsletter: number;
}

export type RegistrationFormType = Nullable<RegistrationDataType>;

export const RegistrationFormHelper: FormType<RegistrationDataType> & {
  finalize: (isFormValid: boolean, value: RegistrationFormType) => RegistrationDataType | undefined;
} = {
  empty: {
    Email: null,
    FirstName: null,
    Surname: null,
    Password: null,
    Newsletter: 0,
  },
  validation: {
    FirstName: [ required(i18n.t('profile.labels.firstname')) ],
    Surname: [ required(i18n.t('profile.labels.surname')) ],
    Email: requiredEmail,
    Password: [
      required(i18n.t('login.labels.password')),
      password(i18n.t('login.labels.password')),
    ],
    Newsletter: []
  },
  create(): RegistrationFormType {
    return { ...this.empty };
  },
  finalize(isFormValid: boolean, value: RegistrationFormType) {
    if (!isFormValid) {
      return;
    }

    return {
      ...(value as any),
      Newsletter: value.Newsletter ? 1 : 0,
    };
  }
};

export type PersonalBaseDataType = {
  Email: string;
  Company: string;
};

export type UserDataType = PersonalBaseDataType & {
  FirstName: string;
  Surname: string;
  Position: string;
  Phone: string | null;
} & LocationType;

export type UserFormType = Nullable<UserDataType>;

export type UserEditType = ReadonlyPartial<UserDataType> & {
  InitialUser?: number;
};

export type UserType = UserDataType & {
  ID: number;
  InitialUser: number;
};

export type FeedbackData = {
  Email: string;
  Name: string | null;
  Subject: string | null;
  Request: string;
};

export type FeedbackForm = { [key in keyof FeedbackData]: FeedbackData[key] | null };
