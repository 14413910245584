<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="250"
    height="250"
    viewBox="0 0 250 250"
  >
    <rect width="250" height="250" fill="#86cedc" />
    <text
      x="50%"
      y="225"
      text-anchor="middle"
      fill="#fff"
      font-size="32"
    >{{ $t('briefing.strategic.name').toUpperCase() }}</text>
    <path d="M46.895,115.14H27.333V53.675H46.895Zm34.123,0H61.456V27.333H81.018Zm34.123,0H95.579V80.018H115.14Z" transform="translate(54.464 43.113)" fill="#fff" />
  </svg>
</template>

<script>
export default {
  name: 'BriefingTypeStrategic'
}
</script>
