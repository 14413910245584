<template>
  <div>
    <v-data-table
      class="complex-table"
      item-key="ID"
      :headers="headers"
      :items="clients || []"
      :items-per-page="5"
      :search="search"
      :loading="loading"
      :footer-props="$TABLE_FOOTER_PROPS"
    >
      <template #top>
        <div class="participant-search d-flex align-end justify-space-between">
          <v-text-field
            v-model="search"
            :label="$t('generic.search')"
            hide-details
          />

          <span class="mx-5">{{ $t('generic.or') }}</span>

          <v-dialog v-model="createDialog.shown" persistent max-width="720px">
            <template #activator="{ on }">
              <button-text 
                :loading="loading"
                underlined
                v-on="on"
              >
                + {{ $t('client.create') }}
              </button-text>
            </template>
            <client-dialog @dismiss="closeCreateDialog" @success="closeCreateDialog" />
          </v-dialog>
        </div>
      </template>
      <template #item.CompanyName="{ item }">
        <span class="table-emphasized">{{ item.CompanyName }}</span>
      </template>
      <template #item.action="{ item }">
        <v-icon
          size="18px"
          class="mr-1"
          :title="$t('client.edit')"
          @click="showEditDialog(item)"
        >
          mdi-pencil-outline
        </v-icon>
        <v-icon size="20px" :title="$t('client.delete.title')" @click="showDeleteDialog(item)">
          mdi-delete-outline
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="editDialog.shown" persistent max-width="720px">
      <client-dialog
        :id="editDialog.id"
        :value="editDialog.form"
        @dismiss="closeEditDialog"
        @success="closeEditDialog"
      />
    </v-dialog>

    <v-dialog v-model="deleteDialog.shown" persistent max-width="720px">
      <client-delete-dialog :value="deleteDialog.client" @dismiss="closeDeleteDialog" @success="closeDeleteDialog" />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import i18n from '@/i18n';
import { ClientData } from '@/model/userdata';
import ClientDialog from '@/components/dialogs/ClientDialog.vue';
import ClientDeleteDialog from '@/components/dialogs/ClientDeleteDialog.vue';

export default {
  name: 'ClientsPage',
  components: {
    ClientDialog,
    ClientDeleteDialog,
  },
  data: () => ({
    createDialog: {
      shown: false,
    },
    editDialog: {
      shown: false,
      id: null,
      form: ClientData.create(),
    },
    deleteDialog: {
      shown: false,
      client: null,
    },
    headers: [
      { text: i18n.t('client.labels.name'), value: 'CompanyName' },
      { text: i18n.t('client.labels.street'), value: 'Street' },
      { text: i18n.t('client.labels.location'), value: 'Location' },
      { text: i18n.t('client.labels.country'), value: 'Country' },
      { text: '', value: 'action', align: 'center', width: '5em', sortable: false },
    ],
    search: null,
    isSaving: false,
  }),
  computed: {
    ...mapState(['clients']),
    loading() {
      return this.clients === null || this.isSaving;
    },
  },
  created() {
    this.fetchClients();
  },
  methods: {
    ...mapActions(['fetchClients']),
    closeCreateDialog() {
      this.createDialog.shown = false;
    },
    showEditDialog(client) {
      this.editDialog.shown = true;
      this.editDialog.id = client.ID;
      this.editDialog.form = client;
    },
    closeEditDialog() {
      this.editDialog.shown = false;
      this.editDialog.id = null;
      this.editDialog.form = ClientData.create();
    },
    showDeleteDialog(client) {
      this.deleteDialog.shown = true;
      this.deleteDialog.client = client;
    },
    closeDeleteDialog() {
      this.deleteDialog.shown = false;
      this.deleteDialog.client = null;
    },
  }
}
</script>
