import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import createLogger from 'vuex/dist/logger';
import { getAuth, clearAuth } from '@/util/auth';
import {
  user, updateUser, getBriefingTypes, getProjectParticipants,
  createProjectParticipant, editProjectParticipant, deleteProjectParticipant,
  getClients, createClient, editClient, deleteClient, uploadClientLogo, login, logout
} from '@/api';
import { isInitialUser } from '@/model/userdata';
import { colors } from '@/model/brief';
import i18n from '@/i18n';
import BriefingStoreModule from './briefing';
import { BRIEFING_MODULE_NAME } from './interfaces/BriefingStore';

Vue.use(Vuex);

// eslint-disable-next-line no-undef
const debug = process.env.NODE_ENV !== 'production';

//debug || 
const isDevUser = (user) => !!(user?.Email?.endsWith("@briefing.works"));

// the timeout handle is stored outside of the store,
// because clearTimeout doesn't work on reactive fields
// shouldn't cause big problems since it's not used from outside
let saveSuccessPopupTimeout = null;

const state = {
  userId: getAuth()?.id,
  user: null,

  loading: false,
  error: null,
  successPopupTex: null,

  // the briefing types come from the backend, they are part of the briefing model
  // they should be pretty much static though and used almost everywhere
  // so cache them in the global store
  briefingTypes: null,

  participants: null,
  clients: null,

  dontShowMobileWarningDialog: false,
  dontShowTabletWarningDialog: false,
  profileNotCompleted: false,
};

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    dontShowMobileWarningDialog: state.dontShowMobileWarningDialog,
    dontShowTabletWarningDialog: state.dontShowTabletWarningDialog,
    profileNotCompleted: state.profileNotCompleted
  }),
})

const plugins = [ vuexLocal.plugin ];

if (debug) {
  plugins.push(createLogger());
}

export default new Vuex.Store({
  state,
  // disallow mutations outside of the mutation handlers
  // only enable it for debugging in dev mode
  // it has a serious performance penalty!
  strict: debug,
  plugins,

  modules: {
    [BRIEFING_MODULE_NAME]: BriefingStoreModule
  },

  getters: {
    isAuthenticated: (state) => state.userId != null,
    isInitialUser: (state) => isInitialUser(state.user),
    isDevUser: (state) => isDevUser(state.user),
    companies: (state) => state.participants
      ?.filter(i => i.Company)
      ?.map(i => i.Company)
  },
  mutations: {
    setUserId: (state, id) => state.userId = id,
    setUser: (state, user) => {
      // initial page load, or login
      if (state.user === null && isDevUser(user)) {
        i18n.locale = 'en';
      }

      state.user = user;
    },
    clearUser: (state) => {
      state.user = null;
      state.userId = null;
    },

    loadStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    loadSuccess: (state) => {
      state.loading = false;
      state.error = null;
    },
    loadError: (state, e) => {
      state.error = e;
      state.loading = false;
    },
    saveStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    saveError: (state, e) => {
      state.loading = false;
      state.error = e;
    },
    setError: (state, e) => {
      state.error = e;
    },
    setParticipants: (state, participants) => {
      state.participants = participants;
    },
    addParticipant: (state, p) => {
      state.participants.push(p);
    },
    replaceParticipant: (state, newParticipant) => {
      state.participants = state.participants.map(p => p.ID === newParticipant.ID ? newParticipant : p);
    },
    removeParticipant: (state, id) => {
      state.participants = state.participants.filter(c => c.ID !== id);
    },
    setClients: (state, clients) => {
      state.clients = clients;
    },
    addClient: (state, c) => {
      state.clients.push(c);
    },
    replaceClient: (state, changed) => {
      state.clients = state.clients.map(c => c.ID === changed.ID ? changed : c);
    },
    removeClient: (state, id) => {
      state.clients = state.clients.filter(c => c.ID !== id);
    },
    dismissError: (state) => state.error = null,
    showSaveSuccessPopup: (state, text) => state.successPopupTex = text,
    autoCloseSaveSuccessPopup: (state) => {
      saveSuccessPopupTimeout = null;
      state.successPopupTex = null;
    },
    dismissSaveSuccessPopup: (state) => {
      clearTimeout(saveSuccessPopupTimeout);
      saveSuccessPopupTimeout = null;
      state.successPopupTex = null;
    },
    setBriefingTypes: (state, value) => state.briefingTypes = value,
    dismissMobileWarningDialog: (state) => {
      state.dontShowMobileWarningDialog = true;
    },
    dismissTabletWarningDialog: (state) => {
      state.dontShowTabletWarningDialog = true;
    },
    setProfileNotCompleted: (state) => {
      state.profileNotCompleted = true;
    },
    dismissProfileNotCompletedDialog: (state) => {
      state.profileNotCompleted = false;
    }
  },
  actions: {
    saveSuccess: ({ commit }) => {
      commit('showSaveSuccessPopup', i18n.t('save_successful'));
      commit('loadSuccess');
      saveSuccessPopupTimeout = setTimeout(() => {
        commit('autoCloseSaveSuccessPopup');
      }, 5000);
    },
    showSnackbar: ({ commit }, text) => {
      commit('showSaveSuccessPopup', text);
      saveSuccessPopupTimeout = setTimeout(() => {
        commit('autoCloseSaveSuccessPopup');
      }, 5000);
    },
    getUser: async ({ state, commit }) => {
      if (state.user != null) {
        return state.user;
      }

      if (state.userId != null) {
        try {
          commit('loadStart');
          const res = await user(state.userId);

          if (res) {
            commit('setUser', res);
            commit('loadSuccess');
            return res;
          } else {
            throw new Error('No userdata in server response.');
          }
        } catch (e) {
          commit('loadError', e);
          throw e;
        }
      } else {
        throw new Error('No user id!');
      }
    },
    updateUser: async ({ state, commit, dispatch }, data) => {
      commit('saveStart');
      await updateUser(state.userId, data);
      commit('setUser', {
        ...state.user,
        ...data
      });
      dispatch('saveSuccess');
    },
    getBriefingTypes: async ({ state, commit }) => {
      if (!state.briefingTypes) {
        const res = await getBriefingTypes();

        res.forEach((t) => {
          t.Color = colors[t.BriefingType];
        });

        commit('setBriefingTypes', res);
        return res;
      }
      
      return state.briefingTypes;
    },
    fetchParticipants: async ({ commit }) => {
      commit('setParticipants', null);
      const res = await getProjectParticipants();
      commit('setParticipants', res);
      return res;
    },
    createParticipant: async ({ commit }, form ) => {
      const res = await createProjectParticipant({
        Title: form.Title,
        Email: form.Email,
        Company: form.Company,
      });

      commit('addParticipant', res);

      return res;
    },
    editParticipant: async ({ commit }, { id, form }) => {
      const res = await editProjectParticipant(id, {
        Title: form.Title,
        Email: form.Email,
        Company: form.Company,
      });

      commit('replaceParticipant', res);

      return res;
    },
    deleteParticipant: async ({ commit }, id) => {
      await deleteProjectParticipant(id);
      commit('removeParticipant', id);
    },
    fetchClients: async ({ commit }) => {
      commit('setClients', null);
      const res = await getClients();
      commit('setClients', res);
      return res;
    },
    createClient: async ({ commit }, form ) => {
      const res = await createClient(form);
      commit('addClient', res);
      return res;
    },
    editClient: async ({ commit }, { id, form }) => {
      const res = await editClient(id, form);
      commit('replaceClient', res);
      return res;
    },
    uploadClientLogo: async ({ commit, state }, { id, logo }) => {
      const req = new FormData();
      req.append('file', logo);
      const { URL } = await uploadClientLogo(id, req);
      commit('replaceClient', {
        ...state.clients.find((c) => c.ID === id),
        Logo: URL,
      });
    },
    deleteClient: async ({ commit }, id) => {
      await deleteClient(id);
      commit('removeClient', id);
    },
    login: async ({ commit }, { email, password }) => {
      const id = await login(email, password);

      if (id != undefined) {
        commit('setUserId', id);

        Vue.$gtm.action('login', { userId: id });
      }

      return id;
    },
    logout: async ({ commit }) => {
      await logout();
      commit('clearUser');
      clearAuth();

      Vue.$gtm.action('logout', { userId: undefined });
    }
  }
});
