var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"briefing-participants pa-0"},[(_vm.added.length === 0)?_c('v-row',{staticClass:"mt-4 mb-4"},[_c('v-col',{attrs:{"cols":"5"}},[_c('button-text',{attrs:{"underlined":"","color":_vm.color,"loading":_vm.loading,"disabled":!_vm.available && _vm.available.length === 0},on:{"click":_vm.showSelectDialog}},[_vm._v(" + "+_vm._s(_vm.$t('client.select.title'))+" ")])],1),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t('generic.or'))+" ")]),_c('v-col',{attrs:{"cols":"5"}},[_c('button-text',{attrs:{"underlined":"","color":_vm.color,"loading":_vm.loading},on:{"click":_vm.showCreateDialog}},[_vm._v(" + "+_vm._s(_vm.$t('client.create'))+" ")])],1)],1):_c('h2',{staticClass:"briefing-field-title participants-added"},[_vm._v(" "+_vm._s(_vm.$t('client.added'))+": ")]),(_vm.added.length > 0)?_c('card-list',{attrs:{"value":_vm.added,"can-delete":""},on:{"deleted":_vm.showDeleteDialog},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.CompanyName || ' '))])]}},{key:"default",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.Email || ' '))])]}}],null,false,572438798)}):_vm._e(),_c('v-dialog',{attrs:{"value":_vm.selectDialog.shown,"max-width":"720px","persistent":""}},[_c('dialog-content',{attrs:{"title":_vm.$t('client.select.title')},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('button-xl',{attrs:{"color":_vm.color},on:{"click":function($event){$event.preventDefault();return _vm.dismissSelectDialog($event)}}},[_vm._v(" "+_vm._s(_vm.$t('generic.cancel'))+" ")]),_c('button-xl',{attrs:{"emphasize":"","color":_vm.color,"disabled":_vm.selectDialog.selected === null},on:{"click":function($event){$event.preventDefault();return _vm.submitSelectDialog($event)}}},[_vm._v(" "+_vm._s(_vm.$t('generic.ok'))+" ")])]},proxy:true}])},[(_vm.selectDialog.shown)?_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSelectDialogNext($event)}}},[_c('p',[_vm._v(_vm._s(_vm.$t('client.select.description')))]),_c('div',{staticClass:"participant-search d-flex align-end justify-space-between"},[_c('v-text-field',{attrs:{"label":_vm.$t('generic.search'),"color":_vm.color,"hide-details":""},model:{value:(_vm.selectDialog.search),callback:function ($$v) {_vm.$set(_vm.selectDialog, "search", $$v)},expression:"selectDialog.search"}}),_c('span',{staticClass:"mx-3"},[_vm._v(" "+_vm._s(_vm.$t('generic.or'))+" ")]),_c('button-text',{attrs:{"underlined":"","color":_vm.color,"loading":_vm.loading},on:{"click":_vm.showCreateDialogFromSelect}},[_vm._v(" + "+_vm._s(_vm.$t('client.create'))+" ")])],1),_c('v-item-group',{attrs:{"value":_vm.selectedIndex,"multiple":false},on:{"change":_vm.onSelectionChange}},[_c('div',{staticClass:"d-flex flex-wrap justify-center"},_vm._l((_vm.available),function(item,i){return _c('v-item',{key:i,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('div',{staticClass:"tile-field-item",class:{ active: active },style:({ color: _vm.color }),on:{"click":toggle}},[(item.Logo)?_c('img',{staticClass:"tile-field-image",attrs:{"src":item.Logo,"title":item.CompanyName,"alt":item.CompanyName}}):_c('span',{staticClass:"tile-field-placeholder d-flex align-center justify-center"},[_vm._v(_vm._s(item.CompanyName))])])]}}],null,true)})}),1)])],1):_vm._e()],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"720px"},model:{value:(_vm.createDialog.shown),callback:function ($$v) {_vm.$set(_vm.createDialog, "shown", $$v)},expression:"createDialog.shown"}},[_c('client-dialog',{attrs:{"color":_vm.color,"after-created":_vm.addClientHelper},on:{"dismiss":_vm.closeCreateDialog,"success":_vm.newClientCreated}})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"720px"},model:{value:(_vm.deleteDialog.shown),callback:function ($$v) {_vm.$set(_vm.deleteDialog, "shown", $$v)},expression:"deleteDialog.shown"}},[_c('dialog-content',{attrs:{"title":_vm.$t('client.unselect.title')},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('button-xl',{attrs:{"color":_vm.color},on:{"click":_vm.closeDeleteDialog}},[_vm._v(" "+_vm._s(_vm.$t('generic.cancel'))+" ")]),_c('button-xl',{attrs:{"emphasize":"","color":_vm.color},on:{"click":_vm.submitDelete}},[_vm._v(" "+_vm._s(_vm.$t('generic.ok'))+" ")])]},proxy:true}])},[_c('p',[_vm._v(_vm._s(_vm.$t('client.unselect.text', [_vm.deleteDialog.client ? _vm.deleteDialog.client.CompanyName : ''])))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }