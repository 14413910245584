<template>
  <v-menu
    v-model="menu"
    content-class="briefing-datepicker-menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template #activator="{ on }">
      <v-text-field
        :value="formattedDate"
        :label="label"
        :rules="rules"
        prepend-inner-icon="mdi-calendar"
        readonly
        :color="briefingType.Color"
        :loading="isSaving"
        :disabled="isSaving"
        :hide-details="hideDetails"
        :single-line="singleLine"
        v-on="on"
      />
    </template>
    <v-date-picker
      :value="pickerDate"
      class="briefing-datepicker"
      :class="briefingType.BriefingType"
      :disabled="isSaving"
      width="344px"
      first-day-of-week="1"
      no-title
      show-current
      :min="min"
      :max="max"
      @input="onInput"
    />
  </v-menu>
</template>

<script>
import { parseISO, formatISO, isValid } from 'date-fns';

const parseDate = (v) => {
  const parsed = parseISO(v);

  if (isValid(parsed)) {
    return parsed;
  } else {
    return null;
  }
}

export default {
  name: 'DatePicker',
  props: {
    value: {
      type: String,
      default: undefined,
    },
    startWith: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    briefingType: {
      type: Object,
      required: true,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: undefined,
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    singleLine: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    menu: false,
    date: null,
  }),
  computed: {
    formattedDate() {
      if (isValid(this.date)) {
        return this.$formatDate(this.date, 'P');
      } else {
        return null;
      }
    },
    isoDate() {
      if (isValid(this.date)) {
        return formatISO(this.date, { representation: 'date' });
      } else {
        return null;
      }
    },
    pickerDate() {
      return this.isoDate || this.startWith;
    },
  },
  watch: {
    value(newValue) {
      if (newValue !== this.isoDate) {
        this.date = parseDate(newValue);
      }
    }
  },
  created() {
    this.date = parseDate(this.value);
  },
  methods: {
    onInput(e) {
      this.menu = false;
      this.$emit('input', e);
    }
  }
}
</script>

<style lang="sass">
$table-parts-gap: 1px solid white

.briefing-datepicker-menu
  border-radius: 0

.briefing-datepicker.v-picker
  border-radius: 0px
  box-shadow: none

  .v-date-picker-header
    padding: 0px 
    width: 344px
    border: 1px solid white
    border-bottom: $table-parts-gap
    font-size: $font-size-root
    font-weight: $font-bold
    line-height: 1

    button
      color: white !important
    
    .v-date-picker-header__value button
      text-transform: uppercase

  .v-date-picker-table
    height: auto

  .v-date-picker-table--date
    padding: 0px
    
    table
      border-collapse: separate
      border-spacing: 1px

    th
      color: white
      font-size: 9pt
      line-height: 1
      padding: 3px 0px
      border: 0px
      border-bottom: $table-parts-gap
    
    td
      line-height: 1

    .v-btn
      border-radius: 0
      width: 48px
      height: 35px
      color: white !important
      border: none
      font-size: 15pt
      font-weight: $font-normal

      &.v-btn--active
        color: white !important
      
      &.v-date-picker-table__current
        font-weight: $font-extrabold

// create the different datepicker color themes
// using a SASS mixin, avoiding duplication
@mixin datepicker-color($colors)
  .v-date-picker-header
    background-color: map-get($colors, "primary")
  .v-date-picker-table--date
    th
      background-color: map-get($colors, "primary")
    .v-btn
      background-color: map-get($colors, "primary")
      &.v-btn--active
        background-color: map-get($colors, "select-overlayed") !important
      &.v-date-picker-table__current
        color: map-get($colors, "primary")
        background-color: map-get($colors, "current")
      &.v-btn--disabled
        background-color: $gray-color

.briefing-datepicker.strategic
  @include datepicker-color($strategic-colors)

.briefing-datepicker.creative
  @include datepicker-color($creative-colors)

.briefing-datepicker.operative
  @include datepicker-color($operative-colors)
</style>
