<template>
  <internal-layout>
    <template #header>
      <canvas-tile v-if="briefingType" :color="briefingType.Color" />
    </template>

    <template #mobile-header>
      <canvas-tile v-if="briefingType" :color="briefingType.Color" />
    </template>

    <template default>
      <success-screen v-if="briefingType" :title="$t('share.title')" :button-groups="buttonGroups">
        <p>{{ $t('share.text') }}</p>
      </success-screen>
    </template>
  </internal-layout>
</template>

<script>
import { mapActions } from 'vuex';
import i18n from '@/i18n';
import { COLLECTIONS, BRIEF_CANVAS, NEW_BRIEFING_SETUP } from '@/routes';
import { getBriefingTypeOf } from '@/model/brief';
import InternalLayout from '@/components/layout/InternalLayout.vue';
import SuccessScreen from '@/components/SuccessScreen.vue';
import CanvasTile from '@/components/decoration/CanvasTile.vue';

export default {
  name: 'SharePage',
  components: {
    InternalLayout,
    SuccessScreen,
    CanvasTile,
  },
  props: {
    uuid: {
      type: String,
      required: true,
    }
  },
  data: () => ({
    briefingType: null,
  }),
  computed: {
    buttonGroups() { 
      return [
        { title: i18n.t('share.to.title'), buttons: [
          { title: i18n.t('share.to.collections'), to: { name: COLLECTIONS } },
          { title: i18n.t('share.to.new_briefing'), to: { name: NEW_BRIEFING_SETUP } },
          { title: i18n.t('share.to.canvas'), to: { name: BRIEF_CANVAS, params: { uuid: this.uuid } }, emphasize: true },
        ]}
      ];
    }
  },
  async created() {
    this.briefingType = await getBriefingTypeOf(this.uuid);
  },
  methods: {
    ...mapActions(['getBriefingTypes']),
  }
}
</script>
