import { render, staticRenderFns } from "./TrashBin.vue?vue&type=template&id=589ac7bc&"
import script from "./TrashBin.vue?vue&type=script&lang=js&"
export * from "./TrashBin.vue?vue&type=script&lang=js&"
import style0 from "./TrashBin.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VDataTable,VDialog,VForm,VSwitch})
