export type ParticipantPermission = "viewer" | "editor";
export const ParticipantPermissions: ParticipantPermission[] = [ 'viewer', 'editor' ];

export type BriefingRoleType = {
  Role: string;
  Permission: ParticipantPermission;
}

export type ParticipantDataType = {
  Title: string;
  Company: string;
  Email: string;
}

export type ParticipantFormType = {
  [key in keyof ParticipantDataType]: ParticipantDataType[key] | null
};

export type ParticipantType = ParticipantDataType & {
  ID: number;
}

type BriefingParticipantDataType = ParticipantDataType & BriefingRoleType;

export type BriefingParticipantFormType = ParticipantFormType & {
  Role: string | null;
  Permission: number;
};

export type BriefingParticipantType = BriefingParticipantDataType & {
  ID: number;
}
