<template>
  <div class="matrix-chart-field">
    <h2 v-if="value.Title" class="briefing-field-title">
      {{ value.Title }}
    </h2>

    <p
      v-if="value.FieldDescription"
      class="briefing-field-description"
      v-html="value.FieldDescription"
    />

    <div class="matrix-chart-wrapper">
      <matrix-chart
        ref="matrix"
        draggable
        :data="points"
        :color="color"
        :label-x="labelX"
        :label-y="labelY"
        @change="onPointDragged"
      />
    </div>

    <v-container>
      <h2>Chart</h2>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="labelX"
            :label="$t('create_briefing.matrix_chart.axis_x')"
            :loading="isSaving"
            @change="onAxisXChanged"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="labelY"
            :label="$t('create_briefing.matrix_chart.axis_y')"
            :loading="isSaving"
            @change="onAxisYChanged"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col v-for="(d, i) in points" :key="i" cols="6">
          <v-text-field
            v-model="d.Title"
            :label="$t('create_briefing.matrix_chart.company')"
            :loading="isSaving"
            :append-outer-icon="i > 0 ? 'mdi-delete-outline' : null"
            hide-details
            @change="onLabelChanged(i)"
            @click:append-outer="deleteBubble(i)"
          />
        </v-col>
      </v-row>
      <add-new-value :color="color" :label="$t('create_briefing.matrix_chart.company')" @input="addBubble" />
    </v-container>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import i18n from '@/i18n';
import {
  createMatrixChartField,
  updateMatrixChartField,
  createMatrixChartValue,
  deleteMatrixChartValue,
  editMatrixChartValue
} from '@/api';
import { convertMatrixChartValueFromAPI } from '@/model/charts';
import { sortFormData } from '@/model/brief';
import FieldBase from './FieldBase';
import AddNewValue from './AddNewValue.vue';
import MatrixChart from '@/components/common/MatrixChart.vue';

export default {
  name: 'MatrixChartField',
  components: {
    MatrixChart,
    AddNewValue,
  },
  mixins: [ FieldBase ],
  data: () => ({
    labelX: '',
    labelY: '',
    points: [
      {
        Title: i18n.t('create_briefing.matrix_chart.my_company'),
        ValueX: 0,
        ValueY: 0,
        Sort: 1,
      },
    ]
  }),
  created() {
    this.labelX = this.value.SubmittedData?.AxisX ?? '';
    this.labelY = this.value.SubmittedData?.AxisY ?? '';

    if (this.value.SubmittedData?.MatrixOptions?.length > 0) {
      this.points = this.value.SubmittedData.MatrixOptions
        .sort(sortFormData)
        .map(convertMatrixChartValueFromAPI);
    }
  },
  methods: {
    ...mapMutations(['setError']),
    async addBubble(Title) {
      try {
        this.isSaving = true;
        const fieldId = await this.getSubmittedFieldId();

        const res = await createMatrixChartValue(fieldId, {
          Title,
          ValueX: 0,
          ValueY: 0,
          Sort: this.points.length + 1
        });

        this.points.push(convertMatrixChartValueFromAPI(res));
        this.onMatrixChanged();
      } catch (e) {
        this.setError(e);
      } finally {
        this.isSaving = false;
      }
    },
    async deleteBubble(index) {
      try {
        this.isSaving = true;
        const fieldId = await this.getSubmittedFieldId();
        await deleteMatrixChartValue(fieldId, this.points[index].ID);
        this.points.splice(index, 1);

        // fix Sort numbers
        this.points
          .filter((_, i) => i >= index)
          .forEach((point) => {
            point.Sort -= 1;
            editMatrixChartValue(fieldId, point.ID, {
              ...point
            });
          });

        this.onMatrixChanged();
      } catch (e) {
        this.setError(e);
      } finally {
        this.isSaving = false;
      }
    },
    onMatrixChanged() {
      this.$refs.matrix.reRender();
    },
    async onLabelChanged(index) {
      this.onPointChanged(index);
      this.onMatrixChanged();
    },
    async onPointDragged({ index, value }) {
      const point = this.points[index];

      point.ValueX = value.x;
      point.ValueY = value.y;

      await this.onPointChanged(index);
      // chart is already updated, doesn't need rerender in this case!
    },
    async onPointChanged(index) {
      try {
        this.isSaving = true;
        const fieldId = await this.getSubmittedFieldId();
        const point = this.points[index];
        await editMatrixChartValue(fieldId, point.ID, {
          ...point,
        });
      } catch (e) {
        this.setError(e);
      } finally {
        this.isSaving = false;
      }
    },
    async axisChangeHelper(data) {
      try {
        this.isSaving = true;
        const fieldId = await this.getSubmittedFieldId();
        this.value.SubmittedData = await updateMatrixChartField(fieldId, data);
      } catch (e) {
        this.setError(e);
      } finally {
        this.isSaving = false;
      }
    },
    async onAxisXChanged(value) {
      await this.axisChangeHelper({
        AxisX: value
      });
    },
    async onAxisYChanged(value) {
      await this.axisChangeHelper({
        AxisY: value
      });
    },
    async getSubmittedFieldId() {
      if (!this.submittedId) {
        // force creation of the submitted field

        const { ID: fid, Parent, FieldType, Name, Sort, Title, } = this.value;

        let body = {
          ParentID: this.submittedFormId,
          ParentElementID: Parent,
          BriefingFieldClassName: FieldType,
          Name,
          Sort,
          Title,
          RelatedFieldID: fid,
          AxisX: this.labelX,
          AxisY: this.labelY,
        };

        this.value.SubmittedData = await createMatrixChartField(body);
        this.submittedId = this.value.SubmittedData.ID;

        const res = await createMatrixChartValue(this.submittedId, {
          ...this.points[0]
        });

        this.points[0] = convertMatrixChartValueFromAPI(res);
      }

      return this.submittedId;
    },
  }
}
</script>

<style lang="sass">
.matrix-chart-field .col
  padding-top: 0
</style>
