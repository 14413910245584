








import Component from 'vue-class-component';
import { field, PdfDisplayable, singleTextField } from '@/pdf/components';
import { stylizeUserText } from '@/util/data';
import ElementBase from './ElementBase';

@Component
export default class CanvasTextarea extends ElementBase implements PdfDisplayable {
  /*
   * computed
   */
  get isRenderable() {
    return this.content !== null;
  }

  get htmlContent() {
    return stylizeUserText(this.content as string | null | undefined, this.briefingType.BriefingType);
  }

  /*
   * methods
   */
  renderPdfContent() {
    if (!this.isRenderable) {
      return undefined;
    }

    return field([
      singleTextField(this.value, this.content?.toString() || '')
    ]);
  }
}
