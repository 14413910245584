






















































































import { Vue, Component } from 'vue-property-decorator';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { mapBriefingState, BriefingStoreState } from '@/store/interfaces/BriefingStore';
import * as BriefingStoreMutations from '@/store/interfaces/BriefingMutations';
import * as BriefingStoreActions from '@/store/interfaces/BriefingActions';
import { BriefingParticipantData } from '@/model/userdata';
import { ParticipantFormType, ParticipantDataType, ParticipantPermissions } from '@/model/participant';
import { StoreState, StoreActions, StoreMutations } from '@/store/interfaces';
import InputLabel from '@/components/common/InputLabel.vue';

@Component({
  components: {
    InputLabel
  },
  computed: {
    ...mapBriefingState(['briefingType']),
    ...mapGetters(['companies']),
  },
  methods: {
    ...mapActions(['createParticipant', 'editParticipant', 'saveSuccess']),
    ...mapMutations(['saveStart', 'saveError']),
    ...BriefingStoreMutations.mapBriefingMutations(['closeCreateParticipantDialog']),
    ...BriefingStoreActions.mapBriefingActions(['addRole']),
  },
})
export default class ParticipantDialog extends Vue {
  $refs!: {
    form: Element & {
      resetValidation: () => void;
    }
  }

  /*
   * data
   */
  valid = false;
  validation = BriefingParticipantData.validation;
  form = BriefingParticipantData.create();
  permissions = ParticipantPermissions;

  /*
   * vuex
   */
  readonly briefingType!: BriefingStoreState['briefingType'];
  readonly companies!: StoreState['companies'];

  readonly closeCreateParticipantDialog!: BriefingStoreMutations.closeCreateParticipantDialog;

  readonly createParticipant!: StoreActions.createParticipant;
  readonly editParticipant!: StoreActions.editParticipant;
  readonly saveSuccess!: StoreActions.saveSuccess;

  readonly saveStart!: StoreMutations.saveStart;
  readonly saveError!: StoreMutations.saveError;

  readonly addRole!: BriefingStoreActions.addRole;

  /* 
   * methods
   */
  dismiss() {
    this.closeCreateParticipantDialog();
    this.reset();
  }

  reset() {
    this.form = { ...BriefingParticipantData.empty };
    this.$refs.form.resetValidation();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isValid(_: ParticipantFormType): _ is ParticipantDataType {
    return this.valid;
  }

  async submit() {
    if (!this.isValid(this.form)) {
      return;
    }

    try {
      this.saveStart();

      let participant = await this.createParticipant(BriefingParticipantData.copyParticipantData(this.form));
      await this.addRole({
        participantId: participant.ID,
        data: BriefingParticipantData.copyRoleData(this.form),
      });

      this.saveSuccess();
      this.dismiss();
    } catch (e) {
      this.saveError(e);
    }
  }
}
