export const SPIDER_CHART_MAX_VALUE = 10;

export interface SpiderChartFormValue {
  readonly Title: string;
  readonly Value: number;
  readonly Sort: number;
}

export interface SpiderChartAPIValue extends SpiderChartFormValue {
  readonly ID: number;
}

export interface SpiderChartJSValue {
  readonly id: number;
  label: string;
  value: number;
}

export const convertSpiderChartValueFromAPI = (v: SpiderChartAPIValue): SpiderChartAPIValue => ({
  ID: v.ID,
  Title: v.Title,
  Value: v.Value,
  Sort: v.Sort,
});

// export const convertSpiderChartValueFromAPI = (v: SpiderChartAPIValue): SpiderChartJSValue => ({
//   id: v.ID,
//   label: v.Title,
//   value: v.Value,
// });

export const convertSpiderChartValueToAPI = (v: SpiderChartJSValue, index: number): SpiderChartAPIValue => ({
  ID: v.id,
  Title: v.label,
  Value: v.value,
  Sort: index
});

export const createNewSpiderChartValue = (Title: string, index: number): SpiderChartFormValue => ({
  Title,
  Value: SPIDER_CHART_MAX_VALUE,
  Sort: index
});

export interface MatrixChartFormValue {
  readonly Title: string;
  readonly ValueX: number;
  readonly ValueY: number;
  readonly Sort: number;
}

export interface MatrixChartAPIValue extends MatrixChartFormValue {
  readonly ID: number;
}

export const convertMatrixChartValueFromAPI = (v: MatrixChartAPIValue): MatrixChartAPIValue => ({
  ID: v.ID,
  Title: v.Title,
  ValueX: v.ValueX,
  ValueY: v.ValueY,
  Sort: v.Sort,
});
