import { StyleDictionary, CustomTableLayout, ContentTable } from 'pdfmake/interfaces';

export const TTNORMS = 'TTNorms';
export const TTNORMS_EXTRALIGHT = 'TTNorms-Extralight';
export const MATERIAL_ICONS = 'MaterialIcons';

export const lineColor = '#e5e5e5';

export const ITEM_MARGIN = 19;
export const FONT_BASE = 12


export const STYLES = {
  TITLE: 'title',
  SUBTITLE: 'subtitle',
  STEP_TITLE: 'step-title',
  GROUP_TITLE: 'group-title',
  FIELD_TITLE: 'field-title',
  SINGLE_TEXTFIELD: 'single-text-field',
  OPTIONS_FIELD: 'options-field',
  YESNO_ACTIVE: 'yesno-active',
  YESNO_INACTIVE: 'yesno-inactive',
  FIELD_WRAPPER: 'field-wrapper',
  FIELD_GROUP_WRAPPER: 'field-group-wrapper',
  ICON: 'icon'
};

export const TABLE_LAYOUTS = {
  FIELD_TABLE: 'fieldtable',
};

export const styles: StyleDictionary = {
  toc: {
    fontSize: 18,
    bold: true,
    margin: [0, 0, 0, 20]
  },
  [STYLES.TITLE]: {
    fontSize: 48,
    bold: true,
  },
  [STYLES.SUBTITLE]: {
    fontSize: 24,
  },
  [STYLES.STEP_TITLE]: {
    fontSize: 24,
    bold: true,
    decoration: 'underline',
  },
  [STYLES.GROUP_TITLE]: {
    fontSize: 18,
    bold: true,
  },
  [STYLES.FIELD_TITLE]: {
    fontSize: FONT_BASE,
    bold: true,
  },
  [STYLES.SINGLE_TEXTFIELD]: {
    fontSize: FONT_BASE,
  },
  [STYLES.OPTIONS_FIELD]: {
    fontSize: FONT_BASE,
  },
  [STYLES.YESNO_ACTIVE]: {
    fontSize: FONT_BASE,
  },
  [STYLES.YESNO_INACTIVE]: {
    fontSize: FONT_BASE,
    color: '#B5B5B5'
  },
  [STYLES.ICON]: {
    font: MATERIAL_ICONS
  }
};

export const tableLayouts: { [name: string]: CustomTableLayout } = {
  [TABLE_LAYOUTS.FIELD_TABLE]: {
    vLineWidth: () => 0,
    hLineWidth: (rowIndex: number, node: ContentTable) => {
      if (rowIndex === 0 || rowIndex === node.table.body.length) {
        return 0;
      }
  
      return 1;
    },
    hLineColor: (rowIndex: number) => rowIndex > 1 ? '#d3d4d6' : 'black',
    paddingLeft: (columnIndex: number) => {
      return columnIndex > 0 ? 14 : 0;},
    paddingRight: () => 0,
  }
};

