<template>
  <div class="switchset briefing-field">
    <h2 v-if="value.Title" class="briefing-field-title">
      {{ value.Title }}
    </h2>
    <p v-if="value.FieldDescription" class="briefing-field-description" v-html="value.FieldDescription" />
    <v-switch
      v-for="o in options"
      :key="o.value"
      v-model="data"
      class="align-center"
      :class="briefingType.BriefingType"
      :label="'' + o.text"
      :color="color"
      :value="o.value"
      :loading="isSaving"
      :disabled="isSaving"
      hide-details
      @change="onChange"
    />

    <add-user-option
      v-if="customOptionsAllowed"
      :color="color"
      :next-index="options.length"
      :get-parent-id="getSubmittedFieldId"
      @option-added="onOptionAdded"
    />
  </div>
</template>

<script>
import FieldBase from './FieldBase';
import AddUserOption from './AddUserOption.vue';

export default {
  name: 'SwitchSetField',
  components: {
    AddUserOption
  },
  mixins: [ FieldBase ],
  data: () => ({
    // TODO
    validation: [],
  }),
  computed: {
    options() {
      return this.value.Options;
    }
  },
  created() {
    if (this.data) {
      this.data = this.data.split(',');
    } else {
      this.data = [];
    }

    this.update();
  },
  methods: {
    transformSubmitBody(body) {
      return {
        ...body,
        IsModelOption: this.value.Model !== undefined ? '1' : '0'
      };
    },
    update() {
      this.onUpdated({
        id: this.value.ID,
        value: this.data.map(v =>
          this.value.Options
            .find(o => o.value === v)
            ?.matchValue
        ),
      });
    },
    async onChange() {
      try {
        this.isSaving = true;

        if (this.data.length > 0) {
          await this.submit(this.data.join(','));
        } else {
          await this.submit(null);
        }

        this.update();
      } catch (e) {
        this.setError(e);
      }

      this.isSaving = false;
    },
    onOptionAdded(newOption) {
      const { ID, Title } = newOption;

      this.value.Options.push({
        text: Title,
        value: ID
      });

      this.data.push(ID);

      this.onChange();
    },
  }
}
</script>

<style lang="sass">
.switchset
  .v-input--switch.v-input--selection-controls
    height: 50px
    margin: 0
    padding-top: 0
</style>
