<template>
  <div class="files-table briefing-field">
    <v-simple-table class="gray-text">
      <template #default>
        <tbody>
          <tr v-for="(f, i) in ordered" :key="`file-${i}`">
            <td class="name">
              <span class="table-emphasized">{{ f.TagValue }}</span>
              <br v-if="$isTabletOrMobile">
              <span v-if="$isTabletOrMobile" class="ext">{{ f.Extension }}</span>
            </td>
            <td v-if="!$isTabletOrMobile" class="ext">
              {{ f.Extension }}
            </td>
            <td class="actions">
              <a :href="f.URL" download>
                <v-icon size="20px" :title="$t('generic.download')" class="mr-1">
                  mdi-download-outline
                </v-icon>
              </a>
              <v-icon v-if="deletable" size="20px" :title="$t('generic.delete')">
                mdi-delete-outline
              </v-icon>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { filesTable } from '@/pdf/components';
export default {
  name: 'FilesTable',
  props: {
    value: {
      type: Array,
      required: true
    },
    deletable: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    deleteDialog: {
      shown: false,
    },
    editDialog: {
      shown: false,
    }
  }),
  computed: {
    ordered() {
      return [ ...this.value ].sort((a, b) => a.TagValue?.localeCompare(b.TagValue));
    }
  },
  methods: {
    renderPdfContent() {
      return filesTable(this.ordered);
    }
  }
}
</script>

<style lang="sass">
.files-table
  td
    &.is-milestone
      width: 30px
    &.actions
      text-align: right
      width: 60px
      padding-right: 5px
    &.ext
      width: 97px
  .ext
    text-transform: uppercase
</style>
