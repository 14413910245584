import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { BriefingType, BriefingFieldInterface } from '@/model/brief2';

const props = Vue.extend({
  props: {
    value: {
      type: Object as PropType<BriefingFieldInterface>,
      required: true,
    },
    briefingType: {
      type: Object as PropType<BriefingType>,
      required: true,
    },
  },
});

@Component
export default class ElementBase extends props {
  /*
   * computed
   */
  get title() {
    return this.value.CanvasTitle || this.value.Title;
  }
  
  get content() {
    return this.value.SubmittedData?.Value;
  }

  get color() {
    return this.briefingType.Color;
  }
}
