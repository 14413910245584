<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="249"
    height="249"
    viewBox="0 0 249 249"
  >
    <rect width="249" height="249" fill="#fc0" />
    <text
      x="50%"
      y="224"
      text-anchor="middle"
      fill="#fff"
      font-size="32"
    >{{ $t('briefing.operative.name').toUpperCase() }}</text>
    <path d="M80.889,100H10.113A10.069,10.069,0,0,1,0,90l.052-70A10.037,10.037,0,0,1,10.113,10h5.051V0H25.277V10H65.726V0H75.836V10h5.053A10.061,10.061,0,0,1,91,19.994V90A10.066,10.066,0,0,1,80.889,100ZM10.113,40V90H80.889V40Zm0-20V30H80.889v-10ZM70.779,80H45.5V55H70.779V80Z" transform="translate(82 62)" fill="#fff" />
  </svg>
</template>

<script>
export default {
  name: 'BriefingTypeOperative'
}
</script>
