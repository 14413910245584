<template>
  <div v-if="timings.length > 0" class="canvas-field">
    <h2 class="canvas-field-title" v-html="title" />
    <milestones-table
      ref="table"
      :value="timings"
      :briefing-type="briefingType"
      :titles="[]"
    />
  </div>
</template>

<script>
import ElementBase from './ElementBase';
import MilestonesTable from '@/components/common/MilestonesTable';
import { sortFormData } from '@/model/brief';
import { field } from '@/pdf/components';

export default {
  name: 'CanvasMilestones',
  components: {
    MilestonesTable
  },
  mixins: [ ElementBase ],
  data: () => ({
    timings: [],
  }),
  created() {
    if (this.value.SubmittedData.MilestoneOptions) {
      this.timings = this.value.SubmittedData.MilestoneOptions.sort(sortFormData);
    } else {
      this.timings = [];
    }
  },
  methods: {
    renderPdfContent() {
      if (this.timings.length === 0) {
        return undefined;
      }

      return field([
        ...this.$refs.table.renderPdfContent(),
      ]);
    }
  }
}
</script>
