import Vue from 'vue';

export default Vue.extend({
  props: {
    to: {
      type: Object,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'main'
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    calculatedColor(): string {
      const themeColor = this.$vuetify.theme.themes.light[this.color] as string | undefined;
      return themeColor ?? this.color;
    }
  },
  methods: {
    onClick(e: any): void {
      if (this.disabled) {
        return;
      }

      if (this.to) {
        this.$router.push(this.to);
      } else {
        this.$emit('click', e);
      }
    }
  }
});
