import { BRIEFING_MODULE_NAME } from './BriefingStore';
import { BriefingParticipantType } from '@/model/participant';
import { mapMutations } from 'vuex';
import { BriefingType, SubmittedData, TemplateField } from '@/model/brief2';

export const BRIEFING_MUTATION_KEYS = {
  clear: 'clear',
  initialize: 'initialize',
  addRole: 'addRole',
  replaceRole: 'replaceRole',
  removeRole: 'removeRole',
  showCreateParticipantDialog: 'showCreateParticipantDialog',
  closeCreateParticipantDialog: 'closeCreateParticipantDialog',
  setParticipantsSbf: 'setParticipantsSbf',
};

export const commitKey = (key: keyof typeof BRIEFING_MUTATION_KEYS) => `${BRIEFING_MODULE_NAME}/${key}`;

export type clear = () => void;
export type InitializePayload = Readonly<{
  uuid: string,
  sbfId?: number,
  briefingType: BriefingType;
  participantsField: TemplateField;
  participantsSbf?: SubmittedData;
  participants: BriefingParticipantType[]
}>;
export type initialize = (payload: InitializePayload) => void;
export type addRole = (payload: BriefingParticipantType) => void;
export type replaceRole = (payload: BriefingParticipantType) => void;
export type removeRole = (roleId: number) => void;
export type showCreateParticipantDialog = () => void;
export type closeCreateParticipantDialog = () => void;
export type setParticipantsSbf = (payload: SubmittedData) => void;


type MapOptionsArray = Array<keyof typeof BRIEFING_MUTATION_KEYS>;
type MapOptionsObject = {
  [key: string]: keyof typeof BRIEFING_MUTATION_KEYS
};

export const mapBriefingMutations = (options: MapOptionsArray | MapOptionsObject) => {
  return mapMutations(BRIEFING_MODULE_NAME, options as any);
};
